<script setup lang="ts">
import { computed, provide, ref } from 'vue';

import { UI_TOUR_CONTEXT_KEY } from './share';
import UITourInternal from './UITourInternal.vue';

withDefaults(
  defineProps<{
    teleportTo?: string;
  }>(),
  { teleportTo: 'body' },
);

const currentId = ref<string | null>(null);
const uiTourInternal = ref<InstanceType<typeof UITourInternal>>();
const children = ref<string[]>([]);
const totalStops = computed(() => children.value.length);

const currentIndex = computed(() => {
  return currentId.value ? children.value.indexOf(currentId.value) : -1;
});

function start(index = 0) {
  addKeyDownListener();
  const intendedId = children.value[index];
  if (intendedId) {
    currentId.value = intendedId;
  }
}

function prev() {
  if (currentId.value === null) {
    currentId.value = children.value[children.value.length - 1];
  } else {
    const currentIndex = children.value.indexOf(currentId.value);
    const prevIndex =
      (currentIndex - 1 + children.value.length) % children.value.length;
    currentId.value = children.value[prevIndex];
  }
}

function next() {
  if (currentId.value === null) {
    currentId.value = children.value[0];
  } else {
    const currentIndex = children.value.indexOf(currentId.value);
    const nextIndex = (currentIndex + 1) % children.value.length;
    currentId.value = children.value[nextIndex];
  }
}

function scanForChildrenIds() {
  if (uiTourInternal.value) {
    const orderedChildren = uiTourInternal.value.getChildrenIds();
    children.value = orderedChildren;
  }
}

async function keyDownHandler(e: KeyboardEvent) {
  if (e.key === 'ArrowRight') {
    next();
  } else if (e.key === 'ArrowLeft') {
    prev();
  } else if (e.key === 'Escape') {
    await exit();
  }
}

function addKeyDownListener() {
  window.addEventListener('keydown', keyDownHandler);
}

function removeKeyDownListener() {
  window.removeEventListener('keydown', keyDownHandler);
}

async function exit(
  options: { omitAnimation: boolean } = { omitAnimation: false },
) {
  await uiTourInternal.value?.exit({ omitAnimation: options.omitAnimation });
  cleanupOnExit();
}

async function cleanupOnExit() {
  removeKeyDownListener();
  currentId.value = null;
}

provide(UI_TOUR_CONTEXT_KEY, {
  prev,
  next,
  exit,
  currentId,
  currentIndex,
  totalStops,
  scanForChildrenIds,
});

defineExpose({ start, exit });
</script>

<template>
  <UITourInternal
    ref="uiTourInternal"
    :teleport-to="teleportTo"
    :current-id="currentId"
    @exited="cleanupOnExit"
  >
    <slot />

    <template #spinner>
      <slot name="spinner" />
    </template>
  </UITourInternal>
</template>
