import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

export default class MasterDataService extends ApiBase {
  getMasterData(): Promise<{
    success: boolean;
    data?: components['schemas']['AccountAttributes'];
    error?: any;
  }> {
    return this.$http
      .options<components['schemas']['DetailResponse_AccountAttributes_']>(
        `v1.0/accounts`,
      )
      .then((response) => {
        return {
          success: true,
          data: response?.data?.data,
        };
      })
      .catch((err) => {
        return { success: false, error: err };
      });
  }
}
