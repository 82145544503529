<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    withMask?: boolean;
  }>(),
  {
    withMask: true,
  },
);
</script>
<template>
  <div class="z-1 relative h-full w-full">
    <div
      v-if="props.modelValue"
      class="pointer-events-none absolute z-50 flex h-full w-full items-center justify-center bg-opacity-60"
      :class="{ 'pointer-events-auto bg-gray-300': props.withMask }"
    >
      <a-spin style="width: 50px; height: 50px" animation-duration=".5s" />
    </div>
    <slot />
  </div>
</template>
