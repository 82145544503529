<script lang="ts" setup>
import { staticResourceUrl } from '@/config/settings';

const emit = defineEmits(['next']);
</script>

<template>
  <!-- Title -->
  <div class="flex flex-col justify-center space-y-4 text-center">
    <img
      :src="`${staticResourceUrl}/images/logo_t.png`"
      class="mx-auto w-[72px]"
    />
    <h1 class="h2-sm">Welcome to Spark!</h1>
  </div>
  <!-- Content -->
  <div class="space-y-4 text-center">
    <p class="text-left">
      Please finish completing your profile to help you make the most of our
      platform.
    </p>
    <img
      src="@/assets/img/mandatory-setup/mobile-login-cristina.webp"
      class="mx-auto w-[70%]"
    />
    <p>
      This will take you
      <strong>1 - 3</strong> minutes.
    </p>
  </div>
  <!-- Start action -->
  <div class="flex justify-center pt-2">
    <SparkTrackerButton size="md" @click="emit('next')"
      >Let's start</SparkTrackerButton
    >
  </div>
</template>
