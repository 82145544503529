import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

export default class MaintenanceService extends ApiBase {
  async getMaintenanceData() {
    const response =
      await this.$http.get<
        components['schemas']['ListResponse_MaintenanceMessagePublicDTO_']
      >(`v1.0/maintenances`);
    return response.data;
  }
}
