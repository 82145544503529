import 'vue-tel-input/vue-tel-input.css';
import '@asika32764/vue-animate/dist/vue-animate.min.css';
import '@/assets/css/tailwind-base.scss';
import '@/assets/css/antd-custom.less';
import '@/assets/css/tailwind.scss';
import '@/assets/css/app.scss';

import * as Sentry from '@sentry/vue';
import { createHead } from '@vueuse/head';
import Antd from 'ant-design-vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { VueQueryPlugin } from 'vue-query';
import VueScrollTo from 'vue-scrollto';
import VueTelInput from 'vue-tel-input';

import SparkTrackerButton from '@/core/components/SparkTrackerButton/SparkTrackerButton.vue';
import router from '@/core/router';
import { resetStore } from '@/core/stores/plugins/resetStore';
import { getAppRelease } from '@/core/utils/getAppRelease';
import Markdown from '@/shared/components/SparkMarkdown.vue';
import CaretLink from '#/components/generic/CaretLink.vue';
import Loader from '#/components/generic/Loader.vue';
import SparkButton from '#/components/generic/SparkButton/SparkButton.vue';
import SparkIcon from '#/components/generic/SparkIcon';
import { installDayJSPlugins } from '#/utils/dayjs';

import App from './App.vue';

installDayJSPlugins();

const head = createHead();
const app = createApp(App);

// please include typings in shims-global-components.d.ts
app.component('SparkButton', SparkButton);
app.component('CaretLink', CaretLink);
app.component('SparkTrackerButton', SparkTrackerButton);
app.component('Loader', Loader);
app.component('SparkIcon', SparkIcon);
app.component('Markdown', Markdown);

const pinia = createPinia();
pinia.use(resetStore);

app.use(pinia);
app.use(router);
app.use(head);
app.use(VueQueryPlugin);
app.use(Antd);
app.use(VueScrollTo);
app.use(VueTelInput);

// Globally handle errors so that app does not stop working
app.config.errorHandler = (err, vm, info) => {
  console.error(err, vm, info);
};

if (import.meta.env.VITE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    release: `sp-application@${getAppRelease()}`,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [import.meta.env.VITE_APP_SP_API_BASE_URL],

    // Capture Replay for 5% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,

    // Vue specific
    trackComponents: true,
    attachProps: true,
    attachStacktrace: true,

    // ignore errors
    // https://github.com/getsentry/sentry-javascript/issues/3440
    ignoreErrors: [
      'Non-Error promise rejection captured',
      'Failed to fetch dynamically imported module',
    ],
  });
}

app.mount('#app');
