import { useAuthStore } from '../stores/auth';

const separator = '-';
const getHashCode = (key: string) => {
  let hash = 0;
  let i;
  let char;
  if (key.length === 0) return hash;
  for (i = 0; i < key.length; i++) {
    char = key.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};
export const userStorageKey = (): string => {
  return getHashCode(useAuthStore().userAccount?.uuid || '')?.toString();
};

export const getItem = (key: string) => {
  return localStorage.getItem(userStorageKey() + separator + key);
};

export const setItem = (key: string, value: any) => {
  localStorage.setItem(userStorageKey() + separator + key, value);
};

export const removeItem = (key: string) => {
  localStorage.removeItem(userStorageKey() + separator + key);
};
