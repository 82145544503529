<script setup lang="ts">
import type { Placement } from '@floating-ui/dom';
import { computed, type StyleValue } from 'vue';

import { ProductColorCssVarValues } from '#/constants/productColor';
import type { SparkProductColor } from '#/types/core';

const props = defineProps<{
  arrowPositionStyles: StyleValue;
  placementResult: Placement;
  color: SparkProductColor;
}>();

defineEmits<{
  arrowElement: [el: HTMLElement];
}>();

const styles = computed(() => {
  const positionStyles =
    typeof props.arrowPositionStyles === 'object' && props.arrowPositionStyles
      ? props.arrowPositionStyles
      : {};

  return {
    ...positionStyles,
    '--arrow-border-color': ProductColorCssVarValues[props.color],
  };
});
</script>

<template>
  <div
    :ref="(e) => $emit('arrowElement', e as HTMLElement)"
    :style="styles"
    class="floating-arrow absolute z-10 h-[15px] w-[15px] rotate-45 border-[--arrow-border-color] bg-white"
    :data-placement="placementResult"
  ></div>
</template>

<style scoped>
.floating-arrow[data-placement^='bottom'] {
  @apply border-l-4 border-t-4;
}

.floating-arrow[data-placement^='top'] {
  @apply border-b-4 border-r-4;
}

.floating-arrow[data-placement^='left'] {
  @apply border-r-4 border-t-4;
}

.floating-arrow[data-placement^='right'] {
  @apply border-b-4 border-l-4;
}
</style>
