export const FREIGHT_UI_TOUR_IDS = {
  FREIGHT_PHYSICAL_TITLE: 'ui-tour-freight-physical-title',
  FREIGHT_FFA_TITLE: 'ui-tour-freight-ffa-title',
  GLOBAL_CALCULATOR_TITLE: 'ui-tour-global-calculator-title',
  HISTORICAL_DATA_TITLE: 'ui-tour-historical-data-title',
  SPREAD_CHARTING_TITLE: 'ui-tour-spread-charting-title',
  ROUND_TRIP_CALCULATOR_TITLE: 'ui-tour-round-trip-calculator-title',
  INDEXATION_TITLE: 'ui-tour-indexation-title',
  ROUTES_TITLE: 'ui-tour-routes-title',
  NEUTRAL_TITLE: 'ui-tour-neutral-title',
  FLOATING_STORAGE_TITLE: 'ui-tour-floating-storage-title',
  FREIGHT_GUIDE_BUTTON: 'ui-tour-freight-guide-button',
};
