<template>
  <g>
    <path
      fill="currentColor"
      d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
      fill-opacity="0.15"
    />
    <path
      fill="currentColor"
      d="M14.8281 8.22882L13.8853 7.28601L9.17125 12L13.8853 16.7141L14.8281 15.7713L11.0569 12.0001L14.8281 8.22882Z"
    />
  </g>
</template>
