<script setup lang="ts">
import { createReusableTemplate } from '@vueuse/core';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    to: string;
    label: string;
    icon: string;
    iconSize?: string;
  }>(),
  {
    iconSize: '24',
  },
);

const [DefineTemplate, ReuseTemplate] = createReusableTemplate();

const isExternal = computed(() => props.to.startsWith('http'));
</script>

<template>
  <DefineTemplate v-slot="{ href, isActive, clickHandler, external }">
    <a
      :href="href"
      :aria-current="isActive ? 'page' : undefined"
      :target="external ? '_blank' : undefined"
      class="flex items-center gap-x-4 px-4 py-2 font-semibold text-gray-400 transition-colors hover:bg-gray-700/50 hover:text-gray-100 aria-[current=page]:bg-gray-700 aria-[current=page]:font-bold aria-[current=page]:text-white"
      @click="clickHandler"
    >
      <SparkIcon :icon="icon" :size="iconSize" />
      <div>{{ label }}</div>
    </a>
  </DefineTemplate>

  <RouterLink
    v-if="!isExternal"
    v-slot="{ href, navigate, isActive }"
    custom
    :to="to"
  >
    <ReuseTemplate
      :href="href"
      :click-handler="navigate"
      :is-active="isActive"
    />
  </RouterLink>

  <ReuseTemplate v-else :href="to" :external="true" />
</template>
