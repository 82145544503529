import { AxiosError } from 'axios';

import { components } from '#/types/platformapi-generated';

export class DetailResponseError extends Error {
  constructor(errors: components['schemas']['Error'][] = []) {
    super('DetailResponseError');
    this.errors = errors;
  }

  errors: components['schemas']['Error'][];
}

export class HTTPValidationError extends Error {
  constructor(error: components['schemas']['HTTPValidationError']) {
    super('HTTPValidationError');
    this.error = error;
  }

  // extra message field is added from the backend API but not in the schema
  error: components['schemas']['HTTPValidationError'] & { message?: string };
}

export interface HttpErrorDetails {
  code?: string;
  message: string;
  status: number;
  data: unknown;
}

export class HttpError extends Error {
  constructor(error: HttpErrorDetails) {
    super('HttpError');
    this.error = error;
  }

  error: HttpErrorDetails;
}

function mapAxiosError(axiosError: AxiosError) {
  if (axiosError.response) {
    const { status } = axiosError.response;
    if (status === 400) {
      const err = axiosError.response.data as {
        error: components['schemas']['HTTPValidationError'];
      };
      return new HTTPValidationError(err.error);
    } else {
      return new HttpError({
        code: axiosError.code,
        message: axiosError.message,
        status,
        data: axiosError.response.data,
      });
    }
  } else {
    return axiosError;
  }
}

/**
 * typical use at the Store level
 */
export function mapApiError(error: unknown): Error {
  if (error instanceof DetailResponseError) {
    return error;
  }
  if (error instanceof AxiosError) {
    return mapAxiosError(error);
  }
  if (error instanceof Error) {
    return error;
  }
  if (typeof error === 'string') {
    return new Error(error);
  }
  return new Error('Unknown error');
}

/*
 * typical use at the Service level
 */
export function throwIfErrors(successResponse: {
  errors?: components['schemas']['Error'][];
}) {
  if (
    Array.isArray(successResponse.errors) &&
    successResponse.errors.length > 0
  ) {
    throw new DetailResponseError(successResponse.errors);
  }
}
