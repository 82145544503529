<template>
  <g>
    <path
      fill="currentColor"
      d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
      fill-opacity="0.15"
    />
    <path
      fill="currentColor"
      d="M11.9993 6.66699C11.6312 6.66699 11.3327 6.96547 11.3327 7.33366V11.3337H7.33268C6.96449 11.3337 6.66602 11.6321 6.66602 12.0003C6.66602 12.3685 6.96449 12.667 7.33268 12.667H11.3327V16.667C11.3327 17.0352 11.6312 17.3337 11.9993 17.3337C12.3675 17.3337 12.666 17.0352 12.666 16.667V12.667H16.666C17.0342 12.667 17.3327 12.3685 17.3327 12.0003C17.3327 11.6321 17.0342 11.3337 16.666 11.3337H12.666V7.33366C12.666 6.96547 12.3675 6.66699 11.9993 6.66699Z"
    />
  </g>
</template>
