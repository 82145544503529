export const UI_TOUR_IDS = {
  CONTRACTS: 'ui-tour-freight-ffa-contracts',
  PRICE_TYPE: 'ui-tour-price-type',
  FFA_PRICE_TABLE: 'ui-tour-ffa-price-type',
  PRICE_CHART: 'ui-tour-price-chart',
  SPOT_ASSESSMENT: 'ui-tour-spot-assessment',
  FFA_TRACKER: 'ui-tour-ffa-tracker',
  SEASONALITY_TRACKER: 'ui-tour-seasonality-tracker',
  DOWNLOADS_AND_ALERTS: 'ui-tour-downloads-and-alerts',
  CHALLENGE: 'ui-tour-challenge',
  METHODOLOGY: 'ui-tour-methodology',
} as const;
