<script setup lang="ts">
import { camelCase } from 'lodash';
import { computed } from 'vue';

import * as Icons from './svg';

const props = withDefaults(
  defineProps<{
    icon: string;
    name?: string;
    width?: number | string;
    height?: number | string;
    size?: number | string;
    color?: string;
    spin?: boolean;
    viewBox?: string;
  }>(),
  {
    name: '',
    width: 24,
    height: 24,
    size: 0,
    color: 'currentColor',
    spin: false,
    viewBox: '0 0 24 24',
  },
);

const getIconComponent = computed(
  () => Icons[camelCase(`${props.icon}Svg`) as keyof typeof Icons],
);
const geticonAccessibleName = computed(() => props.name ?? props.icon ?? '');
</script>
<template>
  <svg
    :class="{ spin: spin }"
    :width="size || width"
    :height="size || height"
    :aria-labelledby="geticonAccessibleName"
    role="presentation"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- <title :id="name" lang="en">{{ name }}</title> -->
    <g :fill="color">
      <component :is="getIconComponent" />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
.spin {
  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
