<template>
  <g fill="none">
    <path
      d="M1.5 17.8333L6.16667 22.5M6.16667 17.8333L1.5 22.5M17.8333 1.5L22.5 6.16667M22.5 1.5L17.8333 6.16667M10.8333 20.1667H17.25C18.333 20.1667 19.3716 19.7365 20.1374 18.9707C20.9031 18.2049 21.3333 17.1663 21.3333 16.0833C21.3333 15.0004 20.9031 13.9618 20.1374 13.196C19.3716 12.4302 18.333 12 17.25 12H7.91667C6.8337 12 5.79509 11.5698 5.02931 10.804C4.26354 10.0382 3.83333 8.99963 3.83333 7.91667C3.83333 6.8337 4.26354 5.79509 5.02931 5.02931C5.79509 4.26354 6.8337 3.83333 7.91667 3.83333H13.1667"
      stroke="currentColor"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>
