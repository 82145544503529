import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

export default class FloatingStorageService extends ApiBase {
  getReferenceData() {
    return this.$http
      .get<
        components['schemas']['DetailResponse_FloatingStorageRefDataDTO_']
      >('/v1.0/floating-storage/reference-data')
      .then((response) => {
        return response?.data?.data;
      });
  }

  getLatest() {
    return this.$http
      .get<
        components['schemas']['DetailResponse_FloatingStorageDTO_']
      >(`v1.0/floating-storage/latest`)
      .then((response) => {
        return response?.data?.data;
      });
  }

  getData(releaseDate?: string) {
    return this.$http
      .get<
        components['schemas']['DetailResponse_FloatingStorageDTO_']
      >(`v1.0/floating-storage/${releaseDate}`)
      .then((response) => {
        return response?.data?.data;
      });
  }
}
