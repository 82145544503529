<script setup lang="ts">
import { computed, onUnmounted, ref, watch } from 'vue';

import SparkAlert from '#/components/generic/SparkAlert.vue';

import { useMasterDataStore } from '../stores/masterData';

const masterDataStore = useMasterDataStore();

const rateLimiterEncountered = computed(
  () => masterDataStore.rateLimiterEncountered,
);

const timer = ref<ReturnType<typeof setTimeout> | undefined>();
const RATE_LIMITER_DISMISS_TIMEOUT = 1000 * 60 * 10; // 10 mins

watch(rateLimiterEncountered, (value) => {
  if (value) {
    timer.value = setTimeout(() => {
      masterDataStore.rateLimiterEncountered = false;
    }, RATE_LIMITER_DISMISS_TIMEOUT);
  }
});

onUnmounted(() => {
  clearTimeout(timer.value);
});
</script>
<template>
  <div v-if="rateLimiterEncountered" class="fixed z-[999] w-full">
    <SparkAlert type="warning" closable>
      <template #message>
        <div class="mx-6">
          Our systems are currently overwhelmed by too many requests, please try
          again after some time.
        </div>
      </template>
    </SparkAlert>
  </div>
</template>
