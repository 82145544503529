import { useEventListener } from '@vueuse/core';
import { filter, map, Observable } from 'rxjs';
import { computed, Ref, ref, watch } from 'vue';

import { isJsonString } from '../utils/isJsonString/isJsonString';

const webSocketPool = new Map<string, WebSocket | undefined>();

export function useWebSocket(
  namespace = 'default',
  url: string,
  accessToken: Ref<string | undefined>,
  options: {
    disable?: boolean;
  } = {
    /// SP-1376: disable websocket for now
    disable: true,
  },
) {
  const allMessageObservable = ref<Observable<string>>();
  const jsonObservable = ref<Observable<Record<any, any>>>();

  const state = computed(() => getWebSocket()?.readyState ?? -1);

  watch(accessToken, (newVal) => {
    if (newVal) {
      wsLogin();
    }
  });

  function getWebSocket(): WebSocket | undefined {
    return webSocketPool.get(namespace);
  }

  function setWebSocket(socket: WebSocket | undefined) {
    webSocketPool.set(namespace, socket);
  }

  async function open(forceReset = false) {
    if (options.disable) {
      return;
    }

    // close any existing socket
    if (getWebSocket()) {
      if (forceReset) {
        console.debug('close existing socket');
        close(1000, 'Open new socket');
      } else {
        console.debug('Socket already open');
        return;
      }
    }

    const socket = new WebSocket(url);

    // TODO: handle errors. currently cannot trigger errors so not sure how to test
    socket.onerror = (event) => {
      console.error('Socket error', event);
      // errorObservable.value = new Observable((subscriber) => {
      //   subscriber.next(event.data);
      // });
    };

    socket.onopen = () => {
      wsLogin();
    };

    setWebSocket(socket);

    allMessageObservable.value = new Observable((subscriber) => {
      socket.addEventListener('message', (event) => {
        // console.debug('ws msg:', event.data);
        subscriber.next(event.data);
      });
    });
    jsonObservable.value = allMessageObservable.value.pipe(
      filter((message) => isJsonString(message)),
      map((message) => JSON.parse(message)),
    );
  }

  async function wsLogin() {
    // sometimes pages are window.reloaded and the websocket is lost. E.g. logout
    await open();
    if (
      accessToken.value &&
      (state.value === WebSocket.OPEN || state.value === WebSocket.CONNECTING)
    ) {
      getWebSocket()?.send(JSON.stringify({ token: accessToken.value }));
    }
  }

  async function close(code = 1000, reason: string) {
    console.debug('close websocket');
    getWebSocket()?.close(code, reason);
    setWebSocket(undefined);
  }

  useEventListener(window, 'beforeunload', () =>
    close(1000, 'Window beforeunload'),
  );

  return {
    namespace,
    websocket: getWebSocket(),
    state,
    allMessageObservable,
    jsonObservable,
    open,
    close,
  };
}
