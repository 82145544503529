export function isNotEmptyText(
  text: string | null | undefined,
): text is string {
  return !!(text && text.trim().length > 0);
}

export function isEmptyText(text: string | null | undefined) {
  return !isNotEmptyText(text);
}

export function containsIgnoreCase(text: string, find: string) {
  return text.toLowerCase().includes(find.toLowerCase());
}

/**
 * Replaces placeholders in a string with their values
 * @param text Placeholders should be in the format of {placeholder}
 * @param placeholders key-value pairs of placeholders and their values
 */
export function replacePlaceholders(
  text: string,
  placeholders: Record<string, string>,
) {
  return text.replace(/{([^{}]*)}/g, (_, key) => {
    return placeholders[key] || '';
  });
}
