<template>
  <g clip-path="url(#clip0)">
    <path
      fill="currentColor"
      d="M10.8701 15.75C9.89268 15.7492 8.92854 15.5238 8.05218 15.091C7.17582 14.6582 6.41072 14.0297 5.81599 13.2541C5.22126 12.4784 4.81285 11.5764 4.62231 10.6178C4.43177 9.65913 4.46421 8.66951 4.71712 7.7254C4.97004 6.78129 5.43665 5.90797 6.0809 5.17295C6.72514 4.43793 7.52977 3.86089 8.43259 3.48643C9.33541 3.11197 10.3122 2.95011 11.2876 3.01337C12.2629 3.07662 13.2107 3.3633 14.0576 3.85125C14.1024 3.87469 14.142 3.90696 14.174 3.94611C14.206 3.98525 14.2298 4.03047 14.2439 4.07905C14.258 4.12762 14.2621 4.17854 14.256 4.22875C14.2499 4.27896 14.2337 4.32741 14.2084 4.3712C14.1831 4.41498 14.1492 4.4532 14.1087 4.48354C14.0683 4.51389 14.0221 4.53573 13.973 4.54776C13.9238 4.5598 13.8728 4.56177 13.8229 4.55356C13.773 4.54535 13.7253 4.52713 13.6826 4.5C12.8333 4.00986 11.8706 3.75025 10.8901 3.74691C9.90949 3.74358 8.94508 3.99664 8.09248 4.48099C7.23987 4.96534 6.5287 5.66416 6.02948 6.50814C5.53026 7.35212 5.26034 8.31195 5.24649 9.29242C5.23264 10.2729 5.47533 11.24 5.95051 12.0977C6.42569 12.9555 7.11684 13.6741 7.95542 14.1823C8.794 14.6906 9.75087 14.9708 10.7311 14.9951C11.7114 15.0195 12.681 14.7872 13.5438 14.3212C13.6338 14.274 13.7389 14.2645 13.836 14.2947C13.933 14.3249 14.0141 14.3925 14.0613 14.4825C14.1086 14.5725 14.1181 14.6776 14.0879 14.7746C14.0576 14.8717 13.9901 14.9528 13.9001 15C12.9675 15.4963 11.9265 15.754 10.8701 15.75Z"
    />
    <path
      fill="currentColor"
      d="M10.8701 14.25C9.57713 14.25 8.33715 13.7364 7.42291 12.8221C6.50867 11.9079 5.99506 10.6679 5.99506 9.375C5.99506 8.08207 6.50867 6.84209 7.42291 5.92785C8.33715 5.01361 9.57713 4.5 10.8701 4.5C10.9695 4.5 11.0649 4.53951 11.1352 4.60984C11.2055 4.68016 11.2451 4.77554 11.2451 4.875C11.2451 4.97446 11.2055 5.06984 11.1352 5.14016C11.0649 5.21049 10.9695 5.25 10.8701 5.25C9.77604 5.25 8.72683 5.6846 7.95324 6.45818C7.17965 7.23177 6.74506 8.28098 6.74506 9.375C6.74506 10.469 7.17965 11.5182 7.95324 12.2918C8.72683 13.0654 9.77604 13.5 10.8701 13.5C10.9695 13.5 11.0649 13.5395 11.1352 13.6098C11.2055 13.6802 11.2451 13.7755 11.2451 13.875C11.2451 13.9745 11.2055 14.0698 11.1352 14.1402C11.0649 14.2105 10.9695 14.25 10.8701 14.25Z"
    />
    <path
      fill="currentColor"
      d="M12.3701 21C12.2706 21 12.1752 20.9605 12.1049 20.8902C12.0346 20.8198 11.9951 20.7245 11.9951 20.625V17.8688C11.9951 17.5313 11.5601 17.25 11.0463 17.25H10.6938C10.1801 17.25 9.74506 17.5313 9.74506 17.8688V20.625C9.74506 20.7245 9.70555 20.8198 9.63522 20.8902C9.5649 20.9605 9.46951 21 9.37006 21C9.2706 21 9.17522 20.9605 9.10489 20.8902C9.03456 20.8198 8.99506 20.7245 8.99506 20.625V17.8688C8.99506 17.1188 9.74506 16.5 10.6938 16.5H11.0463C11.9951 16.5 12.7451 17.115 12.7451 17.8688V20.625C12.7451 20.7245 12.7055 20.8198 12.6352 20.8902C12.5649 20.9605 12.4695 21 12.3701 21Z"
    />
    <path
      fill="currentColor"
      d="M10.8701 17.25C10.7706 17.25 10.6752 17.2105 10.6049 17.1402C10.5346 17.0698 10.4951 16.9745 10.4951 16.875V15.375C10.4951 15.2755 10.5346 15.1802 10.6049 15.1098C10.6752 15.0395 10.7706 15 10.8701 15C10.9695 15 11.0649 15.0395 11.1352 15.1098C11.2055 15.1802 11.2451 15.2755 11.2451 15.375V16.875C11.2451 16.9745 11.2055 17.0698 11.1352 17.1402C11.0649 17.2105 10.9695 17.25 10.8701 17.25Z"
    />
    <path
      fill="currentColor"
      d="M18.3701 12.75H9.74506C9.6456 12.75 9.55022 12.7105 9.47989 12.6402C9.40956 12.5698 9.37006 12.4745 9.37006 12.375C9.37006 12.2755 9.40956 12.1802 9.47989 12.1098C9.55022 12.0395 9.6456 12 9.74506 12H18.3701C18.4695 12 18.5649 12.0395 18.6352 12.1098C18.7055 12.1802 18.7451 12.2755 18.7451 12.375C18.7451 12.4745 18.7055 12.5698 18.6352 12.6402C18.5649 12.7105 18.4695 12.75 18.3701 12.75Z"
    />
    <path
      fill="currentColor"
      d="M11.9951 12.75H9.74506C9.6456 12.75 9.55022 12.7105 9.47989 12.6402C9.40956 12.5698 9.37006 12.4745 9.37006 12.375V9.75C9.37006 9.65054 9.40956 9.55516 9.47989 9.48483C9.55022 9.41451 9.6456 9.375 9.74506 9.375H11.9951C12.0945 9.375 12.1899 9.41451 12.2602 9.48483C12.3305 9.55516 12.3701 9.65054 12.3701 9.75V12.375C12.3701 12.4745 12.3305 12.5698 12.2602 12.6402C12.1899 12.7105 12.0945 12.75 11.9951 12.75ZM10.1201 12H11.6201V10.125H10.1201V12Z"
    />
    <path
      fill="currentColor"
      d="M14.2451 12.75H11.9951C11.8956 12.75 11.8002 12.7105 11.7299 12.6402C11.6596 12.5698 11.6201 12.4745 11.6201 12.375V7.5C11.6201 7.40054 11.6596 7.30516 11.7299 7.23484C11.8002 7.16451 11.8956 7.125 11.9951 7.125H14.2451C14.3445 7.125 14.4399 7.16451 14.5102 7.23484C14.5805 7.30516 14.6201 7.40054 14.6201 7.5V12.375C14.6201 12.4745 14.5805 12.5698 14.5102 12.6402C14.4399 12.7105 14.3445 12.75 14.2451 12.75ZM12.3701 12H13.8701V7.875H12.3701V12Z"
    />
    <path
      fill="currentColor"
      d="M16.4951 12.75H14.2451C14.1456 12.75 14.0502 12.7105 13.9799 12.6402C13.9096 12.5698 13.8701 12.4745 13.8701 12.375V6C13.8701 5.90054 13.9096 5.80516 13.9799 5.73484C14.0502 5.66451 14.1456 5.625 14.2451 5.625H16.4951C16.5945 5.625 16.6899 5.66451 16.7602 5.73484C16.8305 5.80516 16.8701 5.90054 16.8701 6V12.375C16.8701 12.4745 16.8305 12.5698 16.7602 12.6402C16.6899 12.7105 16.5945 12.75 16.4951 12.75ZM14.6201 12H16.1201V6.375H14.6201V12Z"
    />
    <path
      fill="currentColor"
      d="M18.7451 12.75H16.4951C16.3956 12.75 16.3002 12.7105 16.2299 12.6402C16.1596 12.5698 16.1201 12.4745 16.1201 12.375V4.125C16.1201 4.02554 16.1596 3.93016 16.2299 3.85983C16.3002 3.78951 16.3956 3.75 16.4951 3.75H18.7451C18.8445 3.75 18.9399 3.78951 19.0102 3.85983C19.0805 3.93016 19.1201 4.02554 19.1201 4.125V12.375C19.1201 12.4745 19.0805 12.5698 19.0102 12.6402C18.9399 12.7105 18.8445 12.75 18.7451 12.75ZM16.8701 12H18.3701V4.5H16.8701V12Z"
    />
  </g>
</template>
