<template>
  <g>
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.48178 3.79417C2.05438 7.39409 2.19424 13.0909 5.79417 16.5183L12 22.7241L18.5183 16.2059C21.9457 12.6059 21.8058 6.90918 18.2059 3.48178C14.6059 0.0543831 8.90918 0.194243 5.48178 3.79417ZM6.4138 11.4311L7.53104 12.5173L12 8.17252L16.469 12.5173L17.5862 11.4311L12 6.00009L6.4138 11.4311Z"
    />
  </g>
</template>
