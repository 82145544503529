<template>
  <g>
    <path
      d="M8.14286 10.7143C8.14286 9.29413 9.29413 8.14286 10.7143 8.14286H18.4286C19.8487 8.14286 21 9.29413 21 10.7143V18.4286C21 19.8487 19.8487 21 18.4286 21H10.7143C9.29413 21 8.14286 19.8487 8.14286 18.4286V10.7143Z"
    />
    <path
      d="M5.57143 3C4.15127 3 3 4.15127 3 5.57143V13.2857C3 14.7059 4.15127 15.8571 5.57143 15.8571L5.57143 5.57143H15.8571C15.8571 4.15127 14.7059 3 13.2857 3H5.57143Z"
    />
  </g>
</template>
