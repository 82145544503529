// using typescript assertion functions for type narrowing
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#assertion-functions

import dayjs from 'dayjs';

export function assertIsTruthy<T>(
  value: T,
  message?: string,
): asserts value is NonNullable<T> {
  if (!value) {
    throw new Error(message || 'Value is not truthy');
  }
}

export function assertIsDefined<T>(
  value: T,
  message?: string,
): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(message || 'Value is not defined');
  }
}

export function assertIsNumber(
  value: number | null | undefined,
  message?: string,
): asserts value is number {
  if (typeof value !== 'number') {
    throw new Error(message || 'Value is not a number');
  }
}

export function assertValidDateOrNull(
  value: string | null,
  format = 'YYYY-MM-DD',
): asserts value is string | null {
  if (typeof value === 'string') {
    const isValid = dayjs.utc(value, format).isValid();
    if (!isValid) {
      throw new Error(`Invalid date: '${value}'`);
    }
  }
}
