<template>
  <path
    fill="currentColor"
    d="M19.5 7.5H18v-3h-1.5v3H15v9h1.5v3H18v-3h1.5v-9ZM18 15h-1.5V9H18v6Zm-7.5-9H9V3H7.5v3H6v7.5h1.5v3H9v-3h1.5V6ZM9 12H7.5V7.5H9V12Z"
  />
  <path
    fill="currentColor"
    d="M22.5 22.5H3A1.5 1.5 0 0 1 1.5 21V1.5H3V21h19.5v1.5Z"
  />
</template>
