<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useAppStore } from '@/core/stores/app';
import { useAuthStore } from '@/core/stores/auth';
import { analyticsTrack } from '@/core/utils/usageAnalytics';
import { AnalyticsEvent } from '@/types';

import BasisDealsButton from './BasisDealsButton.vue';
import BasisDealsWindow from './BasisDealsWindow.vue';

const BASIS_DEALS_URL = import.meta.env.VITE_APP_BASIS_DEALS_URL;

interface Props {
  bottom?: string;
  right?: string;
  left?: string | undefined;
  top?: string | undefined;
}

withDefaults(defineProps<Props>(), {
  bottom: '20px',
  right: '110px',
  left: undefined,
  top: undefined,
});

const route = useRoute();
let counter = 0;
const symbol = ref<symbol>(Symbol(counter));
const windowOpen = ref<boolean>(false);
const bubbleNumber = ref<number>(0);
const appStore = useAppStore();
const authStore = useAuthStore();

const showBasisDeals = computed(() => {
  return (
    authStore.loggedIn &&
    authStore.userAccount?.status === 'account-approved' &&
    appStore.activeProductId === 'lng-basis-platform' &&
    !!BASIS_DEALS_URL?.trim().length &&
    !route?.meta?.hideDeals
  );
});

function toggleWindow() {
  const newState = !windowOpen.value;
  analyticsTrack(AnalyticsEvent.BasisDealsToggle, {
    action: newState ? 'open' : 'close',
  });
  windowOpen.value = newState;

  // if opening window and has bubble number, reset state so that mark as read automatically will work
  // TODO: to improve implementation
  if (newState && bubbleNumber.value > 0) {
    counter = counter > 100 ? 0 : counter + 1;
    symbol.value = Symbol(counter);
  }
}

function listener(payload: any) {
  const action = payload?.data?.action;
  const data = payload?.data?.data;
  switch (action) {
    case 'deals:setBubbleNumber':
      bubbleNumber.value = data;
      break;
    default:
  }
}

onMounted(() => {
  window.addEventListener('message', listener);
});

onBeforeUnmount(() => {
  window.removeEventListener('message', listener);
});
</script>
<template>
  <Teleport to="#basis-deals">
    <article
      v-if="showBasisDeals"
      class="fixed"
      :style="{ left, right, bottom, top }"
    >
      <section
        class="relative h-[700px] max-h-[95vh] w-[400px] select-none"
        :class="{ hidden: !windowOpen }"
      >
        <BasisDealsWindow
          :key="symbol"
          :iframe-url="BASIS_DEALS_URL"
          :window-open="windowOpen"
          @close="toggleWindow"
        />
      </section>
      <section>
        <BasisDealsButton
          v-show="!windowOpen"
          :bubble-number="bubbleNumber"
          @click="toggleWindow"
        />
      </section>
    </article>
  </Teleport>
</template>
