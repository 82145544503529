<template>
  <g clip-path="url(#clip0_23142_7626)">
    <path
      d="M12 17.1429L5.14285 17.1429L5.14285 20.5715L12 20.5715L12 17.1429ZM18.8571 12.0001L5.14285 12.0001L5.14285 15.4286L18.8571 15.4286L18.8571 12.0001ZM3.42856 -1.71421L1.71428 -1.71421L1.71428 25.7144L22.2857 25.7144L22.2857 24.0001L3.42856 24.0001L3.42856 -1.71421ZM15.4286 6.85721L5.14285 6.85721L5.14285 10.2858L15.4286 10.2858L15.4286 6.85721ZM20.5714 1.71436L5.14285 1.71436L5.14285 5.14293L20.5714 5.14293L20.5714 1.71436Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_23142_7626">
      <rect
        width="24"
        height="24"
        fill="white"
        transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 24 24)"
      />
    </clipPath>
  </defs>
</template>
