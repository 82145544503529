import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/basis/submit',
    redirect: '/cargo/submit',
  },
  {
    path: '/basis/ffa/submit/:contractId',
    redirect: (to) => '/cargo/ffa/submit/' + to.params.contractId,
  },
  {
    path: '/basis',
    redirect: '/cargo',
    children: [
      {
        path: 'connect',
        redirect: '/cargo/connect',
      },
      {
        path: '',
        redirect: '/cargo/sparknwe',
      },

      // to redirect old route '/basis/discover?contractId=...' to new route
      {
        path: 'discover',
        component: () => '',
        beforeEnter: (to, from, next) => {
          if (to.query.contractId) {
            next(`/cargo/${to.query.contractId}`);
          } else {
            next('/cargo/sparknwe');
          }
        },
      },

      // to redirect old route '/basis/ffa/discover/?contractId=...' to new route
      {
        path: 'ffa/discover',
        component: () => '',
        beforeEnter: (to, from, next) => {
          if (to.query.contractId) {
            next(`/cargo/ffa/${to.query.contractId}`);
          } else {
            next('/cargo/ffa/sparknwe-fin');
          }
        },
      },
      {
        path: ':contractId',
        redirect: (to) => '/cargo/' + to.params.contractId,
      },
      {
        path: 'ffa/:contractId',
        redirect: (to) => '/cargo/ffa/' + to.params.contractId,
      },
      {
        path: 'historical-data',
        redirect: '/cargo/historical-data',
      },
      {
        path: 'settlement',
        redirect: '/cargo/settlement',
      },
      {
        path: 'alert-centre',
        redirect: '/cargo/alert-centre',
      },
      {
        path: 'netbacks',
        redirect: '',
        children: [
          {
            path: '',
            redirect: '/cargo/netbacks',
          },
          {
            path: 'arb-breakeven',
            redirect: '/cargo/netbacks/arb-breakeven',
          },
        ],
      },
      {
        path: 'netforwards',
        redirect: '/cargo/netforwards',
      },
      {
        path: 'spark-close-sandbox-intro',
        redirect: '/cargo/spark-close-sandbox-intro',
      },
      {
        path: 'spark-close',
        redirect: '/cargo/spark-close',
      },
      {
        path: 'spark-close-admin',
        redirect: '/cargo/spark-close-admin',
      },
    ],
  },
  {
    path: '/basis/sandbox',
    redirect: '/cargo/sandbox',
    children: [
      {
        path: '',
        name: 'sandbox',
        redirect: 'spark-close',
      },
      {
        path: 'spark-close',
        redirect: '/cargo/sandbox/spark-close',
      },
      {
        path: 'submit-intraday',
        redirect: '/cargo/sandbox/submit-intraday',
      },
    ],
  },
];

export default routes;
