import type { InjectionKey } from 'vue';

import type { UITourContext, UITourInternalContext } from './types';

export const UI_TOUR_STOP_ID_ATTR = 'data-ui-tour-stop-id';

export const UI_TOUR_CONTEXT_KEY = Symbol(
  'UITourContext',
) as InjectionKey<UITourContext>;

export const UI_TOUR_INTERNAL_CONTEXT_KEY = Symbol(
  'UITourInternalContext',
) as InjectionKey<UITourInternalContext>;

export function waitForElementToLoad(
  elementId: string,
  timeout: number = 5000,
): Promise<void> {
  return new Promise((resolve, reject) => {
    if (document.getElementById(elementId)) {
      resolve();
      return;
    }

    let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;

    const observer = new MutationObserver((_, obs) => {
      if (document.getElementById(elementId)) {
        obs.disconnect();
        if (timeoutId !== undefined) {
          clearTimeout(timeoutId);
        }
        resolve();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    timeoutId = setTimeout(() => {
      observer.disconnect();

      if (document.getElementById(elementId)) {
        observer.disconnect();
        resolve();
      } else {
        reject(
          new Error(`Element id '${elementId}' not found within ${timeout}ms`),
        );
      }
    }, timeout);
  });
}
