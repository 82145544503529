<script setup lang="ts">
import { ref } from 'vue';

import type { Props } from '#/components/generic/SparkButton/SparkButton.vue';
import SparkButton from '#/components/generic/SparkButton/SparkButton.vue';

import { useClickAnalytics } from './useClickAnalytics';

const sparkbutton = ref<any | null>(null);

const props = withDefaults(defineProps<Props>(), {
  look: 'filled',
  color: 'green',
  shape: 'pill',
  size: 'sm',
  disabled: false,

  type: 'button',
  loading: false,
  icon: undefined,
  to: undefined,
  isExternalLink: false,
  customColorSchemes: () => ({}),
});

useClickAnalytics(sparkbutton, {
  prefixContent: props.icon,
});
</script>
<template>
  <SparkButton ref="sparkbutton" v-bind="props">
    <template #icon>
      <slot name="icon" />
    </template>
    <slot />
  </SparkButton>
</template>
