<script lang="ts" setup>
import { CheckCircleFilled, ClockCircleOutlined } from '@ant-design/icons-vue';
import { computed } from 'vue';

import { useAuthStore } from '@/core/stores/auth';

const authStore = useAuthStore();

const handle = computed(() => {
  return authStore.userAccount?.iceChatUsername;
});

const verifiedAt = computed(() => {
  return authStore.userAccount?.iceChatUsernameVerifiedAt;
});

const waitForInvitationAccepted = computed(
  () => authStore.userAccount?.iceChatUsernameWaitForInvitationAccepted,
);
</script>
<template>
  <div class="space-y-2">
    <div class="flex items-center gap-2">
      <CheckCircleFilled v-if="handle" class="text-green" />
      <ClockCircleOutlined v-else />
      <span> 1. Add your ICE Chat account handle. </span>
    </div>
    <div>
      <div class="flex items-center gap-2">
        <CheckCircleFilled
          v-if="waitForInvitationAccepted === true"
          class="text-green"
        />
        <ClockCircleOutlined v-else />
        <div class="justify-end">
          <div>
            2. Add <em>'sparkcommodities'</em> to your ICE Chat contact list.
          </div>
          <div>
            3. Wait for Spark to accept your invitation and send you a welcome
            message on ICE.
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center gap-2">
      <CheckCircleFilled v-if="verifiedAt" class="text-green" />
      <ClockCircleOutlined v-else />
      <span>
        4. Click on the link in the welcome message. Your account is ready!
      </span>
    </div>
  </div>
</template>
