<template>
  <g>
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.6667 5.41667C10.6667 5.18655 10.4801 5 10.25 5C10.0199 5 9.83333 5.18655 9.83333 5.41667C9.83333 7.82198 9.03338 9.79403 7.66371 11.1637C6.29403 12.5334 4.32198 13.3333 1.91667 13.3333C1.68655 13.3333 1.5 13.5199 1.5 13.75C1.5 13.9801 1.68655 14.1667 1.91667 14.1667C4.32198 14.1667 6.29403 14.9666 7.66371 16.3363C9.03338 17.706 9.83333 19.678 9.83333 22.0833C9.83333 22.3135 10.0199 22.5 10.25 22.5C10.4801 22.5 10.6667 22.3135 10.6667 22.0833C10.6667 19.678 11.4666 17.706 12.8363 16.3363C14.206 14.9666 16.178 14.1667 18.5833 14.1667C18.8135 14.1667 19 13.9801 19 13.75C19 13.5199 18.8135 13.3333 18.5833 13.3333C16.178 13.3333 14.206 12.5334 12.8363 11.1637C11.4666 9.79403 10.6667 7.82198 10.6667 5.41667Z"
    />
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2381 2.2381C17.2381 2.1066 17.1315 2 17 2C16.8685 2 16.7619 2.1066 16.7619 2.2381C16.7619 3.61256 16.3048 4.73945 15.5221 5.52212C14.7394 6.30479 13.6126 6.7619 12.2381 6.7619C12.1066 6.7619 12 6.8685 12 7C12 7.1315 12.1066 7.2381 12.2381 7.2381C13.6126 7.2381 14.7394 7.69521 15.5221 8.47788C16.3048 9.26055 16.7619 10.3874 16.7619 11.7619C16.7619 11.8934 16.8685 12 17 12C17.1315 12 17.2381 11.8934 17.2381 11.7619C17.2381 10.3874 17.6952 9.26055 18.4779 8.47788C19.2606 7.69521 20.3874 7.2381 21.7619 7.2381C21.8934 7.2381 22 7.1315 22 7C22 6.8685 21.8934 6.7619 21.7619 6.7619C20.3874 6.7619 19.2606 6.30479 18.4779 5.52212C17.6952 4.73945 17.2381 3.61256 17.2381 2.2381Z"
    />
  </g>
</template>
