<script setup lang="ts">
import Cookies from 'js-cookie';
import { isNil } from 'lodash';
import { computed, ref } from 'vue';

import { analyticsService } from '@/core/services';
import { analyticsTrack } from '@/core/utils/usageAnalytics';
import SparkPopup from '@/shared/components/SparkPopup.vue';
import { GDPR_CONSENT_KEY } from '@/shared/misc/constants';
import { AnalyticsEvent } from '@/types';

const props = defineProps({
  showPopupTriggerOnly: { type: Boolean, default: false },
});

interface Emits {
  (e: 'gdpr:accept'): void;
  (e: 'gdpr:save'): void;
  (e: 'popup:closed'): void;
}
const emit = defineEmits<Emits>();

const isCookiePopupVisible = ref(false);
const gdprConsent = ref(
  !isNil(Cookies.get(GDPR_CONSENT_KEY))
    ? Cookies.get(GDPR_CONSENT_KEY) === 'true'
    : true,
);
const isCookieAccepted = ref(!isNil(Cookies.get(GDPR_CONSENT_KEY)));
const showCookieBanner = computed(() => {
  if (props.showPopupTriggerOnly) return false;
  return !isCookieAccepted.value;
});
const handleChange = (value: boolean) => {
  gdprConsent.value = value;
};
const acceptCookie = () => {
  isCookieAccepted.value = true;
  analyticsService.postAnalyticsConsent(true);
  analyticsTrack(AnalyticsEvent.GdprConsent, { value: true });
  Cookies.set(GDPR_CONSENT_KEY, 'true');
  emit('gdpr:accept');
};
const saveCookiePreference = () => {
  isCookiePopupVisible.value = false;
  isCookieAccepted.value = true;
  analyticsService.postAnalyticsConsent(gdprConsent.value);
  analyticsTrack(AnalyticsEvent.GdprConsent, { value: gdprConsent.value });
  Cookies.set(GDPR_CONSENT_KEY, gdprConsent.value?.toString());
  emit('gdpr:save');
};
const onPopupOpened = () => {
  isCookiePopupVisible.value = true;
  // Refresh gdprConsent
  if (!isNil(Cookies.get(GDPR_CONSENT_KEY))) {
    gdprConsent.value = Cookies.get(GDPR_CONSENT_KEY) === 'true';
  }
};
const onPopupClosed = () => {
  isCookiePopupVisible.value = false;
  emit('popup:closed');
};
</script>

<template>
  <Teleport to="#cookie-banner">
    <div
      v-if="showCookieBanner"
      id="cookie_policy"
      class="cookie_policy fixed bottom-0 left-0 right-0 m-6 flex flex-col items-center justify-between space-y-3 rounded-md border border-orange bg-white px-8 py-6 shadow-lg md:flex-row md:space-x-3 md:space-y-0"
    >
      <p class="text-gray-700">
        We use cookies and similar technologies to help provide a better
        experience. By clicking OK or turning an option on in Cookie
        Preferences, you agree to this, as outlined in our
        <a
          href="https://sparkcommodities.com/cookie-policy.html"
          target="_blank"
          rel="noopener"
          class="text-green"
          >Cookie Policy</a
        >. To change preferences or withdraw consent, please update your Cookie
        Preferences.
      </p>
      <div
        class="flex flex-wrap items-center space-y-3 md:shrink-0 md:flex-nowrap md:space-x-3 md:space-y-0"
      >
        <SparkTrackerButton
          style="min-width: 180px"
          class="w-full shrink-0 md:w-auto"
          @click="acceptCookie"
          >OK</SparkTrackerButton
        >
        <SparkTrackerButton
          look="outlined"
          color="green"
          style="min-width: 180px"
          class="w-full shrink-0 md:w-auto"
          @click="isCookiePopupVisible = true"
          >Cookie Preferences</SparkTrackerButton
        >
      </div>
    </div>
  </Teleport>
  <SparkPopup
    popup-title="Cookie Preferences"
    :show="isCookiePopupVisible"
    :mask-closable="false"
    @popup:opened="onPopupOpened"
    @popup:closed="onPopupClosed"
  >
    <template #clickable="{ setModalVisible }">
      <slot :set-modal-visible="setModalVisible"></slot>
    </template>
    <div class="space-y-6">
      <header class="">
        <p class="text-gray-600">
          Please choose whether this site may use Statistics Cookies, as
          described below:
        </p>
      </header>
      <hr />
      <div class="space-y-6">
        <div class="space-y-2">
          <div class="flex items-center space-x-2">
            <h1 class="h6">Strictly Necessary Cookies</h1>
            <!-- <a-switch :checked="true" disabled></a-switch> -->
          </div>
          <p class="text-gray-600">
            These cookies are necessary for the website to function.
          </p>
          <div class="space-y-2">
            <div class="flex items-center justify-between space-x-2">
              <div>
                <strong class="text-gray-600">Security</strong>
                <p class="text-gray-600">
                  This is required to enable core site functionality.
                </p>
              </div>
              <a-switch :checked="true" disabled></a-switch>
            </div>
            <hr />
            <div class="flex items-center justify-between space-x-2">
              <div>
                <strong class="text-gray-600">Support</strong>
                <p class="text-gray-600">
                  This is required for support chats and resolving issues
                  related to errors.
                </p>
              </div>
              <a-switch :checked="true" disabled></a-switch>
            </div>
            <hr />
            <div class="flex items-center justify-between space-x-2">
              <div>
                <strong class="text-gray-600">User Experience</strong>
                <p class="text-gray-600">
                  This is required for improving the usability of the site
                  anonymously.
                </p>
              </div>
              <a-switch :checked="true" disabled></a-switch>
            </div>
            <hr />
            <div class="flex items-center justify-between space-x-2">
              <div>
                <strong class="text-gray-600">User Settings</strong>
                <p class="text-gray-600">
                  This is required to persist user preferences.
                </p>
              </div>
              <a-switch :checked="true" disabled></a-switch>
            </div>
          </div>
        </div>
        <hr />
        <div class="space-y-2">
          <div class="flex items-center justify-between space-x-2">
            <h1 class="h6">Statistics Cookies</h1>
            <a-switch :checked="gdprConsent" @change="handleChange"></a-switch>
          </div>
          <p class="text-gray-600">
            These cookies allow us to analyse site usage so we can measure and
            improve performance.
          </p>
        </div>
        <a
          class="inline-block text-green"
          href="https://sparkcommodities.com/cookie-policy.html"
          target="_blank"
          rel="noopener"
          >See our Cookie Policy</a
        >
        <hr />
        <div class="flex justify-between">
          <div>&nbsp;</div>
          <SparkTrackerButton
            style="min-width: 128px"
            @click="saveCookiePreference"
            >Save</SparkTrackerButton
          >
        </div>
      </div>
    </div>
  </SparkPopup>
</template>
