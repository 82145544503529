<script setup lang="ts">
import { onMounted, ref } from 'vue';

interface Props {
  windowOpen?: boolean;
  iframeUrl: string;
}

withDefaults(defineProps<Props>(), {
  windowOpen: false,
});

interface Emits {
  (e: 'close'): void;
}

const emit = defineEmits<Emits>();

function listenForClose() {
  window.addEventListener('message', (event) => {
    if (
      event.data.action === 'minimize' &&
      event.data.data.target === 'deals'
    ) {
      closeWindow();
    }
  });
}

function closeWindow() {
  emit('close');
}

const isMounted = ref(false);

onMounted(() => {
  listenForClose();
  isMounted.value = true;
});
</script>
<template>
  <transition name="grow" appear>
    <div
      id="deals-window"
      class="absolute bottom-0 h-full w-full drop-shadow-lg"
    >
      <iframe
        v-if="isMounted"
        ref="basis-deals-bubble-iframe"
        :src="iframeUrl"
        class="h-full w-full"
      ></iframe>
    </div>
  </transition>
</template>

<style scoped></style>
<style lang="scss" scoped>
.fastFade-enter-active {
  @apply h-[50%] opacity-0 transition-all;
}

.fastFade-enter-to {
  @apply h-[100%] opacity-100;
}

.fastFade-leave-active {
  @apply opacity-100;
}

.fastFade-leave-to {
  @apply opacity-0 transition-all;
}
</style>
