<script setup lang="ts">
import { createReusableTemplate } from '@vueuse/core';
import { computed, inject } from 'vue';

import { assertIsDefined } from '#/utils/asserts';

import { THE_SIDE_BAR_MAIN_MENU_CONTEXT_KEY } from './share';

const props = withDefaults(
  defineProps<{
    to: string;
    icon: string;
    isLocked?: boolean;
  }>(),
  {
    isLocked: false,
  },
);

const context = inject(THE_SIDE_BAR_MAIN_MENU_CONTEXT_KEY);
assertIsDefined(
  context,
  'TheSidebarMainMenuLink requires TheSidebarMainMenu as parent',
);
const { isExpanded } = context;

const [DefineTemplate, ReuseTemplate] = createReusableTemplate();

const isExternal = computed(() => props.to.startsWith('http'));
</script>

<template>
  <DefineTemplate v-slot="{ href, isActive, clickHandler, external }">
    <a
      :href="href"
      :aria-current="isActive ? 'page' : undefined"
      :target="external ? '_blank' : undefined"
      class="relative col-span-2 grid h-11 grid-cols-[subgrid] text-gray-400 transition-colors hover:bg-gray-700/50 hover:text-gray-100 aria-[current=page]:bg-gray-700 aria-[current=page]:text-white"
      @click="(e) => clickHandler?.(e)"
    >
      <div v-if="icon" class="flex items-center justify-center">
        <SparkIcon :icon="icon" size="20" />
      </div>

      <div
        class="flex w-[calc(100px-1rem)] items-center overflow-hidden text-sm font-medium leading-none opacity-0 transition-opacity data-[expanded=true]:opacity-100"
        :data-expanded="isExpanded"
      >
        <slot />
      </div>
      <svg
        class="active-arrow absolute right-[-10px] top-0 text-gray-700"
        width="8"
        height="36"
        :aria-current="isActive ? 'page' : undefined"
      >
        <polygon points="0,0 8,18 0,36" fill="currentColor" />
      </svg>
    </a>
  </DefineTemplate>

  <RouterLink
    v-if="!isExternal"
    v-slot="{ href, navigate, isActive }"
    custom
    :to="to"
  >
    <ReuseTemplate
      :href="href"
      :is-active="isActive"
      :click-handler="navigate"
    />
  </RouterLink>

  <ReuseTemplate v-else :href="to" :external="true" />
</template>

<style scoped>
.active-arrow {
  &:not([aria-current='page']) {
    display: none;
  }
}
</style>
