<script setup lang="ts">
import { CompassOutlined } from '@ant-design/icons-vue';
import { onActivated, onDeactivated, onMounted, ref } from 'vue';

import { useAuthStore } from '@/core/stores/auth';
import { UITour, UITourStop } from '#/components/uiTour/';
import type { SparkProductColor } from '#/types/core';

withDefaults(
  defineProps<{
    color?: SparkProductColor;
  }>(),
  {
    color: 'green',
  },
);

const emit = defineEmits<{
  click: [];
}>();

const DELAY = 1000;
const UI_TOUR_LEARN_BUTTON = 'ui-tour-learn-button';
const uiTourIntro = ref<InstanceType<typeof UITour>>();
const authStore = useAuthStore();
let timeout: ReturnType<typeof setTimeout> | undefined;

async function acknowledge(exit: () => Promise<void>) {
  // no await on save to avoid blocking the UI
  authStore.saveUiMetadataValue('uiTourAcknowledged', true);
  await exit();
}

function showIntroAfterDelay() {
  const hasSeenIntro = authStore.getUiMetadataValue(
    'uiTourAcknowledged',
    false,
  );

  if (!hasSeenIntro) {
    timeout = setTimeout(() => {
      uiTourIntro.value?.start();
    }, DELAY);
  }
}

async function startTour(exit: () => Promise<void>) {
  await acknowledge(exit);
  emit('click');
}

function killTimeout() {
  if (timeout !== undefined) {
    clearTimeout(timeout);
    timeout = undefined;
  }
}

onMounted(() => {
  showIntroAfterDelay();
});

onActivated(() => {
  // called on initial mount
  // and every time it is re-inserted from the cache
  showIntroAfterDelay();
});

onDeactivated(() => {
  // called when removed from the DOM into the cache
  // and also when unmounted
  uiTourIntro.value?.exit({ omitAnimation: true });
  killTimeout();
});
</script>
<template>
  <SparkTrackerButton
    :id="UI_TOUR_LEARN_BUTTON"
    :color="color"
    look="text"
    size="xs"
    class="!p-0"
    @click="$emit('click')"
  >
    <template #icon>
      <CompassOutlined />
    </template>
    Guide
  </SparkTrackerButton>

  <UITour ref="uiTourIntro" teleport-to="#ui-tour">
    <UITourStop
      v-slot="{ exit }"
      placement="right-start"
      :spotlight-padding="10"
      :spotlight-corner-radius="999"
      :target-element-id="UI_TOUR_LEARN_BUTTON"
    >
      <div class="grid max-w-[400px] gap-y-4 rounded-lg bg-white px-8 py-6">
        <div>
          <h4 class="h4">Introducing Guides</h4>
          <div class="text-sm italic text-gray-600">&lt; 1 min to complete</div>
        </div>
        <p>
          Click on this link to take a quick guide of the page. The guide will
          take you through each component of the page and provide clarity on the
          data presented.
        </p>
        <div class="flex justify-end">
          <SparkTrackerButton look="text" @click="acknowledge(exit)"
            >Try Later</SparkTrackerButton
          >
          <SparkTrackerButton @click="startTour(exit)"
            >Start Guide</SparkTrackerButton
          >
        </div>
      </div>
    </UITourStop>
  </UITour>
</template>
