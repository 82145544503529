<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps<{
  title: string;
  icon: string;
  items: { title: string; to: string; showRedDot: boolean }[];
  isLocked?: boolean;
}>();

const route = useRoute();

const isActive = computed(() => {
  return props.items.some((item) => item.to.startsWith(route.path));
});
</script>

<template>
  <div>
    <div class="relative flex h-10 items-center gap-x-[0.6rem] text-gray-400">
      <div
        class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-700/50 transition-colors data-[active=true]:bg-gray-700"
        :data-active="isActive"
      >
        <SparkIcon :icon="isLocked ? 'lock' : icon" size="14" />
      </div>
      <div
        class="text-sm font-semibold leading-tight text-white data-[locked=true]:text-gray-600"
        :data-locked="isLocked"
      >
        {{ title }}
      </div>
      <div
        class="absolute bottom-0 left-[0.75rem] h-[8px] w-[2px] bg-gray-700/50 transition-colors data-[active=true]:bg-gray-700"
        :data-active="isActive"
      ></div>
    </div>
    <div
      class="ml-3 space-y-1 border-l-2 border-gray-700/50 transition-colors data-[active=true]:border-gray-700"
      :data-active="isActive"
    >
      <RouterLink
        v-for="item in items"
        :key="item.title"
        v-slot="{ href, navigate, isExactActive }"
        custom
        :to="item.to"
      >
        <a
          :href="href"
          :aria-current="isExactActive ? 'page' : undefined"
          :data-locked="isLocked"
          class="relative flex h-9 items-center pl-5 pr-2 text-sm font-medium leading-tight text-gray-400 transition-colors hover:bg-gray-700/50 hover:text-gray-100 aria-[current=page]:bg-gray-700 aria-[current=page]:font-bold aria-[current=page]:text-white data-[locked=true]:cursor-not-allowed data-[locked=true]:text-gray-600 data-[locked=true]:hover:bg-transparent"
          @click="(e) => (isLocked ? e.preventDefault() : navigate(e))"
          ><div class="relative w-fit">
            <div>{{ item.title }}</div>
            <sup
              v-if="item.showRedDot"
              class="absolute right-[-12px] top-[-2px] block h-[6px] w-[6px] rounded-full bg-red"
            ></sup>
          </div>
          <svg
            class="active-arrow absolute right-[-8px] top-0 h-9 w-[0.5rem] text-gray-700"
            viewBox="0 0 8 32"
            preserveAspectRatio="none"
            :aria-current="isExactActive ? 'page' : undefined"
          >
            <polygon points="0,0 8,16 0,32" fill="currentColor" /></svg
        ></a>
      </RouterLink>
    </div>
  </div>
</template>

<style scoped>
.active-arrow {
  &:not([aria-current='page']) {
    display: none;
  }
}
</style>
