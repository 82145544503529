export const UI_TOUR_IDS = {
  CONTRACTS: 'ui-tour-cargo-physical-contracts',
  SPOT_ASSESSMENT: 'ui-tour-spot-assessment',
  FORWARD_CURVE_PHYSICAL: 'ui-tour-forward-curve-physical',
  PRICE_CHART: 'ui-tour-price-chart',
  PRICE_FORMAT_AND_DOWNLOAD: 'ui-tour-price-format-and-download',
  DEALS: 'ui-tour-deals',
  CHALLENGE: 'ui-tour-challenge',
  METHODOLOGY: 'ui-tour-methodology',
} as const;
