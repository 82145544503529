import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

export default class MapService extends ApiBase {
  SERVICE_BASE_URL = `v1.0/maps`;

  async getReferenceData() {
    const response = await this.$http.get<
      components['schemas']['DetailResponse_PAPIMapReferenceDataDTO_']
    >(`${this.SERVICE_BASE_URL}/reference-data`);
    return response.data.data;

    // return {
    //   staticData: {
    //     modes: [
    //       { name: 'Price Snapshot', value: 'price-snapshot' },
    //       { name: 'DES Cargo', value: 'des-cargo', disabled: true },
    //       { name: 'FOB Cargo', value: 'fob-cargo', disabled: true },
    //       {
    //         name: 'European Hub Netbacks',
    //         value: 'european-hub-netbacks',
    //         disabled: true,
    //       },
    //     ],
    //     priceFormats: [
    //       { value: 'ttf-basis', name: 'TTF Basis' },
    //       { value: 'outright', name: 'Outright' },
    //     ],
    //     sparkReleases: ['2024-06-11', '2024-06-10'],
    //     contractMonths: ['2024-07-01', '2024-06-01'],
    //     units: [
    //       { value: 'usd-per-mmbtu', name: '$/MMBtu' },
    //       { value: 'eur-per-mwh', name: '€/MWh', disabled: true },
    //     ],
    //     desPorts: [
    //       {
    //         uuid: '8ddc9866-e8e7-4dda-9897-d96b9e345a88',
    //         shortName: 'Al Zour',
    //       },
    //       {
    //         uuid: '26401777-05ce-45a2-8808-484f4b72aa66',
    //         shortName: 'Bahia Blanca',
    //       },
    //       {
    //         uuid: 'e9bd594f-473c-4929-8502-ed8dbdb8108d',
    //         shortName: 'Chittagong',
    //       },
    //       { uuid: 'c4727c2c-e6ca-429e-8ba8-cbde73f52ba5', shortName: 'Dahej' },
    //       {
    //         uuid: '17e54480-7b61-4194-a449-9bdf39b9c3fa',
    //         shortName: 'Ereglisi',
    //       },
    //       {
    //         uuid: 'fd66234c-f14e-44a6-b190-6aea1cfe6fa1',
    //         shortName: 'Escobar',
    //       },
    //       {
    //         uuid: '27cbff75-377f-44f0-a576-01e8346bcd4a',
    //         shortName: 'Klaipeda',
    //       },
    //       {
    //         uuid: '1d44885e-4389-471f-871b-066b209c238f',
    //         shortName: 'Krk LNG FSRU',
    //       },
    //       {
    //         uuid: '22788f9d-c518-4a7b-8a0c-fbc376ea3056',
    //         shortName: 'Pengerang',
    //       },
    //       {
    //         uuid: 'f6587b94-d877-4429-b2d1-f477ea7a643d',
    //         shortName: 'Port Qasim FSRU',
    //       },
    //       { uuid: '6e8601ec-85fd-4ce5-8c26-f8c95a680682', shortName: 'Rayong' },
    //       {
    //         uuid: '819d6caa-08f6-483a-83a8-7bfdafe4c448',
    //         shortName: 'Revithoussa',
    //       },
    //       { uuid: '0df4d936-3f96-418a-9072-b51ddec8174f', shortName: 'Rovigo' },
    //       {
    //         uuid: '75569937-f268-4ffb-b0b4-f88be1c9e330',
    //         shortName: 'Singapore',
    //       },
    //       {
    //         uuid: 'b86a57f6-39ac-4b21-888f-4bac0d917ca4',
    //         shortName: 'Swinoujscie',
    //       },
    //     ],
    //     fobPorts: [
    //       { uuid: '03018806-b536-439c-9636-bf615b2c53f6', shortName: 'Gorgon' },
    //       {
    //         uuid: '4011700f-1bae-4e60-81a0-cf10be07536d',
    //         shortName: 'Delfin FLNG',
    //       },
    //       {
    //         uuid: 'b111c30c-e10e-49b5-b490-15921e540e5c',
    //         shortName: 'Murmansk',
    //       },
    //       {
    //         uuid: 'e27bf478-92df-43f0-876a-6e730a268c8f',
    //         shortName: 'Cove Point',
    //       },
    //       {
    //         uuid: 'c72a2f0c-675f-499f-a9fe-a085dd79c42d',
    //         shortName: 'Hammerfest',
    //       },
    //       {
    //         uuid: 'badb3356-4055-47ef-8157-a476d88b9c25',
    //         shortName: 'Rio Grande LNG',
    //       },
    //       {
    //         uuid: 'd01cce53-b4c9-44d4-b5b1-a68332d2db46',
    //         shortName: 'Corpus Christi',
    //       },
    //       { uuid: 'e77532d5-3a8b-445d-8a8d-b45dace4e11d', shortName: 'Soyo' },
    //       { uuid: '761ba3e4-24d6-4cc3-aee7-2eae161fe029', shortName: 'Qalhat' },
    //       { uuid: 'b45d6f9a-c64d-4019-9b9c-a27e809e10ce', shortName: 'GLNG' },
    //       {
    //         uuid: 'b67042fb-6458-47df-bcda-1bb29acb5c10',
    //         shortName: 'Bintulu',
    //       },
    //       {
    //         uuid: '5038e832-50ab-4818-838c-310f3f101aaa',
    //         shortName: 'Kamchatka',
    //       },
    //       {
    //         uuid: 'a7552e56-f412-4e09-9a00-24884dfd9db4',
    //         shortName: 'Lake Charles',
    //       },
    //       {
    //         uuid: '173113cd-f229-4585-a647-6736c02f67c9',
    //         shortName: 'Wheatstone',
    //       },
    //       {
    //         uuid: '41c2d5aa-4a88-48b4-8e98-5a6bcc3d36f9',
    //         shortName: 'Bonny LNG',
    //       },
    //       {
    //         uuid: 'eca96fcb-b96c-4acb-8694-5327d4b4d57c',
    //         shortName: 'Woodfibre LNG',
    //       },
    //       {
    //         uuid: '4fc2ec82-abe5-4f2f-89f0-7515c899b7ba',
    //         shortName: 'Bethioua',
    //       },
    //       {
    //         uuid: 'e3096493-33a3-428e-bb18-8c14c1679377',
    //         shortName: 'Tangguh',
    //       },
    //       {
    //         uuid: 'b4fe720d-841c-4037-8794-eef84624db1d',
    //         shortName: 'Plaquemines',
    //       },
    //       {
    //         uuid: '1f4b3db1-1d01-42d3-b8a5-50c8b113006e',
    //         shortName: 'Puerto Libertad',
    //       },
    //       {
    //         uuid: '39a76204-c5cb-43ce-bef9-7ceafd4ff758',
    //         shortName: 'LNG Canada',
    //       },
    //       {
    //         uuid: '7ace6b2d-210c-4872-81ee-4791863d2577',
    //         shortName: 'Calcasieu Pass',
    //       },
    //       {
    //         uuid: 'b454cadf-2cdd-4f0f-9058-b87a4db29b46',
    //         shortName: 'Freeport',
    //       },
    //       { uuid: 'd3f0ee6d-d718-454c-b057-3b4b87830d41', shortName: 'NWS' },
    //       { uuid: '66f48084-5924-4675-93e4-c40554e6ea9a', shortName: 'Bioko' },
    //       {
    //         uuid: 'c20695bb-306b-4ce5-a8f3-ef5d3769ea7a',
    //         shortName: 'Sabine Pass',
    //       },
    //       {
    //         uuid: 'cf0f20bc-d585-4328-b6ea-03c2250e1eaf',
    //         shortName: 'Ras Laffan',
    //       },
    //     ],
    //     routes: [],
    //   },
    //   defaultCriteria: {
    //     mode: 'price-snapshot',
    //     priceFormat: 'ttf-basis',
    //     releaseDate: '2024-06-11',
    //     contractMonth: '2024-07-01',
    //     unit: 'usd-per-mmbtu',
    //     desPorts: [],
    //     fobPorts: [],
    //     routes: [],
    //   },
    // };
  }

  async loadData() {
    const response = await this.$http.get<
      components['schemas']['DetailResponse_PAPIMapDTO_']
    >(`${this.SERVICE_BASE_URL}/latest`);
    return response.data.data;
  }

  async loadPorts(projection: 'usgc' | 'world') {
    const response = await this.$http.get<
      components['schemas']['DetailResponse_PAPIMapFOBPortsDTO_']
    >(`${this.SERVICE_BASE_URL}/ports/${projection}`);
    return response.data;
  }

  async loadPortsFobPrices(projection: 'usgc' | 'world', releaseDate: string) {
    const response = await this.$http.get<
      components['schemas']['DetailResponse_PAPIMapFOBPortsPricesDTO_']
    >(`${this.SERVICE_BASE_URL}/ports/${projection}/fob-prices`, {
      params: {
        releaseDate,
      },
    });
    return response.data;
  }
}
