<script setup lang="ts">
import dayjs from 'dayjs';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import SparkAlert from '#/components/generic/SparkAlert.vue';

import { useMaintenanceStore } from '../stores/maintenance';

const maintenanceStore = useMaintenanceStore();

const route = useRoute();

const messagesForEveryPage = computed(() => {
  return maintenanceStore.messagesForEveryPage;
});

const messagesForPage = computed(() => {
  return maintenanceStore.messagesForSpecificPagesMap.get(route.path);
});

const allMessages = computed(() => {
  return [...messagesForEveryPage.value, ...(messagesForPage.value ?? [])];
});

onMounted(async () => {
  await maintenanceStore.loadData();
});
</script>
<template>
  <SparkAlert
    v-for="msg in allMessages"
    :key="msg.hash"
    :type="msg.level"
    closable
  >
    <template #message>
      <div class="flex w-full justify-between gap-2">
        <Markdown :source="msg.message" :breaks="true" />
        <div class="mt-1 text-xs text-gray-600">
          {{ dayjs.utc(msg.lastUpdatedAt) }}
        </div>
      </div>
    </template>
  </SparkAlert>
</template>
