<template>
  <svg xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_15701_224741)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0192 1.46862C18.0192 1.46862 18.191 2.11838 18.3831 3.18471C18.9257 6.19622 19.6308 12.5303 17.0885 16.9337C15.8833 19.0211 12.9811 19.69 10.1261 18.7301L9.27771 18.3471L7.27805 22.0758C7.03909 22.4897 6.45832 22.6023 5.97912 22.3256C5.49992 22.049 5.30704 21.4897 5.546 21.0758L7.77449 17.4796L7.07642 16.9877C4.77216 14.9932 3.87543 12.1134 5.09146 10.0072C7.62419 5.62033 13.4704 3.05505 16.352 2.01415C17.371 1.64609 18.0192 1.46862 18.0192 1.46862ZM10.1067 16.8013L10.6724 15.7464C10.7127 15.7913 10.7512 15.8104 10.7857 15.7983L16.0186 13.2873C16.0754 13.2674 16.0837 13.0499 16.0372 12.8019C15.9907 12.5539 15.9076 12.3724 15.8508 12.3924L11.9993 13.2722L12.6385 12.0804L16.4916 9.63162C16.5342 9.61011 16.5597 9.43057 16.5485 9.23084C16.5372 9.03111 16.4938 8.88934 16.4512 8.91085L13.8059 9.90352L16.1264 5.57672C16.2257 5.40477 16.1474 5.17364 15.9482 5.05864C15.749 4.94364 15.5069 4.98977 15.4076 5.16173L12.8722 9.25313L12.4297 6.58909C12.4271 6.54144 12.2826 6.57467 12.104 6.66482C11.9254 6.75498 11.7826 6.86679 11.7853 6.91443L11.5983 11.309L10.9055 12.4269L9.82191 8.91165C9.81082 8.8525 9.61209 8.87125 9.37407 8.95497C9.13605 9.03869 8.95187 9.15461 8.96296 9.21376L9.39447 14.8653L8.69789 15.9894L8.15946 15.6101C6.25857 13.928 5.98204 11.9646 6.607 10.8822C8.05749 8.36984 10.9047 6.42679 13.6194 5.07845C14.7735 4.50525 15.8538 4.06374 16.7054 3.74829C16.8586 4.64087 17.0171 5.7934 17.0983 7.07579C17.2895 10.0957 17.0308 13.5336 15.573 16.0587C14.9538 17.1311 13.145 17.8693 10.7624 17.0973L10.1067 16.8013Z"
        fill="#21C08E"
      />
    </g>
    <defs>
      <clipPath id="clip0_15701_224741">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
