import { permissions } from '@/shared/misc/constants';
import type { components, SpAppProduct } from '@/types';
import type { Nullable } from '#/types/core';

export type RedirectLogicCallback = (
  subscriptions: components['schemas']['Subscription'][],
) => Nullable<string>;

export type MainMenuItem = {
  to: string;
  icon: string;
  title: string;
  permission?: {
    compareLogic?: 'OR' | 'AND';
    definitions: {
      product: SpAppProduct;
      permission: (typeof permissions)[keyof typeof permissions];
      compareUsing: 'startsWith' | 'equals';
    }[];
  };
  showOnlyForSparkUsers?: boolean;
  redirectLogic?: RedirectLogicCallback;
};

export const crossProductFeatures: MainMenuItem[] = [
  {
    to: import.meta.env.VITE_APP_INTRADAY_URL,
    icon: 'chartCandlestick',
    title: 'Intraday',
    redirectLogic: (subscriptions) => {
      const intradaySubscription = subscriptions.find(
        (subscription) => subscription.product === 'intraday-platform',
      );

      let redirectTo: string | undefined;

      if (!intradaySubscription?.canAccess) {
        redirectTo = '/intraday-intro';
      }

      return redirectTo;
    },
  },
  {
    to: '/spark-signals',
    icon: 'signals',
    title: 'Signals',
    permission: {
      compareLogic: 'OR',
      definitions: [
        {
          product: 'lng-freight-platform',
          permission: permissions.ACCESS_MULTIPLE_VERTICALS_SIGNALS,
          compareUsing: 'startsWith',
        },
        {
          product: 'lng-freight-platform',
          permission: permissions.ACCESS_MULTIPLE_VERTICALS_SIGNALS,
          compareUsing: 'startsWith',
        },
      ],
    },
  },
  {
    to: '/slot-swap-hub',
    icon: 'globe',
    title: 'Hub',
    permission: {
      compareLogic: 'OR',
      definitions: [
        {
          product: 'connect-platform',
          permission: 'connect-platform-read',
          compareUsing: 'startsWith',
        },
      ],
    },
  },
];

export const miscFeatures: MainMenuItem[] = [
  {
    to: '/data-integrations',
    icon: 'plug',
    title: 'Data Integrations',
  },
  {
    to: '/more',
    icon: 'more',
    title: 'More',
  },
];

export const accountFeatures: MainMenuItem[] = [
  {
    to: '/account/profile',
    icon: 'profile',
    title: 'Profile',
  },
  {
    to: '/logout',
    icon: 'logOut',
    title: 'Logout',
  },
];
