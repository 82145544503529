import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

export default class SettlementService extends ApiBase {
  async getSettlement(contract: string) {
    const response = await this.$http.get<
      components['schemas']['DetailResponse_TimeSeriesDTO_']
    >(`v1/time-series/${contract}`);

    return response?.data?.data;
  }
}
