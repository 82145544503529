<script lang="ts" setup>
import { computed } from 'vue';

import { AnalyticsEvent } from '@/types';

import { useMasterDataStore } from '../stores/masterData';
import { analyticsTrack } from '../utils/usageAnalytics';

const masterDataStore = useMasterDataStore();
const show = computed(() => masterDataStore.show409Modal);

function forceRefresh() {
  analyticsTrack(AnalyticsEvent.REFRESH_409);
  window.location.reload();
}

function close() {
  analyticsTrack(AnalyticsEvent.CLOSE_409);
  masterDataStore.show409Modal = false;
}
</script>
<template>
  <a-modal width="600px" :visible="show" :footer="false" :closable="false">
    <div class="space-y-6 px-8">
      <div class="space-y-2">
        <div class="flex justify-center">
          <SparkIcon icon="warning" size="72" class="text-yellow-500" />
        </div>
        <h1 class="h1-sm text-center">Outdated Data</h1>
      </div>
      <p class="text-lg">
        You just tried to update a resource with an older data. To be able to
        proceed with the action, please refresh the page and try again.
      </p>
      <div class="items-center justify-center space-y-6 py-4">
        <SparkTrackerButton size="md" class="w-full" @click="forceRefresh"
          >Refresh Page</SparkTrackerButton
        >
        <SparkTrackerButton
          class="w-full"
          look="outlined"
          color="red"
          @click="close"
          >Stay on Page Without Submitting</SparkTrackerButton
        >
      </div>
    </div>
  </a-modal>
</template>
