<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import {
  GuideLinkText,
  InlineButton,
  SparkUITour,
  SparkUITourStop,
  SparkUITourStopLast,
  SparkUITourStopWelcome,
} from '@/core/components/UITour';
import { useAppStore } from '@/core/stores/app';
import { useMenuStore } from '@/core/stores/menu';
import { UI_TOUR_IDS as SHARED_UI_TOUR_IDS } from '@/shared/misc/constants';

import {
  CONTRACTS,
  FINANCIAL,
  FREIGHT_TITLES,
  PHYSICAL,
  TOOLS,
} from '../the-nav/constants';
import { FREIGHT_UI_TOUR_IDS } from './constants';

const router = useRouter();
const appStore = useAppStore();
const menuStore = useMenuStore();

const { freightMenu } = storeToRefs(menuStore);

const uiTour = ref<InstanceType<typeof SparkUITour>>();

async function goToRoute(routeUrl: string, done: () => void) {
  await router.push(routeUrl);
  done();
}

function hasAccessTo(title: string, subtitle: string) {
  const section = freightMenu.value.find((item) => item.title === title);
  if (!section) return false;

  const found = section.items.find((item) => item.title === subtitle);
  if (!found) return false;

  return !found.isLocked;
}

appStore.listenEvent('freightUITour', () => {
  uiTour.value?.startTour();
});
</script>

<template>
  <SparkUITour :no-trigger-button="true" ref="uiTour">
    <SparkUITourStopWelcome title="Spark Freight">
      <p>
        Spark's Freight product covers the global LNG Freight market, providing
        $/day assessments in both the Atlantic (Spark30) and Pacific (Spark25)
        basins, as well as additional analytical tools to help you analyse the
        freight market.
      </p>

      <p>
        Spark's platform covers the entire LNG value chain, starting with LNG
        Freight costs. If you're looking to value LNG cargoes delivered to
        Europe, visit our
        <InlineButton color="basis" is-external-link to="/cargo"
          >Cargo product</InlineButton
        >. Alternatively, explore regas costs across major European terminals,
        as well as regas slot availability, with our
        <InlineButton color="access" is-external-link to="/access"
          >Access product</InlineButton
        >.
      </p>
    </SparkUITourStopWelcome>

    <SparkUITourStop
      v-if="
        hasAccessTo(CONTRACTS, PHYSICAL) ||
        hasAccessTo(CONTRACTS, FREIGHT_TITLES.DASHBOARD)
      "
      title="Spark30/25 (Phy)"
      placement="right-start"
      :target-element-id="FREIGHT_UI_TOUR_IDS.FREIGHT_PHYSICAL_TITLE"
      @before-mount-action-async="goToRoute('/freight/spark30', $event)"
    >
      <p>
        The Physical Spark30/25 pages show Spark's LNG Freight physical spot and
        forward price assessment in both the Atlantic (Spark30) and Pacific
        (Spark25) basins.
      </p>

      <p>
        These are our benchmark assessments and are assessed daily by leading
        brokerage firms, giving you an accurate picture of the current freight
        market.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(CONTRACTS, FINANCIAL)"
      title="Spark30/25 (FFA)"
      placement="right-start"
      :target-element-id="FREIGHT_UI_TOUR_IDS.FREIGHT_FFA_TITLE"
      @before-mount-action-async="goToRoute('/freight/ffa/spark30ffa', $event)"
    >
      <p>
        Financial Spark30/25 pages shows Spark's LNG Freight financial (FFA)
        forward curve price assessment in both the Atlantic (Spark30FFA) and
        Pacific (Spark25FFA) basins.
      </p>

      <p>
        Gain a 2 year forward view of the freight market, and track how these
        global forward rates evolve daily. These are also ICE-listed tradeable
        products, allowing you to hedge your freight positions.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, FREIGHT_TITLES.GLOBAL_CALC)"
      title="Global Calculator"
      placement="right-start"
      :target-element-id="FREIGHT_UI_TOUR_IDS.GLOBAL_CALCULATOR_TITLE"
      @before-mount-action-async="
        goToRoute('/freight/global-calculator', $event)
      "
    >
      <p>
        Fully customisable freight calculator offering flexibility for a wide
        range of inputs. Select any global location, vessel specifications, and
        adjust key assumptions like boil-off rates, LNG price, and port/carbon
        costs.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, FREIGHT_TITLES.HISTORICAL_DATA)"
      title="Historical Data"
      placement="right-start"
      :target-element-id="FREIGHT_UI_TOUR_IDS.HISTORICAL_DATA_TITLE"
      @before-mount-action-async="goToRoute('/freight/historical-data', $event)"
    >
      <p>View the Historical Data for any of our assessed freight contracts.</p>
      <p>
        Track seasonality trends by viewing our full historical dataset of
        Spark30S and Spark25S assessments.
      </p>
      <p>
        Understand the volatility of your chosen contract month by viewing the
        historical assessments of that FFA contract
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, FREIGHT_TITLES.SPREAD_CHARTING)"
      title="Spread Charting"
      placement="right-start"
      :target-element-id="FREIGHT_UI_TOUR_IDS.SPREAD_CHARTING_TITLE"
      @before-mount-action-async="goToRoute('/freight/spread', $event)"
    >
      <p>
        Spread charting is a tool that allows you to track the evolution of
        price spreads between different Spark LNG freight rates (Physical and
        FFA), enabling you to spot fundamental trends and explore potential
        trading opportunities.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, FREIGHT_TITLES.ROUND_TRIP_CALC)"
      title="Round Trip Calculator"
      placement="right-start"
      :target-element-id="FREIGHT_UI_TOUR_IDS.ROUND_TRIP_CALCULATOR_TITLE"
      @before-mount-action-async="
        goToRoute('/freight/round-trip-calculator', $event)
      "
    >
      <p>
        Our Round Trip Calculator allows you to vary the assumptions behind our
        benchmark assessed rates to produce a $/day rate tailored to your market
        use case.
      </p>

      <p>
        Adjust assumptions such as LNG price, ballast bonus and positioning fee
        to produce an adjusted Spark30/25 rate.
      </p>

      <p>
        For the latest assessed European DES LNG price, visit our
        <InlineButton color="basis" is-external-link to="/cargo"
          >Cargo page</InlineButton
        >.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, FREIGHT_TITLES.INDEXATION)"
      title="Indexation"
      placement="right-start"
      :target-element-id="FREIGHT_UI_TOUR_IDS.INDEXATION_TITLE"
      @before-mount-action-async="goToRoute('/freight/indexation', $event)"
    >
      <p>
        Our Indexation tool is designed to provide clarity around Spark's
        contracts, as well as helping incorporate Spark benchmark rates into
        freight contracts.
      </p>

      <p>
        Track how your FFA contract month of interest is priced in over the
        month using the Indexation chart, and model min/max gain and loss
        scenarios. Use our Indexation builder to easily construct suggested
        contract wording based on your contractual needs
      </p>

      <p>
        Read our Indexation page and Financial Spark30/25 page methodologies to
        understand how our FFA contracts settle against our physical
        assessments.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, FREIGHT_TITLES.ROUTES)"
      title="Routes"
      placement="right-start"
      :target-element-id="FREIGHT_UI_TOUR_IDS.ROUTES_TITLE"
      @before-mount-action-async="goToRoute('/freight/routes/discover', $event)"
    >
      <p>
        Our Routes page allows you to see $/MMBtu route costs between major FOB
        and DES locations, with 2 years of forward visibility.
      </p>

      <p>
        These curves enable market participants to view $/MMBtu costs for routes
        that are relevant to specific portfolios. Choose from a range of load
        and discharge ports, via points and account for varying levels of
        congestion in the Panama Canal.
      </p>

      <p>
        Want to value LNG cargoes at an FOB port of your choice? Visit our
        <InlineButton color="basis" is-external-link to="/cargo/netbacks">
          Cargo Netbacks tool </InlineButton
        >.
      </p>

      <p>
        Looking to deliver into Europe? Rank NW-Europe terminals by
        competitiveness, for your chosen FOB port, using our
        <InlineButton
          color="access"
          is-external-link
          to="/access/fob-netbacks/terminals"
        >
          Access FOB Hub Netbacks page </InlineButton
        >.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, FREIGHT_TITLES.FLOATING_STORAGE)"
      title="Floating Storage"
      placement="right-start"
      :target-element-id="FREIGHT_UI_TOUR_IDS.FLOATING_STORAGE_TITLE"
      @before-mount-action-async="
        goToRoute('/freight/floating-storage', $event)
      "
    >
      <p>
        Spark's Floating Storage tool combines Spark's LNG Freight and DES LNG
        forward curves to calculate forward floating storage economics.
      </p>

      <p>
        Explore how LNG freight and cargo prices evolve over the next year and
        identify opportunities to float rather than deliver LNG immediately.
        Recognise when current freight rates might be undervalued, or LNG and
        gas timespreads might be mispriced, based on these arbitrage
        opportunities.
      </p>

      <p>
        View how European DES LNG prices are pricing in over the next year on
        our
        <InlineButton color="basis" is-external-link to="/cargo"
          >Cargo NWE/SWE page</InlineButton
        >
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, FREIGHT_TITLES.NEUTRAL)"
      title="Neutral"
      placement="right-start"
      :target-element-id="FREIGHT_UI_TOUR_IDS.NEUTRAL_TITLE"
      @before-mount-action-async="
        goToRoute('/freight/offset-calculator', $event)
      "
    >
      <p>
        Spark's Neutral calculator lets you explore how carbon pricing can be
        incorporated into your specified route. Use this page to vary the
        underlying assumptions in our carbon calculator, to explore different
        market scenarios or to tailor our data to your specific use case.
      </p>

      <p>
        This tool also directly feeds into our Routes page to calculate carbon
        prices as part of the EU ETS scheme.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      title="Data Integrations"
      placement="right-start"
      :target-element-id="SHARED_UI_TOUR_IDS.DATA_INTEGRATIONS_TITLE"
      @before-mount-action-async="goToRoute('/data-integrations', $event)"
    >
      <p>
        Incorporate Spark's data directly into your workflow with Data
        Integrations. Pull our data through our third-party connectors, Excel
        Add-in or API.
      </p>

      <p>
        To find out more on each of these services, explore the relevant section
        within Data Integrations. If you need help setting up the API, or would
        like to further discuss anything API or platform related, please email
        <InlineButton is-external-link to="mailto:info@sparkcommodities.com"
          >info@sparkcommodities.com</InlineButton
        >
        or use our Chatbox located in the bottom-right of the screen.
      </p>
    </SparkUITourStop>

    <SparkUITourStopLast
      title="Thanks for taking the tour!"
      placement="bottom"
      :target-element-id="FREIGHT_UI_TOUR_IDS.FREIGHT_GUIDE_BUTTON"
      @before-mount-action-async="goToRoute('/dashboard', $event)"
    >
      <p>
        We hope you have a sufficient overview of what our Freight product has
        to offer. You may further explore on each of the pages by clicking on
        the
        <GuideLinkText class="text-green-500" /> link at the top of their
        respective pages.
      </p>
      <p>
        You may also access this guide at any time by clicking on this
        <GuideLinkText class="text-green-500" />
        link in the Dashboard page.
      </p>
    </SparkUITourStopLast>
  </SparkUITour>
</template>
