<script setup lang="ts">
import { GlobalOutlined } from '@ant-design/icons-vue';

function openCorpWeb() {
  window.open('https://sparkcommodities.com', '_blank');
}
</script>
<template>
  <a-row class="h-full" type="flex" justify="center" align="middle">
    <a-col class="max-w-md text-gray-100">
      <div class="mr-4 flex items-center justify-center space-x-2">
        <SparkIcon icon="logo" :size="44" />
        <span
          class="h1 pt-2 align-top text-5xl font-semibold leading-normal text-gray-100"
        >
          Spark
          <a-tooltip title="Open corporate website" placement="right">
            <sup>
              <SparkTrackerButton look="text" class="!px-0" @click="openCorpWeb"
                ><GlobalOutlined class="text-lg"
              /></SparkTrackerButton>
            </sup>
          </a-tooltip>
        </span>
      </div>
      <p class="h2 font-medium text-gray-100">Empowering trade, together.</p>
    </a-col>
  </a-row>
</template>
