<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.3109 20.7466C17.8426 20.2624 16.8823 19.0876 16.3716 17.8106C15.7141 16.1669 13.4317 19.073 16.5923 22.3522L16.5913 22.3533C16.6102 22.3722 16.6292 22.391 16.6481 22.4095C16.6666 22.4284 16.6852 22.4472 16.7041 22.4661L16.7051 22.465C19.9844 25.6256 22.8904 23.3433 21.2467 22.6858C19.9699 22.175 18.7952 21.2149 18.3109 20.7466Z"
      fill="#CDE0DF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4125 22.1578C16.4129 22.1582 16.4132 22.1585 16.4136 22.1588C16.4714 22.2228 16.5312 22.2869 16.5932 22.3511L16.5921 22.3522C16.611 22.371 16.6299 22.3897 16.6487 22.4082C16.6673 22.4271 16.686 22.446 16.7049 22.465L16.706 22.4639C19.9852 25.6245 22.8913 23.3421 21.2476 22.6847C21.2307 22.6779 21.2139 22.6711 21.1971 22.6642C21.1831 22.4718 21.0349 22.2897 20.7246 22.1656C19.6564 21.7383 18.6598 20.9966 18.079 20.4913L18.0787 20.4917C18.1648 20.5897 18.2435 20.6749 18.3117 20.7454C18.7895 21.2075 19.9398 22.1487 21.1971 22.6642C21.2557 23.4669 18.9809 24.4497 16.4136 22.1588C16.4133 22.1585 16.4129 22.1581 16.4126 22.1577L16.4125 22.1578Z"
      fill="#8EB9B6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.6659 23.4432C20.2452 23.0417 18.9044 22.2818 17.7863 21.1637C16.7079 20.0853 15.9628 18.7999 15.5509 17.4354C15.8455 17.2383 16.1792 17.3283 16.3725 17.8115C16.8837 19.0895 17.8451 20.2652 18.3129 20.7486C18.7964 21.2165 19.972 22.1779 21.25 22.6891C21.6967 22.8678 21.8074 23.1664 21.6659 23.4432Z"
      fill="#8EB9B6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.2522 20.6926C21.7416 24.182 27.399 24.182 30.8884 20.6926C34.3778 17.2032 34.3778 11.5458 30.8884 8.05639C27.399 4.56699 21.7416 4.56699 18.2522 8.05639C14.7628 11.5458 14.7628 17.2032 18.2522 20.6926ZM21.245 17.6985C23.0815 19.535 26.0591 19.535 27.8956 17.6985C29.7322 15.862 29.7322 12.8844 27.8956 11.0479C26.0591 9.21134 23.0815 9.21134 21.245 11.0479C19.4085 12.8844 19.4085 15.862 21.245 17.6985Z"
      fill="#CDE0DF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.4187 19.7375C20.9283 22.8264 26.2815 22.6946 29.6338 19.3424C32.8198 16.1564 33.0968 11.163 30.4649 7.66275C30.6085 7.78908 30.7489 7.92081 30.886 8.05792C34.3754 11.5473 34.3754 17.2047 30.886 20.6941C27.3966 24.1835 21.7392 24.1835 18.2498 20.6941C17.9464 20.3907 17.6694 20.0709 17.4187 19.7375Z"
      fill="#8EB9B6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.6369 8.03972C21.1681 8.03972 18.3561 10.8517 18.3561 14.3205C18.3561 14.71 18.0403 15.0259 17.6507 15.0259C17.2611 15.0259 16.9453 14.71 16.9453 14.3205C16.9453 10.0725 20.3889 6.62891 24.6369 6.62891C25.0265 6.62891 25.3423 6.94473 25.3423 7.33431C25.3423 7.7239 25.0265 8.03972 24.6369 8.03972Z"
      fill="#E3EDED"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.4647 20.4837C27.9335 20.4837 30.7454 17.6717 30.7454 14.203C30.7454 13.8134 31.0613 13.4976 31.4508 13.4976C31.8404 13.4976 32.1562 13.8134 32.1562 14.203C32.1562 18.4509 28.7126 21.8945 24.4647 21.8945C24.0751 21.8945 23.7593 21.5787 23.7593 21.1891C23.7593 20.7995 24.0751 20.4837 24.4647 20.4837Z"
      fill="#E3EDED"
    />
    <path
      d="M28.6148 18.4187C26.3811 20.6524 22.7595 20.6524 20.5258 18.4187C18.2921 16.1849 18.2921 12.5634 20.5258 10.3297C22.7595 8.09594 26.3811 8.09594 28.6148 10.3297C30.8485 12.5634 30.8485 16.1849 28.6148 18.4187Z"
      fill="#1A9A72"
    />
    <path
      opacity="0.9"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.8606 10.5618L24.937 10.3033L24.1342 13.4398L25.0578 13.6983L25.8606 10.5618ZM23.5395 14.0318L21.1787 11.671L21.7707 11.0789L24.1614 13.4696L23.5694 14.0617L23.5448 14.0371L23.811 14.945L20.7307 15.6916L20.4627 14.7776L23.5395 14.0318ZM27.1554 17.6477L24.7647 15.257L25.3567 14.665L27.7474 17.0557L27.1554 17.6477ZM24.0493 18.3641L23.1116 18.0725L23.8115 14.9456L24.7492 15.2372L24.0493 18.3641ZM28.5236 13.8899L28.2415 12.9426L25.0583 13.6988L25.3404 14.646L28.5236 13.8899Z"
      fill="white"
    />
    <path
      opacity="0.3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.948 18.011C20.9441 18.0149 20.944 18.0211 20.9476 18.0251C20.9991 18.0813 21.0521 18.1366 21.1065 18.191C23.1168 20.2014 26.373 20.2045 28.3795 18.1981C30.3859 16.1917 30.3827 12.9355 28.3724 10.9251C28.3232 10.8759 28.2733 10.8279 28.2226 10.7811C28.2096 10.7691 28.1894 10.7696 28.1769 10.7821V10.7821C28.1655 10.7934 28.164 10.8112 28.1729 10.8245C29.1933 12.337 28.651 14.829 26.8162 16.6638C24.9749 18.5051 22.4719 19.0448 20.961 18.0098C20.9569 18.0071 20.9514 18.0075 20.948 18.011V18.011Z"
      fill="#07261C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.0928 11.1533C23.5637 10.6824 23.2298 9.96642 22.6147 10.2216C22.1245 10.4249 21.6683 10.7237 21.2734 11.1185C20.8859 11.506 20.591 11.9525 20.388 12.4323C20.1241 13.0558 20.8505 13.3956 21.3292 12.9169V12.9169C21.3708 12.8753 21.4072 12.829 21.439 12.7797C21.6296 12.4849 21.8561 12.2029 22.118 11.941C22.3803 11.6788 22.6627 11.452 22.958 11.2612C23.0065 11.2299 23.052 11.1941 23.0928 11.1533V11.1533Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.47506 21.7808C10.026 21.2298 10.9193 21.2298 11.4703 21.7808L13.7696 24.0801L10.4443 27.4055L10.4358 27.397L8.44123 29.3917L8.44966 29.4001L5.12434 32.7254L2.82499 30.4261C2.27403 29.8751 2.27404 28.9818 2.82499 28.4309L4.15512 27.1007C4.70608 26.5498 5.59935 26.5498 6.15031 27.1007L6.8152 27.7656L8.80982 25.771L8.14493 25.1061C7.59398 24.5552 7.59398 23.6619 8.14493 23.1109L9.47506 21.7808Z"
      fill="#6EA5A2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4703 21.7808C10.9193 21.2298 10.026 21.2298 9.47506 21.7808L8.14493 23.1109C7.59398 23.6619 7.59398 24.5552 8.14493 25.1061L8.47746 25.4386C7.92651 24.8877 7.92651 23.9944 8.47747 23.4435L9.80759 22.1133C10.3585 21.5624 11.2518 21.5624 11.8028 22.1133L11.4703 21.7808ZM10.436 27.3972L10.1035 27.0647L8.1083 29.0599L8.44083 29.3924L10.436 27.3972ZM3.15695 30.7592C2.606 30.2082 2.606 29.3149 3.15696 28.764L4.48708 27.4338C5.03804 26.8829 5.93132 26.8829 6.48227 27.4338L6.14974 27.1013C5.59879 26.5504 4.70551 26.5504 4.15455 27.1013L2.82442 28.4314C2.27347 28.9824 2.27347 29.8757 2.82442 30.4266L3.15695 30.7592Z"
      fill="#E7F4F4"
    />
    <path
      d="M13.6318 22.6143C13.9991 22.247 14.5946 22.247 14.9619 22.6143L16.2921 23.9444C16.6594 24.3117 16.6594 24.9072 16.2921 25.2745L6.64864 34.918C6.28134 35.2853 5.68582 35.2853 5.31851 34.918L3.98839 33.5878C3.62108 33.2205 3.62108 32.625 3.98839 32.2577L13.6318 22.6143Z"
      fill="#B7D2D1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.79988 34.3974L5.31946 34.917C5.68677 35.2843 6.28229 35.2843 6.64959 34.917L16.293 25.2735C16.6603 24.9062 16.6603 24.3107 16.293 23.9434L15.7734 23.4238C16.1407 23.7911 16.1407 24.3867 15.7734 24.754L6.13001 34.3974C5.7627 34.7647 5.16719 34.7647 4.79988 34.3974Z"
      fill="#89AEAD"
    />
    <rect
      x="15.2461"
      y="21.0371"
      width="3.76217"
      height="1.41081"
      rx="0.705407"
      transform="rotate(45 15.2461 21.0371)"
      fill="#CDE0DF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.7181 21.5104L16.8869 22.6793C17.1624 22.9548 17.1624 23.4014 16.8869 23.6769C16.6202 23.9436 16.193 23.9521 15.9161 23.7023L16.4099 24.1961C16.6854 24.4716 17.132 24.4716 17.4075 24.1961C17.683 23.9206 17.683 23.474 17.4075 23.1985L15.7448 21.5358C15.7361 21.5271 15.7272 21.5186 15.7181 21.5104Z"
      fill="#6EA5A2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4367 24.2215C16.4276 24.2133 16.4187 24.2049 16.4099 24.1961L14.7473 22.5335C14.4718 22.258 14.4718 21.8114 14.7473 21.5359C15.0227 21.2604 15.4694 21.2604 15.7449 21.5359L15.9065 21.6975C15.6296 21.4477 15.2024 21.4562 14.9356 21.7229C14.6602 21.9984 14.6602 22.445 14.9356 22.7205L16.4367 24.2215Z"
      fill="white"
    />
    <rect
      x="12.7812"
      y="23.0938"
      width="0.288134"
      height="12.2271"
      transform="rotate(45 12.7812 23.0938)"
      fill="#426B69"
    />
  </svg>
</template>
