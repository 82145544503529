<script setup lang="ts">
import { ref } from 'vue';

import SparkIcon from './SparkIcon';

withDefaults(
  defineProps<{
    type: 'success' | 'info' | 'warning' | 'error';
    closable?: boolean;
  }>(),
  {
    closable: false,
  },
);
const show = ref(true);
function close() {
  show.value = false;
}
</script>
<template>
  <div
    v-if="show"
    class="theme w-full border-1 bg-shade-light drop-shadow"
    :class="type"
  >
    <div class="background-hue flex justify-between gap-4 px-4 py-2">
      <slot name="message" />
      <div v-if="closable">
        <button @click="close">
          <SparkIcon class="mt-1 text-gray-700" icon="close" size="15" />
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.theme {
  &.success {
    @apply border-green-500;
    .background-hue {
      @apply bg-green-100/50;
    }
  }
  &.info {
    @apply border-blue-500;
    .background-hue {
      @apply bg-blue-100/50;
    }
  }
  &.warning {
    @apply border-yellow-500;
    .background-hue {
      @apply bg-yellow-100/50;
    }
  }
  &.error {
    @apply border-red-500;
    .background-hue {
      @apply bg-red-100/50;
    }
  }
}
</style>
