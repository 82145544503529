import { createEventHook } from '@vueuse/core';
import { defineStore } from 'pinia';
import { filter } from 'rxjs';
import { computed, watch } from 'vue';

import type { components } from '@/types';
import { useWebSocket } from '#/composables/useWebSocket';

import { useAuthStore } from './auth';

const VERTICALS: components['schemas']['VerticalEnum'][] = [
  'lng-freight',
  'lng-basis',
  'access',
  'connect',
];
export const useWebSocketStore = defineStore('websocket', () => {
  const urlParams = new URLSearchParams();
  urlParams.append('vertical', VERTICALS.join(','));
  const url = `${
    import.meta.env.VITE_APP_SP_API_BASE_URL_WS
  }/v1.0/ws?${urlParams.toString()}`;

  const accessToken = computed(() => useAuthStore().accessToken);
  const {
    websocket,
    allMessageObservable,
    jsonObservable,
    state,
    open,
    close,
  } = useWebSocket('default', url, accessToken);

  const dtoReceivedResult = createEventHook<any>();

  watch(jsonObservable, (newVal) => {
    if (newVal) {
      newVal.pipe(filter((msg) => msg.type === 'dto')).subscribe((obj) => {
        dtoReceivedResult.trigger(obj);
      });
    }
  });

  return {
    websocket,
    state,
    allMessageObservable,
    jsonObservable,
    open,
    close,
    onDtoReceived: dtoReceivedResult.on,
  };
});
