import { useAuthStore } from '@/core/stores/auth';
import { useMasterDataStore } from '@/core/stores/masterData';
import {
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
} from '@/shared/configs/apiConfig';

import AccessService from './AccessService';

const getRefreshTokenFn = () => useAuthStore().refreshToken();

const getAccessTokenFn = () => useAuthStore().accessToken;
const logoutFn = () => useAuthStore().logout();

const handle409Conflict = () => useMasterDataStore().handle409Conflict();

const accessService = new AccessService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

export { accessService };
