<template>
  <g>
    <path
      fill="currentColor"
      d="M19.4923 4.79999C18.8494 4.79999 18.3264 5.32319 18.3264 5.96615C18.3264 6.06767 18.3434 6.16439 18.3679 6.25823L15.3446 9.35039C15.0905 9.22535 14.8058 9.15335 14.5039 9.15335C13.8838 9.15335 13.333 9.45095 12.9828 9.90959L10.6486 9.10463C10.5286 8.58839 10.067 8.20127 9.5148 8.20127C8.8716 8.20127 8.3484 8.72471 8.3484 9.36791C8.3484 9.46463 8.36352 9.55727 8.38584 9.64727L4.79184 13.3368C4.7064 13.3166 4.61784 13.3034 4.5264 13.3034C3.8832 13.3034 3.36 13.8266 3.36 14.4696C3.36 15.1125 3.88344 15.6357 4.5264 15.6357C5.16936 15.6357 5.69232 15.1125 5.69232 14.4696C5.69232 14.3673 5.67504 14.2699 5.65008 14.1753L9.23424 10.4959C9.32472 10.5185 9.4176 10.5338 9.5148 10.5338C9.80664 10.5338 10.0702 10.4225 10.2749 10.2449L12.5899 11.0429C12.5897 11.0515 12.5885 11.0597 12.5885 11.0681C12.5885 12.1238 13.4474 12.983 14.5034 12.983C15.5594 12.983 16.4184 12.1241 16.4184 11.0681C16.4184 10.7508 16.3397 10.4522 16.2026 10.1882L19.2242 7.09799C19.3104 7.11839 19.3992 7.13207 19.4918 7.13207C20.135 7.13207 20.6582 6.60887 20.6582 5.96591C20.6582 5.32295 20.1353 4.79999 19.4923 4.79999ZM14.5039 11.7835C14.1096 11.7835 13.789 11.4629 13.789 11.0685C13.789 10.674 14.1098 10.3533 14.5039 10.3533C14.898 10.3533 15.2189 10.6742 15.2189 11.0685C15.2189 11.4629 14.898 11.7835 14.5039 11.7835Z"
    />
    <path
      fill="currentColor"
      d="M9.51478 19.2096C9.87796 19.2096 10.1724 18.9152 10.1724 18.552C10.1724 18.1889 9.87796 17.8944 9.51478 17.8944C9.15159 17.8944 8.85718 18.1889 8.85718 18.552C8.85718 18.9152 9.15159 19.2096 9.51478 19.2096Z"
    />
    <path
      fill="currentColor"
      d="M14.504 19.2096C14.8672 19.2096 15.1616 18.9152 15.1616 18.552C15.1616 18.1889 14.8672 17.8944 14.504 17.8944C14.1408 17.8944 13.8464 18.1889 13.8464 18.552C13.8464 18.9152 14.1408 19.2096 14.504 19.2096Z"
    />
    <path
      fill="currentColor"
      d="M4.52608 19.2092C4.88913 19.2092 5.18343 18.9148 5.18343 18.5518C5.18343 18.1887 4.88913 17.8944 4.52608 17.8944C4.16303 17.8944 3.86871 18.1887 3.86871 18.5518C3.86871 18.9148 4.16303 19.2092 4.52608 19.2092Z"
    />
    <path
      fill="currentColor"
      d="M19.4926 19.2096C19.8557 19.2096 20.1502 18.9152 20.1502 18.552C20.1502 18.1889 19.8557 17.8944 19.4926 17.8944C19.1294 17.8944 18.835 18.1889 18.835 18.552C18.835 18.9152 19.1294 19.2096 19.4926 19.2096Z"
    />
  </g>
</template>
