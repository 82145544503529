import axios from 'axios';

import ApiBase from '@/core/services/ApiBase';
import { getAppRelease } from '@/core/utils/getAppRelease';

export default class AnalyticsService extends ApiBase {
  SERVICE_BASE_URL = `v1/analytics`;

  async postAnalyticsTrack(
    event: string,
    properties: any,
    context: { product: string },
    gdprConsent: boolean,
  ) {
    if (!gdprConsent) {
      const headers = {
        'X-Client-App-Version': getAppRelease(),
        'X-Client-App-Name': 'sp-application',
      };
      // POST without setting default headers
      return axios.post(
        `${this.config.baseURL}/${this.SERVICE_BASE_URL}`,
        {
          event,
          properties,
          context,
        },
        { withCredentials: true, headers },
      );
    } else {
      return this.$http.post(
        this.SERVICE_BASE_URL,
        {
          event,
          properties,
          context,
        },
        { withCredentials: true },
      );
    }
  }

  postAnalyticsConsent = async (gdprConsent: boolean) => {
    try {
      await this.$http.post(
        `${this.SERVICE_BASE_URL}/consent`,
        {
          gdprConsent,
        },
        { withCredentials: true },
      );
    } catch (err) {
      console.error('postAnalyticsConsent exception: ', err);
    }
  };
}
