import {
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
} from '@/shared/configs/apiConfig';

import { useAuthStore } from '../stores/auth';
import { useMasterDataStore } from '../stores/masterData';
import AnalyticsService from './AnalyticsService';
import AuthService from './AuthService';
import MaintenanceService from './Maintenance';
import MapService from './MapService';
import MarketingService from './MarketingService';
import MasterDataService from './MasterDataService';
import PollService from './PollService';
import PriceReleaseService from './PriceReleaseService';
import PriceSignalService from './PriceSignalService';
import SparkSignalsService from './SparkSignalsService';
import StaticDataService from './StaticDataService';
import UserAccountService from './UserAccountService';

const getRefreshTokenFn = () => useAuthStore().refreshToken();

const getAccessTokenFn = () => useAuthStore().accessToken;
const logoutFn = () => useAuthStore().logout();

const handle409Conflict = () => useMasterDataStore().handle409Conflict();

const masterDataService = new MasterDataService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);
const userAccountService = new UserAccountService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);
const marketingService = new MarketingService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);
const staticDataService = new StaticDataService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

const analyticsService = new AnalyticsService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

const priceReleaseService = new PriceReleaseService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

const authService = new AuthService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

const maintenanceService = new MaintenanceService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);
const priceSignalService = new PriceSignalService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

const pollService = new PollService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

const sparkSignalsService = new SparkSignalsService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

const mapService = new MapService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

export {
  analyticsService,
  authService,
  maintenanceService,
  mapService,
  marketingService,
  masterDataService,
  pollService,
  priceReleaseService,
  priceSignalService,
  sparkSignalsService,
  staticDataService,
  userAccountService,
};
