<script setup lang="ts">
import type { Props } from '#/components/generic/SparkButton/SparkButton.vue';

const props = withDefaults(
  defineProps<{
    color?: 'green' | 'basis' | 'access';
    to?: Props['to'];
    isExternalLink?: Props['isExternalLink'];
  }>(),
  {
    color: 'green',
    to: undefined,
    isExternalLink: false,
  },
);
</script>

<template>
  <SparkTrackerButton look="text" class="!p-0 !text-[1em]" v-bind="props">
    <slot />
  </SparkTrackerButton>
</template>
