<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';

import type { Change } from '@/types';

import { useFeatureVersionStore } from '../stores/featureVersion';

interface Props {
  size?: number | string;
  componentId: string;
  featureId: string;
}

const props = withDefaults(defineProps<Props>(), {
  size: 10,
});

const localUpdatedFeatures = ref<Change | null>(null);

const showRedDot = computed(() => {
  if (!localUpdatedFeatures.value?.[props.featureId]) return false;
  return localUpdatedFeatures.value[props.featureId].includes(
    props.componentId,
  );
});

// User can dismiss it by clicking on red-dot manually
const dismissRedDotLocally = () => {
  if (!localUpdatedFeatures.value) return;
  delete localUpdatedFeatures.value[props.featureId];
};

onMounted(() => {
  const featureVersionStore = useFeatureVersionStore();
  localUpdatedFeatures.value = featureVersionStore.updatedFeatures;
  // Dismiss for next reload
  if (showRedDot.value) {
    setTimeout(() => {
      featureVersionStore.dismissComponent({
        featureId: props.featureId,
        componentId: props.componentId,
      });
    }, 1000);
  }
});
</script>

<template>
  <div class="flex">
    <slot></slot>
    <sup
      v-if="showRedDot"
      :style="`width: ${size}px; height: ${size}px;`"
      class="ml-1 mt-1 inline-block rounded-full bg-spark-red"
      @click="dismissRedDotLocally"
      >&nbsp;</sup
    >
  </div>
</template>
