<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  href: string;
  as?: string;
  openCrispChatIfAvailable?: boolean;
  preFillCrispMessage?: string;
}
const props = withDefaults(defineProps<Props>(), {
  as: 'router-link',
  openCrispChatIfAvailable: false,
  preFillCrispMessage: '',
  minimized: false,
});

const hasChatBox = computed(() => {
  return !!(window.CRISP_WEBSITE_ID && window.$crisp);
});

function tryOpenChatBox(event: Event) {
  if (!hasChatBox.value) {
    return;
  }

  event?.preventDefault();
  if (window.$crisp) {
    window.$crisp.push(['do', 'chat:open']);
    // Reset previously pre-filled message
    window.$crisp.push(['set', 'message:text', ['']]);
    if (props.preFillCrispMessage) {
      window.$crisp.push(['set', 'message:text', [props.preFillCrispMessage]]);
    }
  }
}
</script>

<template>
  <component
    :is="as"
    v-if="!openCrispChatIfAvailable || !hasChatBox"
    :href="href"
    :to="href"
    class="flex w-full items-center justify-center gap-2 bg-orange-600 p-2 text-white transition-colors duration-300 hover:bg-orange-500 hover:text-white"
  >
    <div class="text-xs font-bold">
      <slot></slot>
    </div>
    <SparkIcon icon="chevronright" size="20" />
  </component>
  <button
    v-if="openCrispChatIfAvailable && hasChatBox"
    class="block w-full bg-orange-600 p-2 text-xs text-white transition-colors duration-300 hover:bg-orange-500 hover:text-white"
    @click="tryOpenChatBox"
  >
    <div class="flex items-center justify-center gap-2">
      <div class="text-xs font-bold">
        <slot></slot>
      </div>
      <SparkIcon icon="chevronright" size="20" />
    </div>
  </button>
</template>
