import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

export default class OffsetCalculatorService extends ApiBase {
  getReferenceData() {
    return this.$http
      .get<
        components['schemas']['DetailResponse_ReferenceData_']
      >(`v1/neutral/lng-freight/reference-data`)
      .then((response) => {
        return response?.data?.data;
      });
  }

  getPledges() {
    return this.$http
      .get<
        components['schemas']['DetailResponse_PledgesWrapper_']
      >(`v1/neutral/lng-freight/pledges`)
      .then((response) => {
        return response?.data?.data;
      });
  }

  submitPledge(pledge: components['schemas']['NewPledge']) {
    return this.$http
      .post<
        components['schemas']['DetailResponse_PledgesWrapper_']
      >(`v1/neutral/lng-freight/pledges`, pledge)
      .then((response) => {
        return response?.data?.data;
      });
  }
}
