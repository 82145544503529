<template>
  <g>
    <path
      fill="currentColor"
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
    />
    <path
      d="M7.47169 12.6636H14.9184L11.665 15.9169C11.405 16.1769 11.405 16.6036 11.665 16.8636C11.7896 16.9884 11.9587 17.0586 12.135 17.0586C12.3114 17.0586 12.4805 16.9884 12.605 16.8636L16.9984 12.4703C17.2584 12.2103 17.2584 11.7903 16.9984 11.5303L12.605 7.13694C12.345 6.87694 11.925 6.87694 11.665 7.13694C11.405 7.39694 11.405 7.81694 11.665 8.07694L14.9184 11.3303H7.47169C7.10503 11.3303 6.80503 11.6303 6.80503 11.9969C6.80503 12.3636 7.10503 12.6636 7.47169 12.6636Z"
      fill="white"
    />
  </g>
</template>
