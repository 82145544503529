import type { components } from '@/types';

import ApiBase from './ApiBase';

const SCOPES = ['lng-freight-prices:read', 'access'];

export default class AuthService extends ApiBase {
  SERVICE_BASE_URL = `v1.0/oauth`;

  async login(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('grant_type', 'password');
    params.append('scope', SCOPES.join(','));

    try {
      const response = await this.$http.post<
        components['schemas']['TokenResponse']
      >(`${this.SERVICE_BASE_URL}/token`, params, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  async doRefreshToken() {
    const params = new URLSearchParams();

    params.append('grant_type', 'refresh_token');
    params.append('scope', SCOPES.join(','));

    try {
      const response = await this.$http.post<
        components['schemas']['TokenResponse']
      >(`${this.SERVICE_BASE_URL}/token`, params, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  async logout() {
    await this.$http.post(`${this.SERVICE_BASE_URL}/logout`, undefined, {
      withCredentials: true,
    });
  }
}
