export const CARGO_UI_TOUR_IDS = {
  CARGO_PHYSICAL_TITLE: 'ui-tour-cargo-physical-title',
  CARGO_FINANCIAL_TITLE: 'ui-tour-cargo-financial-title',
  SPARK_CLOSE_TITLE: 'ui-tour-spark-close-title',
  HISTORICAL_DATA_TITLE: 'ui-tour-historical-data-title',
  SETTLEMENT_TRACKER_TITLE: 'ui-tour-settlement-tracker-title',
  NETBACKS_TITLE: 'ui-tour-netbacks-title',
  ARB_BREAKEVEN_TITLE: 'ui-tour-arb-breakeven-title',
  DES_NETFORWARDS_TITLE: 'ui-tour-des-netforwards-title',
  CARGO_GUIDE_BUTTON: 'ui-tour-cargo-guide-button',
};
