import { ColorScheme } from './types';

export const filled: ColorScheme = {
  background: {
    green: 'bg-green-500 hover:bg-green-400 active:bg-green-600',
    orange: 'bg-orange-500 hover:bg-orange-400 active:bg-orange-600',
    blue: 'bg-blue-500 hover:bg-blue-400 active:bg-blue-600',
    red: 'bg-red-500 hover:bg-red-400 active:bg-red-600',
    'coral-red':
      'bg-coral-red-500 hover:bg-coral-red-400 active:bg-coral-red-600',
    gray: 'bg-gray-500 hover:bg-gray-400 active:bg-gray-600',
    black: 'bg-black hover:bg-gray-900 active:bg-gray-800',
    yellow: 'bg-yellow-500 hover:bg-yellow-400 active:bg-yellow-600',
    white: 'bg-white hover:bg-gray-50 active:bg-gray-200',
    neutral: 'bg-neutral-500 hover:bg-neutral-400 active:bg-neutral-600',
    basis: 'bg-basis-500 hover:bg-basis-400 active:bg-basis-600',
    kpler: 'bg-kpler-500 hover:bg-kpler-400 active:bg-kpler-600',
    'basis-connect':
      'bg-basis-connect-500 hover:bg-basis-connect-400 active:bg-basis-connect-600',
    access: 'bg-access-500 hover:bg-access-400 active:bg-access-600',
  },
  backgroundDisabled: {
    green: 'bg-green-500/60',
    orange: 'bg-orange-500/60',
    blue: 'bg-blue-500/60',
    red: 'bg-red-500/60',
    'coral-red': 'bg-coral-red-500/60',
    gray: 'bg-gray-500/60',
    black: 'bg-black/60',
    yellow: 'bg-yellow-500/60',
    white: 'bg-white/60',
    neutral: 'bg-neutral-500/60',
    basis: 'bg-basis-500/60',
    kpler: 'bg-kpler-500/60',
    'basis-connect': 'bg-basis-connect-500/60',
    access: 'bg-access-500/60',
  },
  border: {
    green:
      'border border-transparent hover:border-transparent active:border-transparent',
    orange:
      'border border-transparent hover:border-transparent active:border-transparent',
    blue: 'border border-transparent hover:border-transparent active:border-transparent',
    red: 'border border-transparent hover:border-transparent active:border-transparent',
    'coral-red':
      'border border-transparent hover:border-transparent active:border-transparent',
    gray: 'border border-transparent hover:border-transparent active:border-transparent',
    black:
      'border border-transparent hover:border-transparent active:border-transparent',
    yellow:
      'border border-transparent hover:border-transparent active:border-transparent',
    white:
      'border border-transparent hover:border-transparent active:border-transparent',
    neutral:
      'border border-transparent hover:border-transparent active:border-transparent',
    basis:
      'border border-transparent hover:border-transparent active:border-transparent',
    kpler:
      'border border-transparent hover:border-transparent active:border-transparent',
    'basis-connect':
      'border border-transparent hover:border-transparent active:border-transparent',
    access:
      'border border-transparent hover:border-transparent active:border-transparent',
  },
  borderDisabled: {
    green: 'border border-transparent',
    orange: 'border border-transparent',
    blue: 'border border-transparent',
    red: 'border border-transparent',
    'coral-red': 'border border-transparent',
    gray: 'border border-transparent',
    black: 'border border-transparent',
    yellow: 'border border-transparent',
    white: 'border border-transparent',
    neutral: 'border border-transparent',
    basis: 'border border-transparent',
    kpler: 'border border-transparent',
    'basis-connect': 'border border-transparent',
    access: 'border border-transparent',
  },
  text: {
    green: 'text-white hover:text-white',
    orange: 'text-white hover:text-white',
    blue: 'text-white hover:text-white',
    red: 'text-white hover:text-white',
    'coral-red': 'text-white hover:text-white',
    gray: 'text-white hover:text-white',
    black: 'text-white hover:text-white',
    yellow: 'text-gray-900 hover:text-gray-900',
    white: 'text-gray-900 hover:text-gray-900',
    neutral: 'text-white hover:text-white',
    basis: 'text-white hover:text-white',
    kpler: 'text-white hover:text-white',
    'basis-connect': 'text-white hover:text-white',
    access: 'text-black hover:text-black',
  },
  textDisabled: {
    green: 'text-white/60 disabled:text-white/60',
    orange: 'text-white/60 disabled:text-white/60',
    blue: 'text-white/60 disabled:text-white/60',
    red: 'text-white/60 disabled:text-white/60',
    'coral-red': 'text-white/60 disabled:text-white/60',
    gray: 'text-white/60 disabled:text-white/60',
    black: 'text-white/60 disabled:text-white/60',
    yellow: 'text-white/60 disabled:text-white/60',
    white: 'text-white/60 disabled:text-white/60',
    neutral: 'text-white/60 disabled:text-white/60',
    basis: 'text-white/60 disabled:text-white/60',
    kpler: 'text-white/60 disabled:text-white/60',
    'basis-connect': 'text-white/60 disabled:text-white/60',
    access: 'text-black/60 disabled:text-black/60',
  },
  radiateColor: {
    green: '#4DCDA5',
    orange: '#E7A56D',
    blue: '#0D94FD',
    red: '#C1596C',
    'coral-red': '#ff6466',
    gray: '#BDCCD4',
    black: 'rgba(0, 0, 0, 0.5)',
    yellow: '#facc15',
    white: 'rgba(255, 255, 255, 0.5)',
    neutral: '#52c98e',
    basis: '#B9B3FB',
    kpler: '',
    'basis-connect': '#a78bfa',
    access: '#facc15',
  },
};

export const outlined: ColorScheme = {
  background: {
    green: 'bg-white hover:bg-green-50 active:bg-green-50',
    orange: 'bg-white hover:bg-orange-50 active:bg-orange-50',
    blue: 'bg-white hover:bg-blue-50 active:bg-blue-50',
    red: 'bg-white hover:bg-red-50 active:bg-red-50',
    'coral-red': 'bg-white hover:bg-coral-red-50 active:bg-coral-red-50',
    gray: 'bg-white hover:bg-gray-50 active:bg-gray-50',
    black: 'bg-white hover:bg-gray-50 active:bg-gray-50',
    yellow: 'bg-white hover:bg-yellow-50 active:bg-yellow-50',
    white: 'bg-white hover:bg-gray-50 active:bg-gray-200',
    neutral: 'bg-white hover:bg-neutral-50 active:bg-neutral-50',
    basis: 'bg-white hover:bg-basis-50 active:bg-basis-50',
    kpler: 'bg-white hover:bg-kpler-50 active:bg-kpler-50',
    'basis-connect':
      'bg-white hover:bg-basis-connect-50 active:bg-basis-connect-50',
    access: 'bg-white hover:bg-access-50 active:bg-access-50',
  },
  backgroundDisabled: {
    green: 'bg-white/60',
    orange: 'bg-white/60',
    blue: 'bg-white/60',
    red: 'bg-white/60',
    'coral-red': 'bg-white/60',
    gray: 'bg-white/60',
    black: 'bg-white/60',
    yellow: 'bg-white/60',
    white: 'bg-white/60',
    neutral: 'bg-white/60',
    basis: 'bg-white/60',
    kpler: 'bg-white/60',
    'basis-connect': 'bg-white/60',
    access: 'bg-white/60',
  },
  border: {
    green:
      'border border-green-500 hover:border-green-400 active:border-green-600',
    orange:
      'border border-orange-500 hover:border-orange-400 active:border-orange-600',
    blue: 'border border-blue-500 hover:border-blue-400 active:border-blue-600',
    red: 'border border-red-500 hover:border-red-400 active:border-red-600',
    'coral-red':
      'border border-coral-red-500 hover:border-coral-red-400 active:border-coral-red-600',
    gray: 'border border-gray-500 hover:border-gray-400 active:border-gray-600',
    black: 'border border-black hover:border-gray-900 active:border-gray-800',
    yellow:
      'border border-yellow-500 hover:border-yellow-400 active:border-yellow-600',
    white: 'border border-white hover:border-gray-50 active:border-white-100',
    neutral:
      'border border-neutral-500 hover:border-neutral-400 active:border-neutral-600',
    basis:
      'border border-basis-500 hover:border-basis-400 active:border-basis-600',
    kpler:
      'border border-kpler-500 hover:border-kpler-400 active:border-kpler-600',
    'basis-connect':
      'border border-basis-connect-500 hover:border-basis-connect-400 active:border-basis-connect-600',
    access:
      'border border-access-500 hover:border-access-400 active:border-access-600',
  },
  borderDisabled: {
    green: 'border border-green-500/60',
    orange: 'border border-orange-500/60',
    blue: 'border border-blue-500/60',
    red: 'border border-red-500/60',
    'coral-red': 'border border-coral-red-500/60',
    gray: 'border border-gray-500/60',
    black: 'border border-black/60',
    yellow: 'border border-yellow-500/60',
    white: 'border border-white/60',
    neutral: 'border border-neutral-500/60',
    basis: 'border border-basis-500/60',
    kpler: 'border border-kpler-500/60',
    'basis-connect': 'border border-basis-connect-500/60',
    access: 'border border-access-500/60',
  },
  text: {
    green: 'text-green-500 hover:text-green-400 active:text-green-600',
    orange: 'text-orange-500 hover:text-orange-400 active:text-orange-600',
    blue: 'text-blue-500 hover:text-blue-400 active:text-blue-600',
    red: 'text-red-500 hover:text-red-400 active:text-red-600',
    'coral-red':
      'text-coral-red-500 hover:text-coral-red-400 active:text-coral-red-600',
    gray: 'text-gray-800 hover:text-gray-700 active:text-gray-900',
    black: 'text-black hover:text-gray-900 active:text-gray-800',
    yellow: 'text-yellow-500 hover:text-yellow-400 active:text-yellow-600',
    white: 'text-gray-800 hover:text-gray-700 active:text-gray-900',
    neutral: 'text-neutral-500 hover:text-neutral-400 active:text-neutral-600',
    basis: 'text-basis-500 hover:text-basis-400 active:text-basis-600',
    kpler: 'text-kpler-500 hover:text-kpler-400 active:text-kpler-600',
    'basis-connect':
      'text-basis-connect-500 hover:text-basis-connect-400 active:text-basis-connect-600',
    access: 'text-gray-800 hover:text-gray-800 active:text-gray-800',
  },
  textDisabled: {
    green: 'text-green-500/60',
    orange: 'text-orange-500/60',
    blue: 'text-blue-500/60',
    red: 'text-red-500/60',
    'coral-red': 'text-coral-red-500/60',
    gray: 'text-gray-500/60',
    black: 'text-black/60',
    yellow: 'text-yellow-500/60',
    white: 'text-white/60',
    neutral: 'text-neutral-500/60',
    basis: 'text-basis-500/60',
    kpler: 'text-kpler-500/60',
    'basis-connect': 'text-basis-connect-500/60',
    access: 'text-access-500/60',
  },

  radiateColor: {
    green: '#4DCDA5',
    orange: '#E7A56D',
    blue: '#0D94FD',
    red: '#C1596C',
    'coral-red': '#ff6466',
    gray: '#BDCCD4',
    black: 'rgba(0, 0, 0, 0.5)',
    yellow: '#facc15',
    white: 'rgba(255, 255, 255, 0.5)',
    neutral: '#52c98e',
    basis: '#B9B3FB',
    kpler: '',
    'basis-connect': '#a78bfa',
    access: '#facc15',
  },
};

export const text: ColorScheme = {
  background: {
    green: 'bg-transparent hover:bg-transparent active:bg-transparent',
    orange: 'bg-transparent hover:bg-transparent active:bg-transparent',
    blue: 'bg-transparent hover:bg-transparent active:bg-transparent',
    red: 'bg-transparent hover:bg-transparent active:bg-transparent',
    'coral-red': 'bg-transparent hover:bg-transparent active:bg-transparent',
    gray: 'bg-transparent hover:bg-transparent active:bg-transparent',
    black: 'bg-transparent hover:bg-transparent active:bg-transparent',
    yellow: 'bg-transparent hover:bg-transparent active:bg-transparent',
    white: 'bg-transparent hover:bg-transparent active:bg-transparent',
    neutral: 'bg-transparent hover:bg-transparent active:bg-transparent',
    basis: 'bg-transparent hover:bg-transparent active:bg-transparent',
    kpler: 'bg-transparent hover:bg-transparent active:bg-transparent',
    'basis-connect':
      'bg-transparent hover:bg-transparent active:bg-transparent',
    access: 'bg-transparent hover:bg-transparent active:bg-transparent',
  },
  backgroundDisabled: {
    green: 'bg-transparent',
    orange: 'bg-transparent',
    blue: 'bg-transparent',
    red: 'bg-transparent',
    'coral-red': 'bg-transparent',
    gray: 'bg-transparent',
    black: 'bg-transparent',
    yellow: 'bg-transparent',
    white: 'bg-transparent',
    neutral: 'bg-transparent',
    basis: 'bg-transparent',
    kpler: 'bg-transparent',
    'basis-connect': 'bg-transparent',
    access: 'bg-transparent',
  },
  border: {
    green: 'border-0',
    orange: 'border-0',
    blue: 'border-0',
    red: 'border-0',
    'coral-red': 'border-0',
    gray: 'border-0',
    black: 'border-0',
    yellow: 'border-0',
    white: 'border-0',
    neutral: 'border-0',
    basis: 'border-0',
    kpler: 'border-0',
    'basis-connect': 'border-0',
    access: 'border-0',
  },
  borderDisabled: {
    green: 'border-0',
    orange: 'border-0',
    blue: 'border-0',
    red: 'border-0',
    'coral-red': 'border-0',
    gray: 'border-0',
    black: 'border-0',
    yellow: 'border-0',
    white: 'border-0',
    neutral: 'border-0',
    basis: 'border-0',
    kpler: 'border-0',
    'basis-connect': 'border-0',
    access: 'border-0',
  },
  text: {
    green: 'text-green-500 hover:text-green-400 active:text-green-600',
    orange: 'text-orange-500 hover:text-orange-400 active:text-orange-600',
    blue: 'text-blue-500 hover:text-blue-400 active:text-blue-600',
    red: 'text-red-500 hover:text-red-400 active:text-red-600',
    'coral-red':
      'text-coral-red-500 hover:text-coral-red-400 active:text-coral-red-600',
    gray: 'text-gray-800 hover:text-gray-700 active:text-gray-900',
    black: 'text-black hover:text-gray-900 active:text-gray-800',
    yellow: 'text-yellow-500 hover:text-yellow-400 active:text-yellow-600',
    white: 'text-white hover:text-gray-50 active:text-gray-200',
    neutral: 'text-neutral-500 hover:text-neutral-400 active:text-neutral-600',
    basis: 'text-basis-500 hover:text-basis-400 active:text-basis-600',
    kpler: 'text-kpler-500 hover:text-kpler-400 active:text-kpler-600',
    'basis-connect':
      'text-basis-connect-500 hover:text-basis-connect-400 active:text-basis-connect-600',
    access: 'text-access-600 hover:text-access-500 active:text-access-700',
  },
  textDisabled: {
    green: 'text-green-500/60',
    orange: 'text-orange-500/60',
    blue: 'text-blue-500/60',
    red: 'text-red-500/60',
    'coral-red': 'text-coral-red-500/60',
    gray: 'text-gray-500/60',
    black: 'text-black/60',
    yellow: 'text-yellow-500/60',
    white: 'text-white/60',
    neutral: 'text-neutral-500/60',
    basis: 'text-basis-500/60',
    kpler: 'text-kpler-500/60',
    'basis-connect': 'text-basis-connect-500/60',
    access: 'text-access-500/60',
  },
  radiateColor: undefined,
};

export const unfilled: ColorScheme = {
  background: {
    green: 'hover:bg-green-700 active:bg-green-800',
    orange: 'hover:bg-orange-700 active:bg-orange-800',
    blue: 'hover:bg-blue-700 active:bg-blue-800',
    red: 'hover:bg-red-700 active:bg-red-800',
    'coral-red': 'hover:bg-coral-red-700 active:bg-coral-red-800',
    gray: 'hover:bg-gray-700 active:bg-gray-800',
    black: 'hover:bg-gray-900 active:bg-gray-800',
    yellow: 'hover:bg-yellow-700 active:bg-yellow-800',
    white: 'hover:bg-gray-50 active:bg-gray-200',
    neutral: 'hover:bg-neutral-700 active:bg-neutral-800',
    basis: 'hover:bg-basis-700 active:bg-basis-800',
    kpler: 'hover:bg-kpler-700 active:bg-kpler-800',
    'basis-connect': 'hover:bg-basis-connect-700 active:bg-basis-connect-800',
    access: 'hover:bg-access-700 active:bg-access-800',
  },
  backgroundDisabled: {
    green: '',
    orange: '',
    blue: '',
    red: '',
    'coral-red': '',
    gray: '',
    black: '',
    yellow: '',
    white: '',
    neutral: '',
    basis: '',
    kpler: '',
    'basis-connect': '',
    access: '',
  },
  border: {
    green: 'border-0',
    orange: 'border-0',
    blue: 'border-0',
    red: 'border-0',
    'coral-red': 'border-0',
    gray: 'border-0',
    black: 'border-0',
    yellow: 'border-0',
    white: 'border-0',
    neutral: 'border-0',
    basis: 'border-0',
    kpler: 'border-0',
    'basis-connect': 'border-0',
    access: 'border-0',
  },
  borderDisabled: {
    green: 'border-0',
    orange: 'border-0',
    blue: 'border-0',
    red: 'border-0',
    'coral-red': 'border-0',
    gray: 'border-0',
    black: 'border-0',
    yellow: 'border-0',
    white: 'border-0',
    neutral: 'border-0',
    basis: 'border-0',
    kpler: 'border-0',
    'basis-connect': 'border-0',
    access: 'border-0',
  },
  text: {
    green: 'text-gray-700 hover:text-gray-100',
    orange: 'text-gray-700 hover:text-gray-100',
    blue: 'text-gray-700 hover:text-gray-100',
    red: 'text-gray-700 hover:text-gray-100',
    'coral-red': 'text-gray-700 hover:text-gray-100',
    gray: 'text-gray-700 hover:text-gray-100',
    black: 'text-gray-700 hover:text-gray-100',
    yellow: 'text-gray-700 hover:text-gray-100',
    white: 'text-gray-100 hover:text-gray-700',
    neutral: 'text-gray-700 hover:text-gray-100',
    basis: 'text-gray-700 hover:text-gray-100',
    kpler: 'text-gray-700 hover:text-gray-100',
    'basis-connect': 'text-gray-700 hover:text-gray-100',
    access: 'text-gray-700 hover:text-gray-100',
  },
  textDisabled: {
    green: 'hover:text-gray-700/30',
    orange: 'hover:text-gray-700/30',
    blue: 'hover:text-gray-700/30',
    red: 'hover:text-gray-700/30',
    'coral-red': 'hover:text-gray-700/30',
    gray: 'hover:text-gray-700/30',
    black: 'hover:text-gray-700/30',
    yellow: 'hover:text-gray-700/30',
    white: 'hover:text-gray-100/30',
    neutral: 'hover:text-gray-700/30',
    basis: 'hover:text-gray-700/30',
    kpler: 'hover:text-gray-700/30',
    'basis-connect': 'hover:text-gray-700/30',
    access: 'hover:text-gray-700/30',
  },
  radiateColor: {
    green: '#4DCDA5',
    orange: '#E7A56D',
    blue: '#0D94FD',
    red: '#C1596C',
    'coral-red': '#ff6466',
    gray: '#BDCCD4',
    black: 'rgba(0, 0, 0, 0.5)',
    yellow: '#facc15',
    white: 'rgba(255, 255, 255, 0.5)',
    neutral: '#52c98e',
    basis: '#B9B3FB',
    kpler: '',
    'basis-connect': '#a78bfa',
    access: '#facc15',
  },
};

export const ghost: ColorScheme = {
  background: {
    green: 'hover:bg-green-100 active:bg-green-200',
    orange: 'hover:bg-orange-100 active:bg-orange-200',
    blue: 'hover:bg-blue-100 active:bg-blue-200',
    red: 'hover:bg-red-100 active:bg-red-200',
    'coral-red': 'hover:bg-coral-red-100 active:bg-coral-red-200',
    gray: 'hover:bg-gray-200 active:bg-gray-300',
    black: 'hover:bg-black/20 active:bg-black/30',
    yellow: 'hover:bg-yellow-100 active:bg-yellow-200',
    white: 'hover:bg-white/30 active:bg-white/40',
    neutral: 'hover:bg-neutral-100 active:bg-neutral-200',
    basis: 'hover:bg-basis-100 active:bg-basis-200',
    kpler: 'hover:bg-gray-200 active:bg-gray-300',
    'basis-connect': 'hover:bg-basis-connect-100 active:bg-basis-connect-200',
    access: 'hover:bg-access-100 active:bg-access-200',
  },
  backgroundDisabled: {
    green: '',
    orange: '',
    blue: '',
    red: '',
    'coral-red': '',
    gray: '',
    black: '',
    yellow: '',
    white: '',
    neutral: '',
    basis: '',
    kpler: '',
    'basis-connect': '',
    access: '',
  },
  border: {
    green: 'border-0',
    orange: 'border-0',
    blue: 'border-0',
    red: 'border-0',
    'coral-red': 'border-0',
    gray: 'border-0',
    black: 'border-0',
    yellow: 'border-0',
    white: 'border-0',
    neutral: 'border-0',
    basis: 'border-0',
    kpler: 'border-0',
    'basis-connect': 'border-0',
    access: 'border-0',
  },
  borderDisabled: {
    green: 'border-0',
    orange: 'border-0',
    blue: 'border-0',
    red: 'border-0',
    'coral-red': 'border-0',
    gray: 'border-0',
    black: 'border-0',
    yellow: 'border-0',
    white: 'border-0',
    neutral: 'border-0',
    basis: 'border-0',
    kpler: 'border-0',
    'basis-connect': 'border-0',
    access: 'border-0',
  },
  text: {
    green: 'text-green-500 hover:text-green-500 active:text-green-600',
    orange: 'text-orange-500 hover:text-orange-500 active:text-orange-600',
    blue: 'text-blue-500 hover:text-blue-500 active:text-blue-600',
    red: 'text-red-500 hover:text-red-500 active:text-red-600',
    'coral-red':
      'text-coral-red-500 hover:text-coral-red-500 active:text-coral-red-600',
    gray: 'text-gray-600 hover:text-gray-600 active:text-gray-700',
    black: 'text-black hover:text-black active:text-black',
    yellow: 'text-yellow-600 hover:text-yellow-600 active:text-yellow-700',
    white: 'text-white hover:text-white active:text-white',
    neutral: 'text-neutral-500 hover:text-neutral-600 active:text-neutral-600',
    basis: 'text-basis-500 hover:text-basis-500 active:text-basis-600',
    kpler: 'text-gray-700 hover:text-gray-100',
    'basis-connect':
      'text-basis-connect-500 hover:text-basis-connect-500 active:text-basis-connect-600',
    access: 'text-access-600 hover:text-access-600 active:text-access-700',
  },
  textDisabled: {
    green: 'text-green-500/60',
    orange: 'text-orange-500/60',
    blue: 'text-blue-500/60',
    red: 'text-red-500/60',
    'coral-red': 'text-coral-red-500/60',
    gray: 'text-gray-500/60',
    black: 'text-black/60',
    yellow: 'text-yellow-500/60',
    white: 'text-white/60',
    neutral: 'text-neutral-500/60',
    basis: 'text-basis-500/60',
    kpler: 'text-kpler-500/60',
    'basis-connect': 'text-basis-connect-500/60',
    access: 'text-access-500/60',
  },
  radiateColor: {
    green: '#4DCDA5',
    orange: '#E7A56D',
    blue: '#0D94FD',
    red: '#C1596C',
    'coral-red': '#ff6466',
    gray: '#BDCCD4',
    black: 'rgba(0, 0, 0, 0.5)',
    yellow: '#facc15',
    white: 'rgba(255, 255, 255, 0.5)',
    neutral: '#52c98e',
    basis: '#B9B3FB',
    kpler: '#ee5b11',
    'basis-connect': '#a78bfa',
    access: '#facc15',
  },
};

export const defaultColorScheme = {
  filled,
  outlined,
  text,
  unfilled,
  ghost,
};
