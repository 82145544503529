export const breakpoints = {
  hd: '668px',
  fhd: '1024px',
  qhd: '1280px',
  qhds: '1440px',
  uhd: '2160px',

  // Antd's grid breakpoints
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};
