<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps<{
  title: string;
  icon: string;
  items: { title: string; to: string }[];
  isLocked?: boolean;
}>();

const route = useRoute();

const isActive = computed(() => {
  return props.items.some((item) => item.to.startsWith(route.path));
});
</script>

<template>
  <div class="space-y-2">
    <div class="relative flex h-8 items-center gap-2 px-1 text-gray-400">
      <div
        class="rounded-full bg-gray-700/50 p-1 data-[active=true]:bg-gray-700"
        :data-active="isActive"
      >
        <SparkIcon :icon="isLocked ? 'lock' : icon" size="18" />
      </div>
      <div
        class="font-medium text-white data-[locked=true]:text-gray-600"
        :data-locked="isLocked"
      >
        {{ title }}
      </div>
      <div
        class="absolute bottom-[-8px] left-[16px] h-[12px] w-[2px] bg-gray-700/50 transition-colors data-[active=true]:bg-gray-700"
        :data-active="isActive"
      ></div>
    </div>
    <div
      class="ml-4 space-y-2 border-l-2 border-gray-700/50 data-[active=true]:border-gray-700"
      :data-active="isActive"
    >
      <RouterLink
        v-for="subItem in items"
        :key="subItem.title"
        v-slot="{ href, isExactActive, navigate }"
        :to="subItem.to"
        custom
      >
        <a
          :href="href"
          :aria-current="isExactActive ? 'page' : undefined"
          :data-locked="isLocked"
          class="flex h-8 items-center pl-5 font-medium leading-none text-gray-400 hover:bg-gray-700/50 hover:text-gray-100 aria-[current=page]:bg-gray-700 aria-[current=page]:text-white data-[locked=true]:cursor-not-allowed data-[locked=true]:text-gray-600 data-[locked=true]:hover:bg-transparent"
          @click="(e) => (isLocked ? e.preventDefault() : navigate(e))"
          >{{ subItem.title }}</a
        >
      </RouterLink>
    </div>
  </div>
</template>
