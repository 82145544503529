<script lang="ts" setup>
import { ClockCircleOutlined, InfoCircleFilled } from '@ant-design/icons-vue';
import { computed } from 'vue';

import { useAuthStore } from '@/core/stores/auth';

import AccountRegistrationStatus from './AccountRegistrationStatus.vue';

interface Props {
  bannerMode?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  bannerMode: false,
});

const verifiedAt = computed(() => {
  return useAuthStore().userAccount?.iceChatUsernameVerifiedAt;
});

const expandedView = computed(() => {
  return !props.bannerMode && !verifiedAt.value;
});
</script>
<template>
  <div v-if="expandedView">
    <div class="my-1 flex items-center gap-1 font-medium">
      <SparkIcon height="14" icon="ice" />
      <span>ICE Chat Account Registration Process:</span>
    </div>
    <div class="flex items-stretch space-x-1">
      <a-divider type="vertical" class="h-auto" />
      <AccountRegistrationStatus />
    </div>
  </div>
  <a-popover v-else>
    <template #title>
      <h6 class="h6 my-1 flex items-center gap-1">
        <SparkIcon height="14" icon="ice" />
        <span>ICE Account Registration Process</span>
      </h6>
    </template>
    <template #content> <AccountRegistrationStatus /> </template>

    <InfoCircleFilled
      v-if="verifiedAt"
      class="cursor-pointer text-sm text-green"
    />

    <SparkTrackerButton v-else look="text" class="ml-1" color="orange">
      <template #icon>
        <ClockCircleOutlined />
      </template>
      Registration Pending</SparkTrackerButton
    >
  </a-popover>
</template>
