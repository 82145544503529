<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    to: string;
    label: string;
    icon: string;
    iconSize?: string;
    color: 'green' | 'basis' | 'access';
  }>(),
  {
    iconSize: '24',
  },
);

const css = computed<string>(() => {
  if (props.color === 'green') {
    return 'bg-green-500 text-white hover:text-white hover:bg-green-400';
  } else if (props.color === 'basis') {
    return 'bg-basis-500 text-white hover:text-white hover:bg-basis-400';
  } else if (props.color === 'access') {
    return 'bg-access-500 text-blue-900 hover:text-blue-900 hover:bg-access-400';
  }
  return '';
});
</script>

<template>
  <RouterLink
    :to="to"
    class="flex w-full items-center gap-x-4 px-4 py-2 font-semibold tracking-wide"
    :class="css"
  >
    <div class="flex w-6 items-center justify-center">
      <SparkIcon :icon="icon" :size="iconSize" />
    </div>
    <div class="flex items-center gap-2">
      <div>{{ label }}</div>
    </div>
  </RouterLink>
</template>
