import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type { components } from '@/types';

import { maintenanceService } from '../services';

type Config = {
  enabled?: boolean;
  message?: string;
  level?: 'success' | 'info' | 'warning' | 'error';
};

const useMaintenanceStore = defineStore('maintenance', () => {
  // state
  const initialized = ref(false);
  const loading = ref(false);
  const config = ref<Config>({
    enabled: false,
    message: undefined,
    level: 'warning',
  });

  const maintenanceMessageDtos =
    ref<components['schemas']['MaintenanceMessagePublicDTO'][]>();

  // getters
  const messagesForEveryPage = computed(() => {
    const messages = maintenanceMessageDtos.value?.filter((msg) => !msg.path);

    return messages ?? [];
  });

  const messagesForSpecificPagesMap = computed(() => {
    const messages = maintenanceMessageDtos.value?.filter((msg) => !!msg.path);

    const map = new Map<
      string,
      components['schemas']['MaintenanceMessagePublicDTO'][]
    >();

    messages?.forEach((msg) => {
      if (msg.path) {
        const messagesForPath = map.get(msg.path) ?? [];
        messagesForPath.push(msg);
        map.set(msg.path, messagesForPath);
      }
    });

    return map;
  });

  async function loadStaticData() {
    try {
      // not added to StaticDataService.ts to reduce dependency
      const STATIC_BASE_URL = import.meta.env.VITE_APP_SP_STATIC_BASE_URL;

      const fileName =
        import.meta.env.VITE_APP_ENV === 'production'
          ? 'maintenance-message.txt'
          : 'maintenance-message-preprod.txt';

      const response = await axios.get<Config>(
        `${STATIC_BASE_URL}/configs/${fileName}`,
      );
      config.value = response.data;
    } catch (err) {
      console.error('maintenance-message.txt failed to load.', err);
    }
  }

  async function loadApiData() {
    const response = await maintenanceService.getMaintenanceData();
    const messages = response.data;

    maintenanceMessageDtos.value = messages;
  }
  async function loadData() {
    if (initialized.value || loading.value) {
      return;
    }
    loading.value = true;
    try {
      await Promise.all([loadStaticData(), loadApiData()]);
      initialized.value = true;
    } finally {
      loading.value = false;
    }
  }

  loadData();

  return {
    config,
    messagesForEveryPage,
    messagesForSpecificPagesMap,
    loadData,
  };
});

export { useMaintenanceStore };
