import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import { ApiConfig, ApiMeta } from './types';

// Generic api base to extend from
export default class ApiBase {
  $http!: AxiosInstance;
  meta: ApiMeta;
  config: ApiConfig;
  promiseStore: Map<string, Promise<any>>;

  constructor(config: ApiConfig, meta: ApiMeta) {
    this.config = config;
    this.meta = meta;
    this.promiseStore = new Map();
    this.setup();
  }

  async setup() {
    // Turn on the credentials flag to allow cookies to be sent
    this.$http = Axios.create({ ...this.config, withCredentials: true });

    this.$http.defaults.headers.common['X-Client-App-Name'] =
      this.meta.clientAppName;
    this.$http.defaults.headers.common['X-Client-App-Version'] =
      this.meta.appRelease;
    this.$http.defaults.headers.common['Content-Type'] =
      'application/vnd.api+json';
    this.$http.defaults.headers.common['X-Notify-On-Error'] = false;

    if (this.meta.browserUuid) {
      this.$http.defaults.headers.common['X-Client-Browser-Id'] =
        this.meta.browserUuid;
    }
    this.$http.interceptors.request.use(
      this.requestInterceptor.bind(this),
      this.requestErrorHandler.bind(this),
    );
    this.$http.interceptors.response.use(
      this.responseInterceptor.bind(this),
      this.responseErrorHandler.bind(this),
    );
  }

  requestInterceptor(
    config: InternalAxiosRequestConfig,
  ): InternalAxiosRequestConfig {
    return config;
  }

  responseInterceptor(response: AxiosResponse) {
    return response;
  }

  requestErrorHandler(error: AxiosError) {
    throw error;
  }

  responseErrorHandler(error: AxiosError) {
    throw error;
  }

  /**
   * optimized GET method to remove duplicate GET requests when called multiple times simultaneously
   * @param url the url for GET request
   * @returns response from the GET request
   */
  async dedupeGet<T>(url: string): Promise<AxiosResponse<T>> {
    if (this.promiseStore.has(url)) {
      const promise = this.promiseStore.get(url);
      return promise;
    }

    const promise = this.$http.get<T>(url);
    this.promiseStore.set(url, promise);
    promise.finally(() => {
      this.promiseStore.delete(url);
    });
    return promise;
  }
}
