import { notification } from 'ant-design-vue';
import type { Subscription } from 'rxjs';
import { filter } from 'rxjs';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

import { useWebSocketStore } from '@/core/stores/websocket';

export function useWsNotifications() {
  const { open } = useWebSocketStore();

  const jsonObservable = computed(() => useWebSocketStore().jsonObservable);

  const subscription = ref<Subscription>();

  watch(jsonObservable, (val) => {
    if (!val) return;
    subscription.value = jsonObservable.value
      ?.pipe(filter((obj) => obj.type === 'notification'))
      .subscribe((obj) => {
        notification.open({
          message: obj.payload.title,
          type: obj.payload.level,
        });
      });
  });

  // unsubscribe because we don't want to close the websocket connection for any other subscribers
  function unsubscribe() {
    subscription.value?.unsubscribe();
  }

  onMounted(() => {
    open();
  });

  onBeforeUnmount(() => {
    unsubscribe();
  });

  return {
    unsubscribe,
  };
}
