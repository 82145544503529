<template>
  <g>
    <path
      d="m5.31078,20.13631c-0.16108,-0.29579 -0.36447,-0.571 -0.60977,-0.81587c-0.25722,-0.25644 -0.54873,-0.46568 -0.86128,-0.63077c-0.3735,-0.19733 -0.07826,-0.62003 -0.07826,-0.62003c0.38227,-0.58059 0.73084,-1.16391 1.01904,-1.67208l-1.93481,-1.92985l-1.87833,0c-0.36124,0 -0.65407,-0.29257 -0.65407,-0.65326c0,-0.36052 0.29283,-0.65309 0.65407,-0.65309l2.1488,0c0.17391,0 0.34012,0.06878 0.46277,0.19113l7.07066,7.05101c0.12281,0.1226 0.19172,0.28893 0.19172,0.46204l0,2.15546c0,0.36085 -0.29292,0.65285 -0.65424,0.65285c-0.36165,0 -0.65457,-0.29191 -0.65457,-0.65285l0,-1.88471l-1.92711,-1.92125c-0.52016,0.30646 -1.12479,0.68219 -1.72388,1.09538c-0.00008,-0.00017 -0.34327,0.24371 -0.57076,-0.1741z"
    />
    <path
      d="m2.21838,19.60118c-0.58848,0 -1.14152,0.2285 -1.55792,0.6435c-0.41565,0.41459 -0.64447,0.96608 -0.64447,1.55238c0,0.58671 0.2289,1.13771 0.64447,1.55221c0.41631,0.41467 0.96943,0.64342 1.55792,0.64359c0.58815,0 1.14127,-0.229 1.55725,-0.64359c0.41598,-0.41459 0.64513,-0.96575 0.64513,-1.55221c0,-0.58638 -0.22923,-1.13787 -0.64513,-1.55238c-0.4159,-0.415 -0.9691,-0.6435 -1.55725,-0.6435z"
    />
    <path
      d="m8.37775,15.07218c-0.29002,0.28926 -0.76455,0.28926 -1.05457,0.00008l-0.7296,-0.72758c-0.29002,-0.28918 -0.29002,-0.76247 0,-1.05165l12.45463,-12.41977c0.29002,-0.28918 0.86136,-0.55612 1.26955,-0.59316l3.05273,-0.27711c0.40828,-0.03704 0.71188,0.26603 0.67478,0.6736l-0.2771,3.04151c-0.0371,0.40756 -0.30476,0.97757 -0.59478,1.26684l-12.45538,12.42126c-0.29002,0.28918 -0.76455,0.28918 -1.05457,0l-0.73059,-0.72857c-0.29002,-0.28918 -0.29002,-0.76247 0,-1.05173l10.42807,-10.40014c0.15354,-0.15277 0.1537,-0.40112 0.00025,-0.55405c-0.15304,-0.15319 -0.4019,-0.15319 -0.55561,-0.00017l-10.42782,10.40064z"
    />
  </g>
</template>
