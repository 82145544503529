<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  text?: string;
  preFillCrispMessage?: string;
  forceEmail?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  text: 'Contact us',
  preFillCrispMessage: '',
  forceEmail: false,
});

const hasChatBox = computed(() => {
  return window.CRISP_WEBSITE_ID && window.$crisp ? !props.forceEmail : false;
});

function openChatBox(event: Event) {
  event?.preventDefault();
  if (window.$crisp) {
    window.$crisp.push(['do', 'chat:open']);
    // Reset previously pre-filled message
    window.$crisp.push(['set', 'message:text', ['']]);
    if (props.preFillCrispMessage) {
      window.$crisp.push(['set', 'message:text', [props.preFillCrispMessage]]);
    }
  }
}
</script>
<template>
  <span class="contact-us">
    <a v-if="hasChatBox" href="#" class="underline" @click="openChatBox">
      <slot name="content"> {{ text }}</slot>
    </a>
    <a
      v-else
      href="mailto:info@sparkcommodities.com"
      target="_blank"
      class="underline"
      ><slot name="content">{{ text }}</slot></a
    >
  </span>
</template>
