<template>
  <g clip-path="url(#clip0)">
    <path
      fill="currentColor"
      d="M14.2972 13.2329L16.7655 10.7654L16.2345 10.2344L14.2027 12.2669L11.9527 10.7669L9 13.7196L7.7655 12.4844L7.2345 13.0154L9 14.7801L9.70275 15.4829L11.9527 13.9829L15 17.0301L16.7655 15.2654L16.2345 14.7344L15 15.9696L12.0472 13.0169L9.79725 14.5169L9.53025 14.2499L12.0472 11.7329L14.2972 13.2329Z"
    />
    <path fill="currentColor" d="M6.75 5.625H5.25V6.375H6.75V5.625Z" />
    <path fill="currentColor" d="M9 5.625H7.5V6.375H9V5.625Z" />
    <path fill="currentColor" d="M11.25 5.625H9.75V6.375H11.25V5.625Z" />
    <path
      fill="currentColor"
      d="M3.375 20.625H20.625V3.375H3.375V20.625ZM4.125 4.125H19.875V19.875H4.125V4.125Z"
    />
  </g>
</template>
