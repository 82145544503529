import ApiBase from '@/core/services/ApiBase';
import type { components, PriceReleaseApiResponse } from '@/types';

import type { PriceReleaseServiceInterface } from './PriceReleaseServiceInterface';

export default class PriceReleaseService
  extends ApiBase
  implements PriceReleaseServiceInterface
{
  async getPriceAssessments(
    priceAssessmentType: components['schemas']['PriceAssessmentEnum'],
  ) {
    const response = await this.dedupeGet<
      components['schemas']['DetailResponse_PriceAssessmentDTO_']
    >(`v2.0/price-assessments/${priceAssessmentType}`);

    return response?.data;
  }

  async getPriceRelease(
    priceAssessmentType: components['schemas']['PriceAssessmentEnum'],
    releaseDate: string,
  ) {
    if (releaseDate) {
      const response = await this.dedupeGet<PriceReleaseApiResponse>(
        `v2.0/price-releases/${priceAssessmentType}/${releaseDate}`,
      );

      return response?.data;
    }
  }

  async getLastPriceRelease(
    priceAssessmentType: components['schemas']['PriceAssessmentEnum'],
  ) {
    const response = await this.dedupeGet<PriceReleaseApiResponse>(
      `v2.0/price-releases/${priceAssessmentType}/last`,
    );

    return response?.data;
  }

  async getNextSubmissions(
    priceAssessmentType: components['schemas']['PriceAssessmentEnum'],
  ) {
    const [vertical, assessmentType] = priceAssessmentType.split('::');

    const response = await this.dedupeGet<
      components['schemas']['ListResponse_GenericNextSubmissionDTO_']
    >(
      `v1.0/submissions/next?vertical=${vertical}&assessmentType=${assessmentType}`,
    );

    return response?.data;
  }

  saveSubmissions(
    url: string,
    items: components['schemas']['UserPricePayload'][],
  ) {
    return this.$http
      .post(url, {
        items,
      })
      .then(() => {
        return { success: true };
      })
      .catch((err) => {
        return { success: false, error: err };
      });
  }

  downloadPriceReleaseInExcelFormat(
    priceAssessmentType: components['schemas']['PriceAssessmentEnum'],
    releaseId: string,
  ) {
    const [vertical, assessmentType] = priceAssessmentType.split('::');
    return this.$http
      .get(
        `v1.0/price-releases/${releaseId}/?vertical=${vertical}&assessmentType=${assessmentType}&format=excel`,
        {
          responseType: 'arraybuffer',
        },
      )
      .then((response: any) => {
        return {
          success: true,
          data: response?.data,
        };
      })
      .catch((err: any) => {
        return { success: false, error: err };
      });
  }
}
