<template>
  <g>
    <path fill="currentColor" d="M17 1V3L7 3V1L17 1Z" />
    <path
      fill="currentColor"
      d="M16.2427 8.44772L14.8285 9.86194L13 8.03347L13 15.9665L14.8285 14.138L16.2427 15.5522L12 19.7949L7.75742 15.5522L9.17163 14.138L11 15.9664L11 8.03357L9.17163 9.86194L7.75742 8.44772L12 4.20508L16.2427 8.44772Z"
    />
    <path fill="currentColor" d="M17 23V21H7V23H17Z" />
  </g>
</template>
