<template>
  <g>
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.50003 13.3531L4.20166 20.9232L11 17.3491L17.7984 20.9232L16.5 13.3531L22 7.99197L14.3992 6.88751L11 0L7.60084 6.88751L3.05176e-05 7.99197L5.50003 13.3531ZM14.4726 12.6944L15.2924 17.474L11 15.2174L6.70763 17.474L7.5274 12.6944L4.0548 9.30945L8.85381 8.61211L11 4.26347L13.1462 8.61211L17.9452 9.30945L14.4726 12.6944Z"
    />
  </g>
</template>
