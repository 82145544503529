<script setup lang="ts">
import { computed } from 'vue';

import ContactUs from '@/core/components/ContactUs.vue';

interface Props {
  hasNoData?: boolean;
  inError?: boolean;
}

withDefaults(defineProps<Props>(), {
  hasNoData: false,
  inError: false,
});

const browsingSessionId = computed(() => {
  return window.browsingSessionId;
});

function goHome() {
  window.location.href = '/';
}
</script>

<template>
  <div v-if="inError || hasNoData">
    <div
      class="spark-card-base m-auto max-w-screen-sm"
      :class="['border-t-4', inError ? 'error' : 'warning']"
    >
      <div class="my-2">
        <h1 v-if="inError" class="h2">Whoops, Something Went Wrong!</h1>
        <h1 v-else-if="hasNoData" class="h2">No Data Available</h1>
      </div>
      <div class="my-2">
        <p v-if="inError">
          <slot>
            We track these errors automatically, but if the problem persists
            feel free to <ContactUs text="contact us" />.
          </slot>
        </p>
        <p v-if="inError && browsingSessionId">
          <slot name="sessionTracker">
            To help our support, please provide this identifier:
            <b>{{ browsingSessionId }}</b
            >. Thanks!
          </slot>
        </p>
      </div>
      <div class="my-2">
        <SparkTrackerButton
          class="!px-0"
          look="text"
          icon="chevronright"
          @click="goHome"
          >Go home</SparkTrackerButton
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error {
  border-top-color: #db2e20;
}
.warning {
  border-top-color: #ff6a1a;
}
</style>
