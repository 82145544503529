<script setup lang="ts">
import { onMounted } from 'vue';

import { analyticsTrack } from '@/core/utils/usageAnalytics';
import ErrorCard from '@/shared/components/ErrorCard.vue';
import { AnalyticsEvent } from '@/types';

interface Props {
  err: any;
  vm: any;
  info: any;
}

const props = defineProps<Props>();

onMounted(() => {
  analyticsTrack(AnalyticsEvent.AppErrorCaptured, {
    err: props.err,
    vm: props.vm,
    info: props.info,
  });
});
</script>
<template>
  <div class="min-h-screen bg-shade-light p-4 hd:p-8">
    <div class="flex items-center justify-center">
      <ErrorCard :in-error="true" />
    </div>
  </div>
</template>
