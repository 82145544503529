import { cloneDeep } from 'lodash';

export function resetStore({ store }: { store: any }) {
  const initialState = cloneDeep(store.$state);

  store.$reset = () => {
    // store.$patch(cloneDeep(initialState));
    store.$state = cloneDeep(initialState);
  };
}
