import { SparkProductColor } from '#/types/core';

/**
 * The CSS variable values are generated by @tailwind-plugin/expose-colors tailwindcss plugin.
 */
export const ProductColorCssVarValues: Record<SparkProductColor, string> = {
  green: 'var(--tw-green-500)',
  basis: 'var(--tw-basis-500)',
  access: 'var(--tw-access-500)',
};
