<template>
  <g>
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 11C13.7909 11 12 9.20914 12 7C12 4.79086 13.7909 3 16 3C18.2091 3 20 4.79086 20 7C20 9.20914 18.2091 11 16 11ZM16 9C14.8954 9 14 8.10457 14 7C14 5.89543 14.8954 5 16 5C17.1046 5 18 5.89543 18 7C18 8.10457 17.1046 9 16 9Z"
    />
    <path
      fill="currentColor"
      d="M13 14C12.4477 14 12 14.4477 12 15V21H10V15C10 13.3431 11.3431 12 13 12H19C20.6569 12 22 13.3431 22 15V21H20V15C20 14.4477 19.5523 14 19 14H13Z"
    />
    <path fill="currentColor" d="M6 7H4V9H2V11H4V13H6V11H8V9H6V7Z" />
  </g>
</template>
