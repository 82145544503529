import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { getItem, setItem } from '@/core/misc/UserLocalStorage';
import type { components } from '@/types';

import { accessService } from '../services';

const useChangelogStore = defineStore('changelog', () => {
  const CHANGELOG_STORAGE_KEY = 'changelog-updated-at';
  const loading = ref(false);
  const lastUpdatedAt = ref<string | null>(null);
  const premiumAccessRequired = ref(false);
  const hasUpdate = computed(() => {
    return () => {
      let status = false;
      const lastVisitedDate = getItem(CHANGELOG_STORAGE_KEY);
      if (
        lastUpdatedAt.value &&
        dayjs(lastVisitedDate).isBefore(dayjs(lastUpdatedAt.value))
      ) {
        status = true;
      }
      return status;
    };
  });

  const changelogs = ref<
    components['schemas']['SparkRChangeLogEntryDTOAttr'][]
  >([]);
  async function loadData() {
    premiumAccessRequired.value = false;
    loading.value = true;
    try {
      const response = await accessService.getSparkRTerminalsChangelog();
      changelogs.value = response.changes;
      lastUpdatedAt.value = response.lastUpdatedAt;
    } catch (err: any) {
      if (err.response?.status === 402) {
        premiumAccessRequired.value = true;
      }
    } finally {
      loading.value = false;
    }
  }

  function markPageAsVisited() {
    setItem(CHANGELOG_STORAGE_KEY, new Date().toISOString());
  }

  return {
    loading,
    lastUpdatedAt,
    premiumAccessRequired,
    hasUpdate,
    loadData,
    changelogs,
    markPageAsVisited,
  };
});

export { useChangelogStore };
