<script setup lang="ts">
import { CheckCircleFilled, QuestionCircleFilled } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import type { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { computed, onUnmounted, ref, watch } from 'vue';

import ErrorMessage from '@/core/components/ErrorMessage.vue';
import RedDotTitle from '@/core/components/RedDotTitle.vue';
import { userAccountService } from '@/core/services';
import { useAuthStore } from '@/core/stores/auth';
import { useMarketingStore } from '@/core/stores/marketing';
import SparkPopup from '@/shared/components/SparkPopup.vue';

import EditWhatsapp from './components/EditWhatsapp.vue';
import VerifyWhatsapp from './components/VerifyWhatsapp.vue';

const MESSAGE_DELETE_SUCCESS =
  'Your WhatsApp number has been successfully deleted. You should receive a message from Spark Commodities soon.';
const ERROR_MESSAGE_UPDATE =
  'Error occurred when updating your WhatsApp number';
const ERROR_MESSAGE_REQUEST_VERIFICATION =
  'Error occurred when requesting verification code for your WhatsApp number';

const ERROR_MESSAGE_DELETE =
  'Error occurred when deleting your WhatsApp number';

interface Props {
  bannerMode?: boolean;
}

withDefaults(defineProps<Props>(), {
  bannerMode: false,
});

const marketingStore = useMarketingStore();
const authStore = useAuthStore();

const { loadFeedbacks } = marketingStore;
const { updateUserAccount } = authStore;

const isVerifying = ref(false);
const isResending = ref(false);
const whatsappNumberInternal = ref('');
const verifiedAtInternal = ref('');
const resendEnabledTime: any = ref(null);
const nextResendInSeconds = ref(0);
let timerId = -1;

const verifyModalVisible = ref(false);

const errorMsg = ref('');
const defaultCountry = ref('SG');
const verificationCode = ref('');
const isSubmitting = ref(false);
const isSubmitted = ref(false);
const userAccount = computed(() => {
  return authStore.userAccount;
});
const whatsappNumber = computed(() => {
  return userAccount.value?.whatsappNumber ?? '';
});
const phoneNumber = computed(() => {
  return userAccount.value?.phoneNumber;
});
const verifiedAt = computed(() => {
  return (
    (whatsappNumber.value && userAccount.value?.whatsappNumberVerifiedAt) ?? ''
  );
});

function showVerifyWhatsappModal() {
  verificationCode.value = '';
  verifyModalVisible.value = true;
}

function checkNextResendInSeconds() {
  if (resendEnabledTime.value) {
    const now = dayjs();
    const diffInSeconds = resendEnabledTime.value.diff(now, 'seconds');
    if (diffInSeconds <= 0) {
      clearInterval(timerId);
      resendEnabledTime.value = null;
      nextResendInSeconds.value = 0;
    } else {
      nextResendInSeconds.value = diffInSeconds;
    }
  }
}

function disableImmediateResend() {
  resendEnabledTime.value = dayjs().add(1, 'minutes');
  nextResendInSeconds.value = 60;
  timerId = window.setInterval(() => {
    checkNextResendInSeconds();
  });
}

async function updateWhatsappNumber({
  whatsappNumber,
  currentPassword,
}: {
  whatsappNumber: string | null;
  currentPassword: string;
}) {
  isSubmitting.value = true;
  isSubmitted.value = false;
  errorMsg.value = '';
  const response = await updateUserAccount({
    whatsappNumber,
    currentPassword,
  });
  if (response?.success) {
    if (whatsappNumber) {
      verifiedAtInternal.value = '';
      showVerifyWhatsappModal();
      disableImmediateResend();
    } else {
      notification.success({
        message: 'Success',
        description: MESSAGE_DELETE_SUCCESS,
      });
      loadFeedbacks();
    }
    isSubmitted.value = true;
  } else {
    errorMsg.value =
      response?.error?.message ||
      (whatsappNumber ? ERROR_MESSAGE_UPDATE : ERROR_MESSAGE_DELETE);
  }
  isSubmitting.value = false;
}

function requestWhatsappNumberVerification() {
  isResending.value = true;
  errorMsg.value = '';
  userAccountService
    .requestWhatsappNumberVerification()
    .then(() => {
      isResending.value = false;
      showVerifyWhatsappModal();
      disableImmediateResend();
    })
    .catch((error: AxiosError<{ error: { message: string } }>) => {
      isResending.value = false;
      errorMsg.value =
        error?.response?.data?.error?.message ||
        ERROR_MESSAGE_REQUEST_VERIFICATION;
    });
}

onUnmounted(() => {
  if (timerId) {
    clearInterval(timerId);
  }
});

function onPopupClose() {
  isSubmitted.value = false;
  errorMsg.value = '';
}

watch(
  whatsappNumber,
  (number: string) => {
    whatsappNumberInternal.value = number;
  },
  { immediate: true },
);

watch(
  verifiedAt,
  (verifiedAt: string) => {
    verifiedAtInternal.value = verifiedAt;
  },
  { immediate: true },
);
</script>

<template>
  <div>
    <section class="flex items-center justify-between">
      <div v-if="bannerMode" class="flex flex-1 items-center space-x-2">
        <img
          src="@/assets/img/more/whatsapp-logo.png"
          height="16"
          width="17"
          alt="WhatsApp logo"
        />
        <RedDotTitle feature-id="alert-centre" component-id="whatsapp">
          <span class="font-display font-bold">WhatsApp Number</span>
        </RedDotTitle>
      </div>
      <!-- If whatsapp number is added, verified or not -->
      <div
        class="flex items-center space-x-4"
        :class="{ 'flex-1 justify-between': !bannerMode }"
      >
        <div v-if="whatsappNumberInternal" class="flex items-center space-x-4">
          <div class="flex items-center space-x-2">
            <!-- Readonly number -->
            <!-- <img
              v-if="!bannerMode && whatsappNumberInternal"
              src="@/assets/img/more/whatsapp-logo.png"
              height="16"
              width="17"
              alt="WhatsApp logo"
            /> -->
            <vue-tel-input
              v-model="whatsappNumberInternal"
              mode="international"
              :default-country="defaultCountry"
              disabled
              class="hidden"
            ></vue-tel-input>
            <span>{{ whatsappNumberInternal }}</span>

            <!-- Verified state -->

            <CheckCircleFilled
              v-if="verifiedAtInternal"
              class="align-middle text-green"
              title="Verified"
            />

            <!-- Not verified state -->
            <a-popover
              v-else
              placement="right"
              @visible-change="(visible: boolean) => !visible && onPopupClose()"
            >
              <template #title>Whatsapp Verification Pending</template>
              <template #content>
                <p>
                  You may request a new verification code to verify your
                  whatsapp number.
                </p>
                <div class="my-2">
                  <SparkTrackerButton
                    :loading="isResending"
                    @click="requestWhatsappNumberVerification"
                    >Resend verification code</SparkTrackerButton
                  >
                </div>
                <ErrorMessage :message="errorMsg"></ErrorMessage>
              </template>
              <QuestionCircleFilled />
            </a-popover>
          </div>
          <!-- <div class="hidden md:block w-px h-5 bg-gray-400"></div> -->
        </div>
        <div class="flex space-x-4">
          <!-- Edit -->
          <EditWhatsapp
            :phone-number="phoneNumber"
            :whatsapp-number="whatsappNumberInternal"
            :verified-at="verifiedAtInternal"
            :is-submitting="isSubmitting"
            :is-submitted="isSubmitted"
            :error="errorMsg"
            @save="updateWhatsappNumber"
            @closed="onPopupClose"
          />
        </div>
      </div>
    </section>
    <!-- Verify -->
    <SparkPopup
      popup-title="Verify WhatsApp Number"
      :show="verifyModalVisible"
      :mask-closable="!isResending || !isVerifying"
      :closable="!isResending || !isVerifying"
      width="450px"
      @popup:closed="() => (verifyModalVisible = false)"
    >
      <VerifyWhatsapp
        v-model:isVerifying="isVerifying"
        @success="verifyModalVisible = false"
      />
    </SparkPopup>
  </div>
</template>
