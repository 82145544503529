<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Markdown from 'vue3-markdown-it';

const props = defineProps<{
  source: string;
}>();

const router = useRouter();

const anchorsReady = ref(false);

// this is needed because no way to know Markdown is "ready"
async function startAnchorSetup() {
  const CUTOFF = 10;
  for (let i = 0; i < CUTOFF; i++) {
    const success = stopAnchorLinkRedirects();

    // exit if success
    if (success) {
      anchorsReady.value = true;
      break;
    }

    // wait 200ms before retrying
    await new Promise((resolve) => setTimeout(resolve, 200));
  }
}

function stopAnchorLinkRedirects(): boolean {
  console.debug('replacing anchors');
  const linkElements = document.querySelectorAll('#markdown a');
  if (!linkElements.length) {
    return false;
  }
  linkElements.forEach((a) => {
    const href = a.getAttribute('href');
    if (!href) {
      return;
    }

    if (href.startsWith('http')) {
      // treat absolute urls as external ones
      a.setAttribute('target', '_blank');
      a.setAttribute('rel', 'noopener noreferrer');
    } else {
      // if link is internal, use router
      a.addEventListener('click', (e) => {
        e.preventDefault();
        router.push(href);
      });
    }
  });
  return true;
}

watch(
  () => props.source,
  (newSource) => {
    if (newSource) {
      startAnchorSetup();
    }
  },
);

onMounted(() => {
  startAnchorSetup();
});
</script>
<template>
  <Markdown
    id="markdown"
    :source="source"
    :html="true"
    :linkify="true"
  ></Markdown>
</template>
