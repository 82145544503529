<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 5V7H20C21.1 7 22 7.9 22 9V19C22 20.1 21.1 21 20 21H4C2.9 21 2 20.1 2 19V5C2 3.9 2.9 3 4 3H10C11.1 3 12 3.9 12 5ZM4 19H6V17H4V19ZM6 15H4V13H6V15ZM4 11H6V9H4V11ZM6 7H4V5H6V7ZM8 19H10V17H8V19ZM10 15H8V13H10V15ZM8 11H10V9H8V11ZM10 7H8V5H10V7ZM12 19H19C19.55 19 20 18.55 20 18V10C20 9.45 19.55 9 19 9H12V11H14V13H12V15H14V17H12V19ZM18 11H16V13H18V11ZM16 15H18V17H16V15Z"
    />
  </svg>
</template>
