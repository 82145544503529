<script setup lang="ts">
import { provide, ref } from 'vue';

import GuideButton from '@/core/components/UITour/GuideButton.vue';
import { UITour } from '#/components/uiTour/';
import type { SparkProductColor } from '#/types/core';

import { SPARK_UI_TOUR_CONTEXT } from './share';

const props = withDefaults(
  defineProps<{
    color?: SparkProductColor;
    noTriggerButton?: boolean;
  }>(),
  {
    color: 'green',
    noTriggerButton: false,
  },
);

const uiTour = ref<InstanceType<typeof UITour>>();

function startTour() {
  uiTour.value?.start();
}

defineExpose({
  startTour,
});

provide(SPARK_UI_TOUR_CONTEXT, {
  color: props.color,
});
</script>

<template>
  <GuideButton v-if="!noTriggerButton" :color="color" @click="startTour" />
  <UITour ref="uiTour" teleport-to="#ui-tour">
    <slot></slot>

    <template #spinner>
      <div class="flex h-full items-center justify-center">
        <SparkIcon
          icon="spinner"
          :spin="true"
          :class="{
            'text-green-500': color === 'green',
            'text-basis-500': color === 'basis',
            'text-access-500': color === 'access',
          }"
        />
      </div>
    </template>
  </UITour>
</template>
