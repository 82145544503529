<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_19154_271112)">
      <g filter="url(#filter0_dd_19154_271112)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.3565 3.20261C12.8813 4.75203 12.9415 7.20393 14.4909 8.67908L17.1619 11.3501L19.9674 8.54463C21.4426 6.99522 21.3824 4.54332 19.833 3.06816C18.2835 1.593 15.8316 1.6532 14.3565 3.20261ZM14.7576 5.04999L15.2385 4.58249L17.1619 6.45251L19.0854 4.5825L19.5663 5.05L17.1619 7.38752L14.7576 5.04999Z"
          fill="#4F41F4"
        />
      </g>
      <g filter="url(#filter1_dd_19154_271112)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.25065 4.64342C2.52347 6.45753 2.59395 9.32831 4.40807 11.0555L7.53538 14.1828L10.8201 10.8981C12.5473 9.08395 12.4768 6.21317 10.6627 4.486C8.8486 2.75883 5.97782 2.82931 4.25065 4.64342ZM4.72052 6.80613L5.28353 6.25876L7.53559 8.44825L9.78764 6.25877L10.3507 6.80614L7.53558 9.543L4.72052 6.80613Z"
          fill="#ED7336"
        />
      </g>
      <g filter="url(#filter2_dd_19154_271112)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.04659 11.1596C5.90279 13.4113 5.99027 16.9746 8.24199 19.1184L12.1237 23.0001L16.2008 18.923C18.3446 16.6713 18.2571 13.108 16.0054 10.9642C13.7537 8.82039 10.1904 8.90787 8.04659 11.1596ZM8.62932 15.9365L9.32814 16.6159L12.1234 13.8982L14.9187 16.6159L15.6176 15.9365L12.1234 12.5394L8.62932 15.9365Z"
          fill="#21C08E"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_19154_271112"
        x="-1.71167"
        y="-4"
        width="37.7473"
        height="40.3501"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_19154_271112"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="7.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_19154_271112"
          result="effect2_dropShadow_19154_271112"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_19154_271112"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dd_19154_271112"
        x="-12"
        y="-2.76465"
        width="39.0708"
        height="41.9475"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_19154_271112"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="7.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_19154_271112"
          result="effect2_dropShadow_19154_271112"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_19154_271112"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_dd_19154_271112"
        x="-8.50574"
        y="3.41187"
        width="41.2589"
        height="44.5881"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_19154_271112"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="7.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_19154_271112"
          result="effect2_dropShadow_19154_271112"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_19154_271112"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_19154_271112">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
