<template>
  <g>
    <path fill="currentColor" d="M17 9H7V7H17V9Z" />
    <path fill="currentColor" d="M7 13H17V11H7V13Z" />
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 18V2H22V18H16V22H14C11.7909 22 10 20.2091 10 18H2ZM12 16V18C12 19.1046 12.8954 20 14 20V16H20V4H4V16H12Z"
    />
  </g>
</template>
