<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
  keepAlive?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  keepAlive: true,
});

const route = useRoute();

const keepAliveActive = computed(() => route.meta.keepAlive ?? props.keepAlive);
</script>
<template>
  <router-view v-slot="{ Component }">
    <KeepAlive v-if="keepAliveActive">
      <component :is="Component" />
    </KeepAlive>
    <component :is="Component" v-else />
  </router-view>
</template>
