<script lang="ts" setup>
import { computed } from 'vue';

import IceChatAccountRegistrationSteps from '@/core/components/IceChatAccount/components/AccountRegistrationSteps.vue';
import IceChatAccount from '@/core/components/IceChatAccount/index.vue';
import { useAuthStore } from '@/core/stores/auth';

const emit = defineEmits(['next']);
const authStore = useAuthStore();

const hasIceAccountUsername = computed(() => {
  return authStore.userAccount?.iceChatUsername;
});
</script>
<template>
  <!-- Title -->
  <div class="flex flex-col justify-center space-y-4 text-center">
    <SparkIcon icon="ice" size="72" class="mx-auto" />
    <h1 class="h2-sm">2/3 ICE Chat Price notifications</h1>
  </div>
  <!-- content -->
  <div class="space-y-4 text-center">
    <p class="text-left">
      Enter your ICE Chat Account to receive price notifications on ICE Chat
    </p>
    <div
      class="flex flex-col justify-center gap-2 rounded bg-shade-light px-4 py-4 text-left shadow"
    >
      <div>
        <h6 class="h6 flex items-center gap-1">
          <span>Manage ICE Chat Account</span>
        </h6>
        <IceChatAccountRegistrationSteps />
      </div>
      <div class="my-6 text-left">
        <div class="font-medium">Account Handle</div>
        <IceChatAccount />
      </div>
    </div>
  </div>
  <!-- start action -->
  <div class="flex flex-col items-center justify-center gap-4 pt-2">
    <SparkTrackerButton
      v-if="hasIceAccountUsername"
      size="lg"
      @click="emit('next')"
      >Continue</SparkTrackerButton
    >
    <SparkTrackerButton v-else look="text" @click="emit('next')"
      >Skip for now</SparkTrackerButton
    >
  </div>
</template>
