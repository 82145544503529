<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import {
  GuideLinkText,
  InlineButton,
  SparkUITour,
  SparkUITourStop,
  SparkUITourStopLast,
  SparkUITourStopWelcome,
} from '@/core/components/UITour';
import { useAppStore } from '@/core/stores/app';
import { useMarketingStore } from '@/core/stores/marketing';
import { useMenuStore } from '@/core/stores/menu';
import { UI_TOUR_IDS as SHARED_UI_TOUR_IDS } from '@/shared/misc/constants';
import {
  showErrorNotification,
  showSuccessNotification,
} from '@/shared/misc/notification';
import type { Nullable } from '#/types/core';

import {
  CARGO_TITLES,
  CONTRACTS,
  FINANCIAL,
  PHYSICAL,
  TOOLS,
} from '../the-nav/constants';
import { CARGO_UI_TOUR_IDS } from './constants';

const router = useRouter();
const appStore = useAppStore();
const menuStore = useMenuStore();

const { cargoMenu } = storeToRefs(menuStore);

const uiTour = ref<InstanceType<typeof SparkUITour>>();

const marketingStore = useMarketingStore();
const { useFeedback, loadFeedbacks } = marketingStore;
const { sendFeedback, alreadyAnswered } = useFeedback(
  'sparkclose-register-interest',
);
const DEFAULT_SUCCESS_MESSAGE = 'We’ve received your interest.';
const DEFAULT_ERROR_MESSAGE =
  'Sorry we cannot process your request, please contact us.';
const isRegistering = ref<boolean>();
const isRegistrationSuccessful = ref<Nullable<boolean>>(null);

async function goToRoute(routeUrl: string, done: () => void) {
  await router.push(routeUrl);
  done();
}

async function registerSparkCloseInterest() {
  try {
    isRegistering.value = true;
    const response = await sendFeedback('acknowledge', {
      msg: `Register interest for SparkClose`,
      action: 'acknowledge',
    });
    if (response?.status === 204) {
      showSuccessNotification(DEFAULT_SUCCESS_MESSAGE);
      await loadFeedbacks({ forceRefresh: true });
      isRegistrationSuccessful.value = true;
    }
  } catch (err) {
    const message =
      (err as { response?: { data?: { message: string } } })?.response?.data
        ?.message ?? DEFAULT_ERROR_MESSAGE;
    console.error(err);
    showErrorNotification(message, err);
    isRegistrationSuccessful.value = false;
  } finally {
    isRegistering.value = false;
  }
}

function hasAccessTo(title: string, subtitle: string) {
  const section = cargoMenu.value.find((item) => item.title === title);
  if (!section) return false;

  const found = section.items.find((item) => item.title === subtitle);
  if (!found) return false;

  return !found.isLocked;
}

appStore.listenEvent('cargoUITour', () => {
  uiTour.value?.startTour();
});
</script>

<template>
  <SparkUITour :no-trigger-button="true" ref="uiTour" color="basis">
    <SparkUITourStopWelcome title="Spark Cargo">
      <p>
        Spark's Cargo product covers the European DES LNG market, providing
        $/MMBtu assessments of cargos delivered to NW and SW Europe, as well as
        additional analytical tools to help you analyse the global LNG market.
      </p>

      <p>
        Spark's platform covers the entire LNG value chain. If you're looking
        for more information on global LNG freight costs, visit our
        <InlineButton color="green" is-external-link to="/freight">
          Freight product </InlineButton
        >. Alternatively, explore regas costs across major European terminals,
        as well as regas slot availability, with our
        <InlineButton color="access" is-external-link to="/access"
          >Access product</InlineButton
        >.
      </p>
    </SparkUITourStopWelcome>

    <SparkUITourStop
      v-if="hasAccessTo(CONTRACTS, PHYSICAL)"
      title="Physical - SparkNWE/SWE"
      placement="right-start"
      :target-element-id="CARGO_UI_TOUR_IDS.CARGO_PHYSICAL_TITLE"
      @before-mount-action-async="goToRoute('/cargo/sparknwe', $event)"
    >
      <p>
        The Physical SparkNWE/SWE pages show Spark's LNG Cargo physical front
        month and forward curve price assessment for both NW-Europe (NWE) and
        SW-Europe (SWE). These are assessed as a basis to the TTF to align with
        common market practice, but is also shown as an outright DES LNG price.
      </p>

      <p>
        These are our benchmark assessments and are assessed daily by leading
        market participants, giving you an accurate picture of the current LNG
        market in Europe.
      </p>

      <p>
        Looking to deliver into Europe? View our
        <InlineButton
          color="access"
          is-external-link
          to="/access/des-hub-netbacks/terminals"
        >
          Spark Access DES Hub Netbacks page
        </InlineButton>
        to rank European terminals by competitiveness
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(CONTRACTS, FINANCIAL)"
      title="Financial - SparkNWE/SWE"
      placement="right-start"
      :target-element-id="CARGO_UI_TOUR_IDS.CARGO_FINANCIAL_TITLE"
      @before-mount-action-async="goToRoute('/cargo/ffa/sparknwe-fin', $event)"
    >
      <p>
        The Financial SparkNWE/SWE pages show Spark's LNG Cargo financial
        forward curve price assessment for NWE and SWE. Gain a 2 year forward
        view of the DES LNG market in Europe, and track how these global forward
        rates evolve daily. These are also ICE-listed tradeable products,
        allowing you to hedge your cargo positions.
      </p>

      <p>
        Interested in a forward view of the LNG Freight market and hedging your
        freight position? View our
        <InlineButton
          color="green"
          is-external-link
          to="/freight/ffa/spark30ffa"
          >Spark Freight FFA page</InlineButton
        >.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(CONTRACTS, CARGO_TITLES.SPARKCLOSE)"
      title="SparkClose (Sandbox)"
      placement="right-start"
      :target-element-id="CARGO_UI_TOUR_IDS.SPARK_CLOSE_TITLE"
      @before-mount-action-async="
        goToRoute('/cargo/sandbox/spark-close', $event)
      "
    >
      <p>
        SparkClose is designed to provide a clear and intuitive interface to
        allow market participants to input cargo bids and offers and enable
        cargo transactions.
      </p>

      <p>
        SparkClose is in Sandbox stage, meaning the tool is currently only
        available for functionality testing.
      </p>

      <p v-if="!alreadyAnswered">
        If SparkClose is a platform you would be interested in, please register
        your interest
        <InlineButton
          color="basis"
          @click="
            registerSparkCloseInterest || isRegistrationSuccessful === true
          "
          :loading="isRegistering"
          >here</InlineButton
        >.
      </p>
      <p v-else class="italic text-gray-500">
        Thank you for registering your interest in SparkClose.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, CARGO_TITLES.HISTORICAL_DATA)"
      title="Historical Data"
      placement="right-start"
      :target-element-id="CARGO_UI_TOUR_IDS.HISTORICAL_DATA_TITLE"
      @before-mount-action-async="goToRoute('/cargo/historical-data', $event)"
    >
      <p>View the Historical Data for any of our assessed LNG prices.</p>

      <p>
        Track how DES LNG prices in Europe are shifting, and monitor how the
        discount to the TTF varies as regas capacity in Europe
        increases/decreases in demand.
      </p>

      <p>
        View European regas capacity, one of the main drivers behind varying TTF
        discount levels, using our
        <InlineButton color="access" is-external-link to="/access"
          >Access product</InlineButton
        >.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, CARGO_TITLES.SETTLEMENT_TRACKER)"
      title="Settlement Tracker"
      placement="right-start"
      :target-element-id="CARGO_UI_TOUR_IDS.SETTLEMENT_TRACKER_TITLE"
      @before-mount-action-async="goToRoute('/cargo/settlement', $event)"
    >
      <p>
        Our Settlement Tracker tool is designed to provide clarity around
        Spark's contracts, and to help market participants incorporate Spark
        benchmark rates into contracts.
      </p>

      <p>
        Track how your contract month of interest is priced in over the month
        using the Settlement chart, and model min/max gain and loss scenarios.
        Read our Settlement Chart and Financial SparkNWE/SWE page methodologies
        to understand how our financial contracts settle against our physical
        assessments.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, CARGO_TITLES.NETBACKS)"
      title="Netbacks"
      placement="right-start"
      :target-element-id="CARGO_UI_TOUR_IDS.NETBACKS_TITLE"
      @before-mount-action-async="goToRoute('/cargo/netbacks', $event)"
    >
      <p>
        Spark's Netbacks tool combines our DES LNG pricing assessments with our
        assessed freight costs, allowing you to compare delivered LNG cargo
        valuations to NE-Asia and NW-Europe from an FOB port of your choice.
      </p>

      <p>
        Use our Netbacks tool to view pricing signals indicating vessel flows
        over the next 12 months.
      </p>

      <p>
        Input slider allowing you to adjust the percentage of freight to include
        in your netbacks calculations.
      </p>

      <p>
        Looking to deliver to Europe over the next year? View our
        <InlineButton
          color="access"
          is-external-link
          to="/access/regas/forwards"
        >
          Access Regas Forward
        </InlineButton>
        curves to compare forward regas costs across major European regas
        terminals.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, CARGO_TITLES.NETBACKS)"
      title="Arb Breakeven"
      placement="right-start"
      :target-element-id="CARGO_UI_TOUR_IDS.ARB_BREAKEVEN_TITLE"
      @before-mount-action-async="
        goToRoute('/cargo/netbacks/arb-breakeven', $event)
      "
    >
      <p>
        Spark's Arb Breakevens tool displays NEA-NWE arb levels over the next 12
        months, along with breakeven rates for the Freight and JKM-TTF spread
        components of the arb. These breakeven rates show the level that Freight
        rates/JKM-TTF spread prices would have to move to for the arb to
        open/close out.
      </p>

      <p>
        This tool enables you to assess the stability of the arb over the coming
        year, and track how those arb levels evolve daily.
      </p>

      <p>
        Interested in timespreads instead? Our
        <InlineButton
          color="green"
          is-external-link
          to="/freight/floating-storage/"
          >Freight Floating Storage tool</InlineButton
        >
        tracks Freight and LNG timespreads to show floating storage
        opportunities in both the NWE and NEA regions.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, CARGO_TITLES.NETFORWARDS)"
      title="Netforwards"
      placement="right-start"
      :target-element-id="CARGO_UI_TOUR_IDS.DES_NETFORWARDS_TITLE"
      @before-mount-action-async="goToRoute('/cargo/netforwards', $event)"
    >
      <p>
        Spark's Netforwards tool values the delivered LNG price of cargos from a
        selection of FOB ports, for your DES location of choice.
      </p>

      <p>
        Understand the theoretical price tender range for a DES location, and
        rank the competitiveness of your selected range of FOB ports.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      title="Data Integrations"
      placement="right-start"
      :target-element-id="SHARED_UI_TOUR_IDS.DATA_INTEGRATIONS_TITLE"
      @before-mount-action-async="goToRoute('/data-integrations', $event)"
    >
      <p>
        Incorporate Spark's data directly into your workflow with Data
        Integrations. Pull our data through our Excel Add-in, Power BI plug-in
        or API.
      </p>

      <p>
        To find out more on each of these services, explore the relevant section
        within Data Integrations. If you need help setting up the API, or would
        like to further discuss anything API or platform related, please email
        <InlineButton is-external-link to="mailto:info@sparkcommodities.com"
          >info@sparkcommodities.com</InlineButton
        >
        or use our Chatbox located in the bottom-right of the screen.
      </p>
    </SparkUITourStop>

    <SparkUITourStopLast
      title="Thanks for taking the tour!"
      placement="bottom"
      :target-element-id="CARGO_UI_TOUR_IDS.CARGO_GUIDE_BUTTON"
      @before-mount-action-async="goToRoute('/dashboard', $event)"
    >
      <p>
        We hope you have a sufficient overview of what our Cargo product has to
        offer. You may further explore on each of the pages by clicking on the
        <GuideLinkText class="text-basis-500" /> link at the top of their
        respective pages.
      </p>
      <p>
        You may also access this guide at any time by clicking on this
        <GuideLinkText class="text-basis-500" />
        link in the Dashboard page.
      </p>
    </SparkUITourStopLast>
  </SparkUITour>
</template>
