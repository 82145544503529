<script setup lang="ts">
import type { Placement } from '@floating-ui/core';
import { inject } from 'vue';

import { UITourStop } from '#/components/uiTour/';

import { SPARK_UI_TOUR_CONTEXT } from './share';
import SparkUITourStopArrow from './SparkUITourStopArrow.vue';
import SparkUITourStopDialog from './SparkUITourStopDialog.vue';

withDefaults(
  defineProps<{
    title: string;
    targetElementId: string;
    placement: Placement;
    spotlightPadding?: number;
    spotlightCornerRadius?: number;
    /**
     * Sets where the scrollIntoView() method is called on the target element.
     * Used when the viewport is shorter than the target element.
     * Default is 'center'
     */
    scrollLogicalPosition?: ScrollLogicalPosition;
    /**
     * Sets some margin from the edge of the viewport for the target element
     * when the scrollIntoView() method is called.
     */
    scrollMargin?: string;
  }>(),
  {
    spotlightPadding: 10,
    spotlightCornerRadius: 10,
    scrollLogicalPosition: 'center',
    scrollMargin: undefined,
  },
);

const context = inject(SPARK_UI_TOUR_CONTEXT);
if (!context) {
  throw new Error('SparkUITourStop must be used inside SparkUITour');
}

const { color } = context;
</script>

<template>
  <UITourStop
    placement="bottom"
    :target-element-id="targetElementId"
    :spotlight-padding="spotlightPadding"
    :spotlight-corner-radius="spotlightCornerRadius"
    floating-element-css="drop-shadow-lg"
  >
    <template #default="{ prev, exit, currentIndex, totalStops }">
      <SparkUITourStopDialog
        :title="title"
        :total-stops="totalStops - 1"
        :current-index="currentIndex"
        @prev="prev"
      >
        <slot />
        <template #actions>
          <SparkTrackerButton :color="color" @click="exit">
            Complete Guide
          </SparkTrackerButton>
        </template>
      </SparkUITourStopDialog>
    </template>

    <template
      #floating-arrow="{
        setElement,
        arrowPositionStyles,
        placement: placementResult,
      }"
    >
      <SparkUITourStopArrow
        :arrow-position-styles="arrowPositionStyles"
        :placement-result="placementResult"
        :color="color"
        @arrow-element="setElement"
      />
    </template>
  </UITourStop>
</template>
