import ApiBase from '@/core/services/ApiBase';
import type { components, PatchAccountPayload } from '@/types';

export default class UserAccountService extends ApiBase {
  async getMyAccountInfo(): Promise<{
    success: boolean;
    data?: components['schemas']['AccountResponse'];
    error?: unknown;
  }> {
    try {
      const response =
        await this.$http.get<
          components['schemas']['DetailResponse_AccountResponse_']
        >(`v1.0/accounts/me`);

      return { success: true, data: response.data?.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  registerUser(user: components['schemas']['CreateAccountPayload']) {
    return this.$http.post<components['schemas']['EmptyResponse']>(
      'v1.0/accounts/',
      user,
    );
  }

  changePassword(password: string, token: string) {
    return this.$http.post<components['schemas']['EmptyResponse']>(
      `v1.0/password-reset`,
      {
        newPassword: password,
        passwordResetToken: token,
      },
    );
  }

  sendPasswordResetLink(email: string) {
    return this.$http.post<components['schemas']['EmptyResponse']>(
      `v1.0/password-reset/request`,
      {
        email,
      },
    );
  }

  requestWhatsappNumberVerification() {
    return this.$http.post<components['schemas']['EmptyResponse']>(
      `v1.0/accounts/me/whatsapp-verification/request`,
      {},
    );
  }

  verifyWhatsappNumber(code: string) {
    return this.$http.post<components['schemas']['EmptyResponse']>(
      `v1.0/accounts/me/whatsapp-verification`,
      {
        code,
      },
    );
  }

  saveNotificationPreference(
    payload: components['schemas']['NotificationPreferencePayload'],
  ) {
    return this.$http
      .post<
        components['schemas']['DetailResponse_AccountResponse_']
      >(`v1.0/accounts/me/notification-preferences`, payload)
      .then((response) => response.data);
  }

  requestEmailVerification() {
    return this.$http.post<components['schemas']['EmptyResponse']>(
      `v1.0/accounts/me/email-verification/request`,
      {},
    );
  }

  updateUserAccount(payload: Partial<PatchAccountPayload>) {
    return this.$http.patch<
      components['schemas']['DetailResponse_AccountResponse_']
    >(`v1.0/accounts/me`, payload);
  }

  deleteUserAccount(currentPassword: string) {
    return this.$http.post(`v1.0/accounts/me`, {
      action: 'delete-account',
      currentPassword,
    });
  }

  async getOAuthClients() {
    const response =
      await this.$http.get<components['schemas']['ListResponse_OAuthClient_']>(
        `v1.0/oauth-clients`,
      );

    return response?.data?.data;
  }

  async addOAuthClient({
    name,
    clientType,
  }: {
    name: string;
    clientType?: components['schemas']['OauthClientTypeEnum'];
  }) {
    const response = await this.$http.post(`v1.0/oauth-clients`, {
      name,
      clientType,
    });
    return response?.data?.data;
  }

  async deleteOAuthClient(clientId: string) {
    return await this.$http.delete<components['schemas']['EmptyResponse']>(
      `v1.0/oauth-clients/${clientId}`,
    );
  }

  async resetOAuthClientSecret(clientId: string) {
    const response = await this.$http.post<
      components['schemas']['DetailResponse_ClearTextOAuthClient_']
    >(`v1.0/oauth-clients/${clientId}/reset-secret`);
    return response?.data?.data;
  }
}
