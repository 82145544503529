<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useAuthStore } from '@/core/stores/auth';
import { useMasterDataStore } from '@/core/stores/masterData';

import { REDIRECT_ROUTE_NAMES } from './redirect-route-names';
import DataIntegrationStep from './steps/DataIntegrationStep.vue';
import IceChatStep from './steps/IceChatStep.vue';
import IntroStep from './steps/IntroStep.vue';
import WhatsappStep from './steps/WhatsappStep.vue';

const step = ref(1);
const route = useRoute();

const authStore = useAuthStore();

const masterDataStore = useMasterDataStore();
const whiteListedRouteNames = Object.values(REDIRECT_ROUTE_NAMES);
const whiteListedRoutePaths = ['onboarding'];

const show = computed(() => masterDataStore.showMandatorySetup);
const isLoggedIn = computed(() => authStore.loggedIn);

function goNext() {
  step.value++;
}

async function setMandatorySetupDone() {
  step.value = 1;
  await authStore.setHasVisitedMandatoryOnboarding();
}

function closeModal() {
  setMandatorySetupDone();
  masterDataStore.showMandatorySetup = false;
}

function decideToOpenOrNot() {
  setTimeout(() => {
    if (!isLoggedIn.value || authStore.userAccount?.hasVisitedOnboardingPage) {
      return;
    }
    if (
      whiteListedRouteNames.includes(
        route.name as (typeof REDIRECT_ROUTE_NAMES)[keyof typeof REDIRECT_ROUTE_NAMES],
      )
    ) {
      return;
    }
    if (whiteListedRoutePaths.some((path) => route.path.includes(path))) {
      return;
    }
    masterDataStore.showMandatorySetup = true;
  }, 1000);
}

watch(
  () => route.name,
  () => {
    decideToOpenOrNot();
  },
);

onMounted(() => {
  decideToOpenOrNot();
});
</script>
<template>
  <a-modal width="600px" :closable="false" :visible="show" :footer="false">
    <Transition name="slide-fade" mode="out-in">
      <!-- Intro -->
      <div v-if="step === 1" class="step-section-container">
        <IntroStep @next="goNext" />
      </div>

      <!-- Enter whatsapp to receive price notifications -->
      <div v-else-if="step === 2" class="step-section-container">
        <WhatsappStep @next="goNext" />
      </div>
      <div v-else-if="step === 3" class="step-section-container">
        <IceChatStep @next="goNext" />
      </div>
      <div v-else-if="step === 4" class="step-section-container">
        <DataIntegrationStep @next="closeModal" />
      </div>
      <!-- <div v-else-if="step === 5" class="step-section-container">
        <TutorialsStep @next="closeModalAndGoTutorials" />
      </div> -->
    </Transition>
  </a-modal>
</template>
<style lang="scss">
.step-section-container {
  @apply flex flex-col justify-center space-y-4 p-4;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
</style>
