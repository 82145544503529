<template>
  <g>
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.48178 3.79417C2.05438 7.39409 2.19424 13.0909 5.79417 16.5183L12 22.7241L18.5183 16.2059C21.9457 12.6059 21.8058 6.90918 18.2059 3.48178C14.6059 0.0543829 8.90918 0.194243 5.48178 3.79417ZM6.41382 8.0862L7.53106 7L12 11.3448L16.469 7.00002L17.5862 8.08623L12 13.5172L6.41382 8.0862Z"
    />
  </g>
</template>
