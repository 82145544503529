export function getAppRelease() {
  const rev = import.meta.env.VITE_APP_BUILD_EPOCH;
  if (import.meta.env.VITE_APP_MODE === 'development') {
    return `v${import.meta.env.VITE_APP_VERSION}-dev-${rev}`;
  }
  if (import.meta.env.VITE_APP_MODE === 'preprod') {
    return `v${import.meta.env.VITE_APP_VERSION}-preprod-${rev}`;
  }
  if (import.meta.env.VITE_APP_MODE === 'production') {
    return `v${import.meta.env.VITE_APP_VERSION}`;
  }
  throw new Error(`Unknown environment: ${import.meta.env.VITE_APP_MODE}`);
}
