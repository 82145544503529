import ApiBase from '@/core/services/ApiBase';
import type { components, RoutesSearchCriteria } from '@/types';

export default class RoutesService extends ApiBase {
  async getReferenceData() {
    const response = await this.$http.get<
      components['schemas']['DetailResponse_RoutesReferenceDataDTO_']
    >(`v2.0/routes/reference-data`);
    return response.data;
  }

  async getSearchResults(criteria: RoutesSearchCriteria) {
    let queryString = `loadPort=${criteria.loadPortUuid}&dischargePort=${criteria.dischargePortUuid}&releaseDate=${criteria.releaseDate}`;
    if (criteria.pointCode) {
      queryString += `&via=${criteria.pointCode}`;
    }

    const response = await this.$http.get<
      components['schemas']['DetailResponse_RouteDTO_']
    >(`v2.0/routes?${queryString}`);
    return response.data;
  }
}
