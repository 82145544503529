export default {
  DIMENSION_SP: 'spark_prices',
  DIMENSION_SP_ST_DEV: 'submitter_benchmark_sd',
  DIMENSION_SP_RANGE: 'submitter_benchmark_range',
  DIMENSION_SP_DIRECTION: 'submitter_benchmark_direction',
  DIMENSION_SP_PERCENT: 'spark_prices_percent',
  DIMENSION_YS: 'submitter_prices',
  DIMENSION_YS_PREV: 'previous_submitter_prices',
  DIMENSION_YS_MIN: 'submitter_benchmark_min',
  DIMENSION_YS_MAX: 'submitter_benchmark_max',
  DIMENSION_PR: 'previous_spark_prices',
  DIMENSION_PP: 'portfolio_player_benchmark_avg',
  DIMENSION_PP_MIN: 'portfolio_player_benchmark_min',
  DIMENSION_PP_MAX: 'portfolio_player_benchmark_max',
  DIMENSION_OWNER: 'owner_benchmark_avg',
  DIMENSION_DELTA: 'Δ',

  MARKER_SIZE_CONFIG: {
    spark_prices: 10,
    previous_spark_prices: 8,
    submitter_prices: 8,
    submitter_benchmark: 8,
    submitter_benchmark_min: 6,
    submitter_benchmark_max: 6,
    portfolio_player_benchmark_min: 6,
    portfolio_player_benchmark_max: 6,
    portfolio_player_benchmark_avg: 8,
    buyer_avg: 8,
    buyer_min: 6,
    buyer_max: 6,
    seller_avg: 8,
    seller_min: 6,
    seller_max: 6,
    trader_avg: 8,
    trader_min: 6,
    trader_max: 6,
    previous_submitter_prices: 8,
    spark_prices_174: 8,
    spark_neutral_prices: 8,
    hub_price_tfm: 8,
    hub_price_peg: 8,
    hub_price_m_uk_nbp: 8,
    hub_price_the: 8,
  } as Record<string, number>,

  LINE_WIDTH_CONFIG: {
    spark_prices: 3,
    previous_spark_prices: 2,
    submitter_prices: 2,
    submitter_benchmark: 2,
    submitter_benchmark_min: 1,
    submitter_benchmark_max: 1,
    portfolio_player_benchmark_min: 1,
    portfolio_player_benchmark_max: 1,
    portfolio_player_benchmark_avg: 2,
    buyer_avg: 2,
    buyer_min: 1,
    buyer_max: 1,
    seller_avg: 2,
    seller_min: 1,
    seller_max: 1,
    trader_avg: 2,
    trader_min: 1,
    trader_max: 1,
    previous_submitter_prices: 3,
    spark_prices_174: 1,
    spark_neutral_prices: 1,
    hub_price_tfm: 2,
    hub_price_peg: 2,
    hub_price_m_uk_nbp: 2,
    hub_price_the: 2,
  } as Record<string, number>,

  LAYERS: {
    submitter_benchmark_max: 1,
    submitter_benchmark_min: 2,
    spark_prices: 6,
    submitter_prices: 8,
    previous_spark_prices: 8,
    portfolio_player_benchmark_min: 4,
    portfolio_player_benchmark_max: 3,
    portfolio_player_benchmark_avg: 7,
    owner_benchmark_min: 14,
    owner_benchmark_max: 13,
    owner_benchmark_avg: 17,
    buyer_avg: 7,
    buyer_min: 4,
    buyer_max: 3,
    seller_avg: 7,
    seller_min: 4,
    seller_max: 3,
    trader_avg: 7,
    trader_min: 4,
    trader_max: 3,
    previous_submitter_prices: 8,
    spark_prices_174: 8,
    spark_neutral_prices: 8,
    hub_price_tfm: 7,
    hub_price_peg: 8,
    hub_price_m_uk_nbp: 9,
    hub_price_the: 10,
  } as Record<string, number>,
} as const;
