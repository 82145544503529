import ApiBase from '@/core/services/ApiBase';
import type { paths } from '@/types';

export default class GlobalCalcService extends ApiBase {
  async getReferenceData() {
    const path = 'v1.0/global-calculator/reference-data';

    type Response =
      paths['/v1.0/global-calculator/reference-data']['get']['responses']['200']['content']['application/json'];

    const response = await this.$http.get<Response>(path);
    return response?.data;
  }
}
