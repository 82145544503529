<template>
  <g clip-path="url(#clip0_23047_11237)">
    <path
      d="M18.0019 7.83441V7.78902C18.0019 4.82439 15.6527 3 12.3008 3C10.3672 3 8.83012 3.54512 7.59885 4.3891C6.88308 4.87964 6.81864 5.95508 7.34303 6.64715L7.72542 7.15183C8.24981 7.8436 9.18882 7.90937 9.94896 7.4895C10.6417 7.1068 11.3443 6.89964 12.0042 6.89964C13.0987 6.89964 13.7145 7.37833 13.7145 8.15394V8.17661C13.7145 9.15739 12.7794 10.5589 10.1341 10.7868L10.043 10.8894C9.99272 10.946 10.0562 11.6881 10.1844 12.5465L10.2896 13.2465C10.4179 14.1049 11.1398 14.8006 11.9017 14.8006C12.6635 14.8006 13.3427 14.4738 13.4177 14.071C13.4936 13.668 13.5548 13.3411 13.5548 13.3411C16.0857 12.725 18.0019 10.7079 18.0019 7.83441Z"
    />
    <path
      d="M11.1583 16.123C10.2903 16.123 9.58704 16.8267 9.58704 17.6946V18.4285C9.58704 19.2965 10.2907 20.0001 11.1583 20.0001H12.5076C13.3756 20.0001 14.0792 19.2965 14.0792 18.4285V17.6946C14.0792 16.8267 13.3753 16.123 12.5076 16.123H11.1583Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_23047_11237">
      <rect width="17" height="17" transform="translate(4 3)" />
    </clipPath>
  </defs>
</template>
