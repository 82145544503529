import Cookies from 'js-cookie';

import { useAppStore } from '@/core/stores/app';
import { AnalyticsEvent } from '@/types';

import { analyticsService } from '../services';
import { useAuthStore } from '../stores/auth';
import { Platform } from './types';

const getContextForInternalTrack = () => {
  const context = {
    product: 'none',
    platform: Platform.SPARK,
  };
  const appStore = useAppStore();
  const productId = appStore?.activeProductId;
  if (productId) {
    context.product = productId;
  }

  return context;
};

function getGdprConsent() {
  const gdprConsentCookie = Cookies.get('gdpr_consent'); // cookie reading here
  return gdprConsentCookie === 'true';
}

const internalAnalyticsTrack = async (event: string, properties: any) => {
  try {
    const context = getContextForInternalTrack();

    await analyticsService.postAnalyticsTrack(
      event,
      properties,
      context,
      getGdprConsent(),
    );
  } catch (err) {
    console.log('postAnalyticsTrack exception: ' + err);
  }
};

const analyticsPage = function (name: string, properties = {}) {
  if (!useAuthStore().isSparkUser) {
    internalAnalyticsTrack(AnalyticsEvent.PageChange, {
      name,
      ...properties,
    });
  }
};

const analyticsTrack = function (event: string, properties = {}) {
  if (!useAuthStore().isSparkUser) {
    internalAnalyticsTrack(event, properties);
  } else {
    console.debug('analytics track', { event, properties });
  }
};

export { analyticsPage, analyticsTrack };
