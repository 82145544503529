<script setup lang="ts">
import type { Placement } from '@floating-ui/vue';
import { nanoid } from 'nanoid';
import type { ComponentPublicInstance, StyleValue } from 'vue';
import {
  computed,
  getCurrentInstance,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  watch,
} from 'vue';

import type { DynamicClassBinding } from '#/types/core';
import { assertIsDefined } from '#/utils/asserts';
import { delayAsync } from '#/utils/promise';

import {
  UI_TOUR_CONTEXT_KEY,
  UI_TOUR_INTERNAL_CONTEXT_KEY,
  UI_TOUR_STOP_ID_ATTR,
  waitForElementToLoad,
} from './share';
import type { FloatingArrowSlotProps, UITourStopSlotProps } from './types';

interface Props {
  targetElementId?: string;
  placement: Placement | 'modal';
  spotlightPadding?: number;
  spotlightCornerRadius?: number;
  /**
   * Sets where the scrollIntoView() method is called on the target element.
   * Used when the viewport is shorter than the target element.
   * Default is 'center'
   */
  scrollLogicalPosition?: ScrollLogicalPosition;
  /**
   * Sets some margin from the edge of the viewport for the target element
   * when the scrollIntoView() method is called.
   */
  scrollMargin?: string;
  floatingElementCss?: DynamicClassBinding;
  /**
   * The minimum and maximum duration to scan for the target element in milliseconds.
   * This will only be used when beforeMountActionAsync event is hooked up.
   * During the scanning, it will show a spinner.
   * Defaults to 500ms minimum and 5000ms (5 seconds) maximum.
   */
  scanMinMaxDuration?: [number, number];
}
const props = withDefaults(defineProps<Props>(), {
  targetElementId: undefined,
  spotlightCornerRadius: 10,
  spotlightPadding: 10,
  scrollLogicalPosition: 'center',
  scrollMargin: undefined,
  floatingElementCss: undefined,
  scanMinMaxDuration: () => [500, 5000],
});

defineSlots<{
  default?: (props: UITourStopSlotProps) => any;
  'floating-arrow'?: (props: FloatingArrowSlotProps) => any;
}>();

const emit = defineEmits<{
  beforeMountAction: [];
  beforeMountActionAsync: [done: () => void];
}>();

const context = inject(UI_TOUR_CONTEXT_KEY);
assertIsDefined(
  context,
  'UITour component must be the parent component to use UITourStop.',
);

const internalContext = inject(UI_TOUR_INTERNAL_CONTEXT_KEY);
assertIsDefined(
  internalContext,
  'UITourInternal component must be the parent component to use UITourStop.',
);

const {
  prev,
  next,
  exit,
  currentId,
  totalStops,
  scanForChildrenIds,
  currentIndex,
} = context;

const {
  showFloatingContent,
  showModalContent,
  showSpinner,
  hideSpinner,
  showContent,
  computedFloatingStyles,
  arrowPositionStyles,
  setFloatingElement,
  setFloatingContentElement,
  setFloatingArrowElement,
  setModalElement,
  placement: placementResult,
  exit: exitInternal,
} = internalContext;

const id = nanoid();

const instance = getCurrentInstance();
// https://stackoverflow.com/questions/46706737/check-if-a-component-has-an-event-listener-attached-to-it
const hasBeforeMountActionListener = computed(() => {
  return !!instance?.vnode.props?.onBeforeMountAction;
});
const hasBeforeMountActionAsyncListener = computed(() => {
  return !!instance?.vnode.props?.onBeforeMountActionAsync;
});

function setElement(
  el: Element | ComponentPublicInstance | null,
  setter: (e: HTMLElement) => void,
) {
  if (el !== null && el instanceof HTMLElement) {
    setter(el);
  }
}

onMounted(() => {
  scanForChildrenIds();
});

onUnmounted(() => {
  scanForChildrenIds();
});

watch(currentId, async (currentIdValue) => {
  if (currentIdValue === id) {
    if (hasBeforeMountActionListener.value) {
      emit('beforeMountAction');
    }
    if (hasBeforeMountActionAsyncListener.value) {
      try {
        showSpinner();
        await new Promise<void>((done) => {
          emit('beforeMountActionAsync', done);
        });
        const delayPromise = delayAsync(props.scanMinMaxDuration[0]);
        assertIsDefined(props.targetElementId);
        const waitForElPromise = waitForElementToLoad(
          props.targetElementId,
          props.scanMinMaxDuration[1],
        );
        await Promise.all([waitForElPromise, delayPromise]);
      } catch {
        await exitInternal({ omitAnimation: true });
      } finally {
        hideSpinner();
      }
    }

    await nextTick();

    if (props.placement !== 'modal') {
      await showFloatingContent({
        targetElementId: props.targetElementId,
        placement: props.placement,
        spotlightPadding: props.spotlightPadding,
        spotlightCornerRadius: props.spotlightCornerRadius,
        scrollMargin: props.scrollMargin,
        scrollLogicalPosition: props.scrollLogicalPosition,
      });
    } else {
      await showModalContent({
        spotlightPadding: props.spotlightPadding,
        spotlightCornerRadius: props.spotlightCornerRadius,
      });
    }
  }
});
</script>

<template>
  <div class="pseudo" :[UI_TOUR_STOP_ID_ATTR]="id" />
  <div
    v-if="currentId === id && showContent && props.placement !== 'modal'"
    :ref="(el) => setElement(el as Element, setFloatingElement)"
    :style="computedFloatingStyles as StyleValue"
  >
    <div
      :ref="(el) => setElement(el as Element, setFloatingContentElement)"
      class="floating-content"
      :data-placement="placement"
      :class="floatingElementCss"
    >
      <slot
        name="floating-arrow"
        :arrow-position-styles="arrowPositionStyles"
        :set-element="setFloatingArrowElement"
        :placement="placementResult"
      >
        <div
          :ref="(el) => setElement(el as Element, setFloatingArrowElement)"
          class="absolute h-[15px] w-[15px] rotate-45 bg-white"
          :style="arrowPositionStyles"
        ></div>
      </slot>
      <slot
        :prev="prev"
        :next="next"
        :exit="exit"
        :current-index="currentIndex"
        :total-stops="totalStops"
      />
    </div>
  </div>
  <div
    v-if="currentId === id && showContent && props.placement === 'modal'"
    class="fixed inset-0 flex h-full items-center justify-center"
  >
    <div
      :ref="(el) => setElement(el as Element, setModalElement)"
      class="modal"
    >
      <slot
        :prev="prev"
        :next="next"
        :exit="exit"
        :current-index="currentIndex"
        :total-stops="totalStops"
      />
    </div>
  </div>
</template>

<style scoped>
.floating-content {
  transition:
    transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1),
    opacity 0.2s linear;
}

.floating-content[data-placement^='top'] {
  @apply -translate-y-5 opacity-0;
}
.floating-content[data-placement^='bottom'] {
  @apply translate-y-5 opacity-0;
}
.floating-content[data-placement^='left'] {
  @apply -translate-x-5 opacity-0;
}
.floating-content[data-placement^='right'] {
  @apply translate-x-5 opacity-0;
}

.floating-content[data-placement^='top'].show,
.floating-content[data-placement^='right'].show,
.floating-content[data-placement^='bottom'].show,
.floating-content[data-placement^='left'].show {
  @apply translate-x-0 translate-y-0 opacity-100;
}

.modal {
  @apply opacity-0;
  transition: opacity 0.2s linear;
}
.modal.show {
  @apply scale-100 opacity-100;
}

.pseudo {
  display: none;
  pointer-events: none;
  position: absolute;
}
</style>
