<script setup lang="ts">
import { computed, ref, useSlots, watchEffect } from 'vue';

import { staticResourceUrl } from '@/config/settings';

interface Props {
  popupTitle?: string;
  popupClass?: string;
  width?: string;
  show?: boolean;
  dialogStyle?: Record<string, string>;
  contentClass?: string[];
  disableClick?: boolean;
  disableScroll?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  popupTitle: 'Spark',
  popupClass: '',
  width: '700px',
  show: false,
  dialogStyle: () => ({}),
  contentClass: () => [],
  disableClick: false,
  disableScroll: false,
});
interface Emits {
  (e: 'popup:opened'): void;
  (e: 'popup:closed'): void;
  (e: 'popup:cancel'): void;
}
const emit = defineEmits<Emits>();
const modalVisible = ref(false);
const slots = useSlots();
watchEffect(() => {
  modalVisible.value = props.show;
});
const bodyScrollStyles = computed(() => {
  return props.disableScroll
    ? {}
    : {
        '--body-max-height': '600px',
        '--body-overflow-y': 'auto',
      };
});

function setModalVisible(visible: boolean) {
  modalVisible.value = visible;
}
function onCancel() {
  modalVisible.value = false;
  emit('popup:cancel');
}
function onClosed() {
  emit('popup:closed');
}

watchEffect(() => {
  if (modalVisible.value) {
    emit('popup:opened');
  }
});
</script>
<template>
  <slot name="clickable" :set-modal-visible="setModalVisible"> </slot>

  <a-modal
    v-bind="$attrs"
    :visible="modalVisible"
    :class="[popupClass, 'spark-modal']"
    :width="width"
    :style="{
      top: '50px',
      ...bodyScrollStyles,
      ...dialogStyle,
    }"
    :after-close="onClosed"
    :no-padding="true"
    :footer="slots?.footer ? undefined : null"
    @cancel="onCancel"
  >
    <template #title>
      <div class="flex items-center">
        <img
          class="spark-logo"
          :src="`${staticResourceUrl}/images/logo_t.png`"
          alt="spark-logo"
          style="width: 20px; margin-right: 5px"
        />
        {{ popupTitle }}
      </div>
    </template>
    <div :class="contentClass">
      <slot :set-modal-visible="setModalVisible" />
    </div>

    <template #footer>
      <slot name="footer" :set-modal-visible="setModalVisible" />
    </template>
  </a-modal>
</template>

<style>
.spark-modal {
  display: flex;
}
.spark-modal .ant-modal-header {
  background: #38424c !important;
  border-bottom: none;
}

.spark-modal .ant-modal-body {
  max-height: var(--body-max-height);
  overflow-y: var(--body-overflow-y);
}

.spark-modal .ant-modal-content {
  @apply w-full;
  button {
    width: auto;
  }
  img {
    display: inline-block;
  }
  .ant-modal-title,
  .ant-modal-close {
    @apply text-white;
    &:focus {
      outline: none;
    }
    .ant-modal-close-x {
      i {
        vertical-align: middle;
      }
    }
  }
}
</style>
