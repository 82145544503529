<template>
  <g clip-path="url(#clip0_23142_7625)">
    <path
      d="M17.1429 12.0001V18.8572H20.5714V12.0001H17.1429ZM12 5.14293V18.8572H15.4286V5.14293H12ZM-1.71428 20.5715V22.2858H25.7143V1.71436H24V20.5715H-1.71428ZM6.85715 8.5715V18.8572H10.2857V8.5715H6.85715ZM1.7143 3.42864V18.8572H5.14287V3.42864H1.7143Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_23142_7625">
      <rect
        width="24"
        height="24"
        fill="white"
        transform="matrix(-1 0 0 1 24 0)"
      />
    </clipPath>
  </defs>
</template>
