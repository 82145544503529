import { notification } from 'ant-design-vue';
import { AxiosError } from 'axios';

import {
  DetailResponseError,
  HttpError,
  HTTPValidationError,
} from '#/utils/exception';

export function showErrorNotification(message: string, error: unknown) {
  if (error instanceof AxiosError) {
    const description = error.response?.data?.error?.message ?? error.message;
    notification.error({
      message,
      description,
      duration: 0,
    });
    return;
  }

  if (error instanceof DetailResponseError) {
    const messages = error.errors.map((x) => x.message);
    notification.error({
      message,
      description: messages.join('; '),
      duration: 0,
    });
    return;
  }

  if (error instanceof HTTPValidationError) {
    notification.error({
      message,
      description:
        error.error.message ??
        error.error.detail?.map((x) => x.msg).join('\n '),
      duration: 0,
    });
    return;
  }

  if (error instanceof HttpError) {
    notification.error({
      message,
      description: error.error.message,
      duration: 0,
    });
    return;
  }

  if (error instanceof Error) {
    notification.error({
      message,
      description: error.message,
      duration: 0,
    });
    return;
  }

  if (typeof error === 'string') {
    notification.error({
      message,
      description: error,
      duration: 0,
    });
    return;
  }

  console.error(error);
}

export function showSuccessNotification(message: string) {
  notification.success({
    message,
  });
}
