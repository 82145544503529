<template>
  <g clip-path="url(#clip0_23253_8090)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.23955 11.1466C2.91681 11.6735 2.91681 12.331 3.23955 12.8579L7.1124 19.1894C7.4213 19.6938 7.97457 20 8.57855 20H16.4211C17.0204 20 17.5783 19.6938 17.8872 19.1894L21.7601 12.8579C22.0828 12.331 22.0828 11.6735 21.7601 11.1466L17.8872 4.81058C17.5783 4.30622 17.025 4 16.4211 4H8.57855C7.97918 4 7.4213 4.30622 7.1124 4.81058L3.23955 11.1466ZM15.2269 18.0006H11.1512C10.0078 18.0006 9.19175 16.9243 9.51448 15.857L12.1655 7.18829C12.3822 6.48128 13.0462 5.99944 13.8023 5.99944H15.2315C15.8309 5.99944 16.3888 6.30566 16.6977 6.81002L19.3441 11.1421C19.6669 11.669 19.6669 12.3265 19.3441 12.8534L16.6977 17.1855C16.3888 17.6898 15.8355 17.9961 15.2315 17.9961L15.2269 18.0006Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_23253_8090">
      <rect width="19" height="16" fill="white" transform="translate(3 4)" />
    </clipPath>
  </defs>
</template>
