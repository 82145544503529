<template>
  <g>
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 17.3491L4.20163 20.9232L5.5 13.3531L0 7.99197L7.60081 6.88751L11 0L14.3992 6.88751L22 7.99197L16.5 13.3531L17.7984 20.9232L11 17.3491Z"
    />
  </g>
</template>
