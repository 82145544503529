import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

export default class FFAPriceReleaseService extends ApiBase {
  async getPriceReleaseExtraData(releaseDate: string) {
    const response = await this.$http.get<
      components['schemas']['DetailResponse_FFAExtraDataDTO_']
    >(
      `v2.0/price-releases/${'lng-freight::financial'}/extra-data/${releaseDate}`,
    );
    return response?.data;
  }

  async getLastPriceReleaseExtraData() {
    const response = await this.$http.get<
      components['schemas']['DetailResponse_FFAExtraDataDTO_']
    >(`v2.0/price-releases/${'lng-freight::financial'}/extra-data/last`);
    return response?.data;
  }

  async getTransactions() {
    const response =
      await this.$http.get<components['schemas']['ListResponse_FFATradeDTO_']>(
        `v1.0/ffa-tracker`,
      );
    return response?.data;
  }

  async getSeasonalityTracker(contractId: string) {
    const response = await this.$http.get<
      components['schemas']['ListResponse_SeasonalityTrackerDTO_']
    >(`v1.0/seasonality-tracker/${contractId}`);

    return response?.data;
  }
}
