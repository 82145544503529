import type { AxiosResponse } from 'axios';

import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

export default class PriceService extends ApiBase {
  getLngPriceSpot() {
    return this.$http
      .get(`v1.0/lng-prices/spot`)
      .then(function (response: any) {
        return { success: true, data: response?.data?.data };
      })
      .catch(function (err: any) {
        return { success: false, data: null, error: err };
      });
  }

  getGACPortCosts() {
    return this.$http
      .get(`v1.0/price-reference-data/port-costs`)
      .then(function (
        response: AxiosResponse<
          components['schemas']['DetailResponse_PortCostDTOListWrapper_']
        >,
      ) {
        return { success: true, data: response?.data?.data };
      })
      .catch(function (err: any) {
        return { success: false, data: null, error: err };
      });
  }

  getRTCalcReferenceData() {
    return this.$http
      .get(`v1.0/round-trip-calculator/reference-data`)
      .then(function (
        response: AxiosResponse<
          components['schemas']['DetailResponse_RoundTripCalculatorRefDataDTO_']
        >,
      ) {
        return { success: true, data: response?.data?.data };
      })
      .catch(function (err: any) {
        return { success: false, data: null, error: err };
      });
  }
}
