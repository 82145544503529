import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

export default class MarketingService extends ApiBase {
  SERVICE_BASE_URL = `v1.0/marketing-spaces`;

  async getRecentUpdates() {
    const response = await this.$http.get<
      components['schemas']['ListResponse_RecentUpdateItemDTO_']
    >('v1.0/marketing/recent-updates');
    return response.data.data;
  }

  async getFeedbacks() {
    const response = await this.$http.get<
      components['schemas']['ListResponse_FeedbackItemDTO_']
    >('v1.0/marketing/feedbacks');
    return response.data.data;
  }

  sendUserFeedback(
    feedbackSlug: string,
    payload: components['schemas']['FeedbackResponsePayload'],
  ) {
    return this.$http.post(
      `v1.0/marketing/feedbacks/${feedbackSlug}/response`,
      payload,
    );
  }

  performMarketingAction(url: string) {
    return this.$http.post(url);
  }
}
