<script lang="ts" setup>
import { computed } from 'vue';

import { useAuthStore } from '@/core/stores/auth';

import WhatsappNumber from '../../WhatsappNumber/WhatsappNumber.vue';

const emit = defineEmits(['next']);
const authStore = useAuthStore();
const isWhatappAdded = computed<boolean>(() => {
  return !!authStore.userAccount?.whatsappNumber;
});
</script>
<template>
  <!-- Title -->
  <div class="flex flex-col justify-center space-y-4 text-center">
    <img src="@/assets/img/more/whatsapp-large.png" class="mx-auto w-[72px]" />
    <h1 class="h2-sm">1/3 WhatsApp Price notifications</h1>
  </div>
  <!-- content -->
  <div class="space-y-4 text-center">
    <p class="text-left">
      Enter your WhatsApp contact to receive price notifications directly on
      your phone:
    </p>
    <div class="flex justify-center rounded bg-shade-light py-4 shadow">
      <WhatsappNumber />
    </div>
  </div>
  <!-- start action -->
  <div class="flex flex-col items-center justify-center gap-4 pt-2">
    <SparkTrackerButton v-if="isWhatappAdded" size="lg" @click="emit('next')"
      >Continue</SparkTrackerButton
    >
    <SparkTrackerButton v-else look="text" @click="emit('next')"
      >Skip for now</SparkTrackerButton
    >
  </div>
</template>
