<script setup lang="ts">
function updateNow() {
  window.location.reload();
}
</script>

<template>
  <div
    class="relative flex min-h-screen items-center justify-center overflow-hidden bg-white p-6"
  >
    <div
      class="fixed left-0 top-0 mx-6 my-4 flex items-center justify-start space-x-2"
    >
      <SparkIcon icon="logo" class="text-green" :size="26" />
      <h3 class="h3 pt-1 font-semibold leading-normal text-green">Spark</h3>
    </div>

    <div class="flex h-full items-center justify-center">
      <div class="max-w-md space-y-3 text-center">
        <SparkIcon icon="new" size="136" class="mx-auto my-4 text-green" />
        <h1 class="h2">New version available</h1>
        <p>
          To ensure that you’ll always have the best experience when using
          Spark, please click the button below to update.
        </p>
        <div class="my-2 flex items-center justify-center">
          <SparkTrackerButton size="md" class="my-4" @click="updateNow"
            >Refresh & Update</SparkTrackerButton
          >
        </div>
      </div>
    </div>
  </div>
</template>
