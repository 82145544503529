import { useBreakpoints } from '@vueuse/core';

import { breakpoints } from '@/shared/configs/screenBreakpoints.js';

// use zod to verify breakpoints.js?

const breakpointsObj = Object.entries(breakpoints).reduce(
  (obj, [key, value]) => {
    obj[key] = parseInt(value as string);
    return obj;
  },
  {} as Record<string, number>,
);
export function useAppBreakpoints() {
  const breakpointsResults = useBreakpoints(breakpointsObj);
  return {
    ...breakpointsResults,
    isDesktop: breakpointsResults.greater('fhd'),
  };
}
