<script setup lang="ts">
import { notification } from 'ant-design-vue';
import type { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { computed, onUnmounted, ref, watch } from 'vue';

import ErrorMessage from '@/core/components/ErrorMessage.vue';
import { userAccountService } from '@/core/services';
import { useAuthStore } from '@/core/stores/auth';
import { useMarketingStore } from '@/core/stores/marketing';

const MESSAGE_VERIFY_SUCCESS =
  'Your WhatsApp number has been successfully verified. You should receive a message from Spark Commodities soon.';
const ERROR_MESSAGE_REQUEST_VERIFICATION =
  'Error occurred when requesting verification code for your WhatsApp number';
const ERROR_MESSAGE_VERIFY =
  'Error occurred when verifying your WhatsApp number';
const VERIFICATION_CODE_PATTERN = /^\d{6}$/;

interface Props {
  bannerMode?: boolean;
  isVerifying: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  bannerMode: false,
});

const marketingStore = useMarketingStore();
const authStore = useAuthStore();

const emit = defineEmits(['success', 'update:isVerifying']);

const { loadFeedbacks } = marketingStore;
const { verifyWhatsapp } = authStore;

const isResending = ref(false);
const whatsappNumberInternal = ref('');
const resendEnabledTime: any = ref(null);
const nextResendInSeconds = ref(0);
let timerId = -1;

const errorMsg = ref('');
const verificationCode = ref('');

const userAccount = computed(() => {
  return authStore.userAccount;
});
const whatsappNumber = computed(() => {
  return userAccount.value?.whatsappNumber ?? '';
});

const isResendDisabled = computed(() => {
  return props.isVerifying || isResending.value || !!nextResendInSeconds.value;
});

const isVerificationCodeValid = computed(() => {
  return VERIFICATION_CODE_PATTERN.test(verificationCode.value);
});

const isVerifyDisabled = computed(() => {
  return (
    props.isVerifying || isResending.value || !isVerificationCodeValid.value
  );
});

watch(
  () => whatsappNumber.value,
  (number: string) => {
    whatsappNumberInternal.value = number;
  },
  { immediate: true },
);

function checkNextResendInSeconds() {
  if (resendEnabledTime.value) {
    const now = dayjs();
    const diffInSeconds = resendEnabledTime.value.diff(now, 'seconds');
    if (diffInSeconds <= 0) {
      clearInterval(timerId);
      resendEnabledTime.value = null;
      nextResendInSeconds.value = 0;
    } else {
      nextResendInSeconds.value = diffInSeconds;
    }
  }
}

function disableImmediateResend() {
  resendEnabledTime.value = dayjs().add(1, 'minutes');
  nextResendInSeconds.value = 60;
  timerId = window.setInterval(() => {
    checkNextResendInSeconds();
  });
}

function requestWhatsappNumberVerification() {
  isResending.value = true;
  errorMsg.value = '';
  userAccountService
    .requestWhatsappNumberVerification()
    .then(() => {
      isResending.value = false;
      disableImmediateResend();
    })
    .catch((error: AxiosError<{ error: { message: string } }>) => {
      isResending.value = false;
      errorMsg.value =
        error?.response?.data?.error?.message ||
        ERROR_MESSAGE_REQUEST_VERIFICATION;
    });
}

function verifyWhatsappNumber() {
  emit('update:isVerifying', true);
  errorMsg.value = '';

  verifyWhatsapp(verificationCode.value)
    .then(() => {
      emit('update:isVerifying', false);
      notification.success({
        message: 'Success',
        description: MESSAGE_VERIFY_SUCCESS,
      });
      loadFeedbacks();
      emit('success');
    })
    .catch((error: AxiosError<{ error: { message: string } }>) => {
      emit('update:isVerifying', false);
      errorMsg.value =
        error?.response?.data?.error?.message || ERROR_MESSAGE_VERIFY;
    });
}

onUnmounted(() => {
  if (timerId) {
    clearInterval(timerId);
  }
});

const verificationCodeFormatted = computed(() => {
  return verificationCode.value.length > 3
    ? verificationCode.value.slice(0, 3) + ' ' + verificationCode.value.slice(3)
    : verificationCode.value;
});

function onVerificationCodeChanged(value: string) {
  const removedWhitespace = value.replace(/\s/g, '');
  verificationCode.value = removedWhitespace;
}
</script>

<template>
  <div class="space-y-4">
    <p>
      A message with a verification code has been sent to
      <span class="whitespace-nowrap font-medium"
        >{{ whatsappNumberInternal }}
      </span>
      via WhatsApp. The code is valid for 20 minutes. Please enter the 6-digit
      code.
    </p>
    <a-input
      style="font-size: 2rem; padding: 1em"
      placeholder="verification code"
      class="text-center focus:placeholder:opacity-0"
      pattern="[A-Za-z]{3} [A-Za-z]{3}"
      :maxlength="7"
      :value="verificationCodeFormatted"
      @update:value="onVerificationCodeChanged"
    >
    </a-input>
    <ErrorMessage :message="errorMsg"></ErrorMessage>
  </div>

  <div class="mt-6 flex items-center justify-center space-x-4">
    <SparkTrackerButton
      look="outlined"
      :disabled="isResendDisabled"
      :loading="isResending"
      @click="requestWhatsappNumberVerification"
      >Resend Code<span v-if="nextResendInSeconds">
        ({{ nextResendInSeconds }}s)</span
      ></SparkTrackerButton
    >
    <SparkTrackerButton
      :disabled="isVerifyDisabled"
      :loading="isVerifying"
      @click="verifyWhatsappNumber"
      >Verify</SparkTrackerButton
    >
  </div>
</template>
