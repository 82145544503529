import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

type ListResponseWebhookDto = components['schemas']['ListResponse_WebhookDTO_'];
type DetailResponseWebhookOptions =
  components['schemas']['DetailResponse_WebhookOptions_'];
type DetailResponseWebhookDto =
  components['schemas']['DetailResponse_WebhookDTO_'];
type EmptyResponse = components['schemas']['EmptyResponse'];

export default class WebhookService extends ApiBase {
  SERVICE_BASE_URL = 'v1.0/webhooks';

  async getWebhooks() {
    const response = await this.$http.get<ListResponseWebhookDto>(
      `${this.SERVICE_BASE_URL}`,
    );
    return response.data;
  }

  async getWebhooksMeta() {
    const response = await this.$http.options<DetailResponseWebhookOptions>(
      `${this.SERVICE_BASE_URL}`,
    );
    return response.data;
  }

  async createWebhook({
    url,
    description,
    contracts,
    eventTypes,
  }: {
    url: string;
    description: string;
    contracts: string[];
    eventTypes: string[];
  }) {
    const response = await this.$http.post<DetailResponseWebhookDto>(
      `${this.SERVICE_BASE_URL}`,
      {
        url,
        description,
        contracts,
        eventTypes,
      },
    );
    return response.data;
  }

  async updateWebhook(webhook: any) {
    const response = await this.$http.patch<DetailResponseWebhookDto>(
      `${this.SERVICE_BASE_URL}/${webhook.uuid}`,
      webhook,
    );
    return response.data;
  }

  async resetSigningSecret(uuid: string) {
    const response = await this.$http.post<DetailResponseWebhookDto>(
      `${this.SERVICE_BASE_URL}/${uuid}/reset-signing-secret`,
    );
    return response.data;
  }

  async deleteWebhook(uuid: string) {
    const response = await this.$http.delete<EmptyResponse>(
      `${this.SERVICE_BASE_URL}/${uuid}`,
    );
    return response.data;
  }

  async getDeliveries(uuid: string) {
    const response = await this.$http.get<ListResponseWebhookDto>(
      `${this.SERVICE_BASE_URL}/${uuid}/deliveries`,
    );
    return response.data;
  }

  async triggerWebhook(uuid: string, contract: string, eventType: string) {
    const response = await this.$http.post<EmptyResponse>(
      `${this.SERVICE_BASE_URL}/${uuid}/deliveries`,
      { contract, eventType },
    );
    return response.data;
  }
}
