<template>
  <g>
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5347 3.25165C19.1995 2.91612 18.656 2.91612 18.3208 3.25165L17.572 4.00116C16.6149 3.54347 15.4337 3.71141 14.641 4.50498L5.53683 13.618L10.3924 18.4782L19.4965 9.36523C20.2893 8.57166 20.4571 7.38938 19.9998 6.43128L20.7486 5.68178C21.0838 5.34624 21.0838 4.80224 20.7486 4.46671L19.5347 3.25165ZM15.8713 10.5638L10.3924 16.0481L7.96459 13.618L13.4435 8.13368L15.8713 10.5638ZM17.432 9.00159L18.2826 8.15017C18.6178 7.81464 18.6178 7.27064 18.2826 6.93511L17.0687 5.72004C16.7335 5.38451 16.19 5.38451 15.8548 5.72004L15.0042 6.57146L17.432 9.00159Z"
    />
    <path fill="currentColor" d="M3 21L4.82116 14.3168L9.67636 19.1774L3 21Z" />
  </g>
</template>
