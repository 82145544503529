<template>
  <g>
    <path
      fill="currentColor"
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill-opacity="0.15"
    />
    <path
      fill="currentColor"
      d="M9.17188 15.7711L10.1147 16.7139L14.8287 11.9998L10.1147 7.28579L9.17189 8.2286L12.9431 11.9998L9.17188 15.7711Z"
    />
  </g>
</template>
