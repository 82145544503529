<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useAuthStore } from '@/core/stores/auth';

import { REDIRECT_ROUTE_NAMES } from '../redirect-route-names';

const router = useRouter();

const emit = defineEmits(['next']);

const authStore = useAuthStore();

const availableApiSubscriptions = computed(() => {
  return authStore.apiSubscriptions;
});

const hasApiSubscription = computed(() => {
  return availableApiSubscriptions.value?.length;
});

const integrationsRouteHref = computed(() => {
  const routeData = router.resolve({ name: REDIRECT_ROUTE_NAMES.INTEGRATIONS });
  return routeData.href;
});
</script>
<template>
  <!-- Title -->
  <div class="flex flex-col justify-center space-y-4 text-center">
    <h1 class="h2-sm">3/3 Data Integration</h1>
    <h2 class="h3-sm text-center">Connect to Spark via multiple methods</h2>
    <p v-if="hasApiSubscription" class="text-left">
      Your subscription includes
      <a :href="integrationsRouteHref" target="_blank">data integration tools</a
      >. Make sure you have installed yours.
    </p>
    <p v-else class="text-left">
      To save you time and effort, we’ve built
      <a :href="integrationsRouteHref" target="_blank">multiple options</a>
      for you to integrate our data into your systems.
    </p>

    <a :href="integrationsRouteHref" target="_blank">
      <img
        src="@/assets/img/mandatory-setup/integration-methods.webp"
        class="mx-auto w-full rounded-lg"
      />
    </a>
  </div>
  <!-- content -->
  <div v-if="!hasApiSubscription" class="space-y-4 text-center">
    <section class="space-y-4 rounded bg-shade-light p-4 text-left shadow">
      Your subscription does not include data integration tools. Reach out to
      <a href="mailto:commercial@sparkcommodities.com"
        >commercial@sparkcommodities.com</a
      >
      to get access.
    </section>
  </div>
  <div v-else class="space-y-4 text-center">
    <section class="flex justify-center rounded bg-shade-light p-4 shadow">
      <a :href="integrationsRouteHref" target="_blank">
        <SparkTrackerButton look="text" icon="external-link"
          >Open Data Integrations Page in New Tab</SparkTrackerButton
        >
      </a>
    </section>
  </div>
  <!-- start action -->
  <div class="flex flex-col items-center justify-center gap-4 pt-2">
    <SparkTrackerButton size="md" @click="emit('next')"
      >Access the platform</SparkTrackerButton
    >
  </div>
</template>
