<template>
  <g>
    <path
      d="M5.79417 16.5183C2.19424 13.0909 2.05438 7.39409 5.48178 3.79417C8.90918 0.194243 14.6059 0.054383 18.2059 3.48178C21.8058 6.90918 21.9457 12.6059 18.5183 16.2059L12 22.7241L5.79417 16.5183Z"
      fill="#21C08E"
    />
    <path
      d="M8.80566 14V6.12598H12.1519C12.9933 6.12598 13.6038 6.19759 13.9834 6.34082C14.3665 6.48047 14.6727 6.73112 14.9019 7.09277C15.131 7.45443 15.2456 7.868 15.2456 8.3335C15.2456 8.92432 15.0719 9.41309 14.7246 9.7998C14.3773 10.1829 13.8581 10.4246 13.167 10.5249C13.5107 10.7254 13.7936 10.9456 14.0156 11.1855C14.2412 11.4255 14.5438 11.8516 14.9233 12.4639L15.8848 14H13.9834L12.834 12.2866C12.4258 11.6743 12.1465 11.2894 11.9961 11.1318C11.8457 10.9707 11.6864 10.8615 11.5181 10.8042C11.3498 10.7433 11.083 10.7129 10.7178 10.7129H10.3955V14H8.80566ZM10.3955 9.45605H11.5718C12.3345 9.45605 12.8107 9.42383 13.0005 9.35938C13.1903 9.29492 13.3389 9.18392 13.4463 9.02637C13.5537 8.86882 13.6074 8.67188 13.6074 8.43555C13.6074 8.17057 13.5358 7.95752 13.3926 7.79639C13.2529 7.63167 13.0542 7.52783 12.7964 7.48486C12.6675 7.46696 12.2808 7.45801 11.6362 7.45801H10.3955V9.45605Z"
      fill="white"
    />
  </g>
</template>
