import { createRouter, createWebHistory } from 'vue-router';

import { getItem, setItem } from '@/core/misc/UserLocalStorage';

import { afterGuards, beforeGuards } from './route-guard';
import { constantRouteMap } from './router.config';

const router = createRouter({
  history: createWebHistory(),
  routes: constantRouteMap,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else if (to.meta.scrollToTop) {
      return { top: 0 };
    }
  },
});

beforeGuards.forEach((guard) => {
  router.beforeEach(guard);
});

afterGuards.forEach((guard) => {
  router.afterEach(guard);
});

// router error for dynamic import failure
// https://github.com/vitejs/vite/issues/11804
window.setTimeout(() => {
  setItem('dynamicImportFailure', 'false');
}, 10000);

router.onError((error) => {
  if (
    (error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Importing a module script failed')) &&
    getItem('dynamicImportFailure') === 'false'
  ) {
    setItem('dynamicImportFailure', 'true');
    window.location.reload();
  }
});

export default router;
