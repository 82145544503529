<script lang="ts" setup>
import type { FormInstance } from 'ant-design-vue';
import { notification } from 'ant-design-vue';
import { computed, reactive, ref, watch } from 'vue';

import ErrorMessage from '@/core/components/ErrorMessage.vue';
import { useAuthStore } from '@/core/stores/auth';
import { analyticsTrack } from '@/core/utils/usageAnalytics';
import SparkPopup from '@/shared/components/SparkPopup.vue';
import { AnalyticsEvent } from '@/types';

const ERROR_MESSAGE_UPDATE =
  'Error occurred when updating your ICE chat account handle';

interface Props {
  show: boolean;
}

const props = defineProps<Props>();

interface Emit {
  (e: 'close'): void;
}

const emits = defineEmits<Emit>();

const authStore = useAuthStore();

const handle = computed(() => {
  return authStore.userAccount?.iceChatUsername;
});

const formState = reactive(getInitialFormState());
const iceChatForm = ref<FormInstance | null>(null);
const errorMsg = ref('');
const loading = ref(false);

function getInitialFormState() {
  return {
    handle: handle.value ?? '',
    password: '',
  };
}

watch(
  () => props.show,
  () => {
    if (!props.show) {
      Object.assign(formState, getInitialFormState());
    }
  },
);

const rules = {
  handle: [
    {
      max: 256,
      trigger: 'change',
      message: 'Account handle must be within 256 characters',
    },
  ],
  password: [
    { required: true, trigger: 'blur', message: 'Password is required' },
  ],
};

async function submitForm() {
  loading.value = true;
  const response = await authStore.updateUserAccount({
    iceChatUsername: formState.handle,
    currentPassword: formState.password,
  });
  if (response?.success) {
    closeModal();
    notification?.success({
      message: 'Success',
      description: 'Updated successfully',
    });
  } else {
    errorMsg.value = response?.error?.message || ERROR_MESSAGE_UPDATE;
  }

  loading.value = false;

  analyticsTrack(AnalyticsEvent.ACCOUNT_ICE_HANDLE_UPDATE_BTN_CLICKED, {
    iceChatUsername: formState.handle,
  });
}

function closeModal() {
  emits('close');
}
</script>
<template>
  <SparkPopup
    :closable="!loading"
    :footer="null"
    :mask-closable="!loading"
    :show="show"
    popup-title="Manage ICE Chat Account"
    width="450px"
    @popup:cancel="closeModal"
  >
    <a-form
      ref="iceChatForm"
      :model="formState"
      :rules="rules"
      @finish="submitForm"
    >
      <a-form-item label="Account Handle" name="handle">
        <a-input
          v-model:value="formState.handle"
          type="text"
          autocomplete="new"
        />
      </a-form-item>
      <div>
        <a-divider class="mb-0 mt-6" />
        <div class="bg-gray-100 p-2">
          <p class="my-2">
            Your password is required to perform profile updates
          </p>
          <div class="flex items-center space-x-2">
            <a-form-item
              class="flex-1"
              label="Password (Spark Platform)"
              name="password"
            >
              <a-input-password
                v-model:value="formState.password"
                autocomplete="new-password"
              ></a-input-password>
            </a-form-item>
          </div>
        </div>
      </div>
      <ErrorMessage :message="errorMsg"></ErrorMessage>
      <div class="mt-6 flex items-center justify-center">
        <SparkTrackerButton :loading="loading" type="submit"
          >Update
        </SparkTrackerButton>
      </div>
    </a-form>
  </SparkPopup>
</template>
