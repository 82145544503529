<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useMenuStore } from '@/core/stores/menu';
import SparkScroller from '@/shared/components/SparkScroller.vue';
import { useAppBreakpoints } from '@/shared/composables/useAppBreakpoints';
import SparkRevealer from '#/components/generic/SparkRevealer.vue';

import { accountFeatures, miscFeatures } from './mainMenuConfig';
import TheTopMenuLink from './TheTopMenuLink.vue';
import TheTopMenuProductButton from './TheTopMenuProductButton.vue';
import TheTopMenuSubmenu from './TheTopMenuSubmenu.vue';
import TheTopMenuTriggerButton from './TheTopMenuTriggerButton.vue';

const route = useRoute();
const menu = useMenuStore();

const isExpanded = ref(false);
const expandedProduct = ref<'freight' | 'cargo' | 'access' | null>(null);
const { greaterOrEqual } = useAppBreakpoints();
const {
  freightMenu,
  freightCallToActions,
  cargoMenu,
  cargoCallToActions,
  accessMenu,
  accessCallToActions,
  crossProductFeaturesMenu,
} = storeToRefs(menu);

function lockBodyScroll() {
  document.body.style.overflow = 'hidden';
}

function unlockBodyScroll() {
  document.body.style.overflow = '';
}

function onMenuButtonClicked() {
  if (!isExpanded.value) {
    if (route.meta.product === 'lng-freight-platform') {
      expandedProduct.value = 'freight';
    } else if (route.meta.product === 'lng-basis-platform') {
      expandedProduct.value = 'cargo';
    } else if (route.meta.product === 'access-platform') {
      expandedProduct.value = 'access';
    }

    isExpanded.value = true;
  } else {
    isExpanded.value = false;
  }
}

function onMenuClicked(e: MouseEvent) {
  if (
    e.composedPath().some((el) => el instanceof HTMLAnchorElement) &&
    isExpanded.value
  ) {
    isExpanded.value = false;
  }
}

watch(isExpanded, (isExpandedValue) => {
  if (isExpandedValue) {
    lockBodyScroll();
  } else {
    unlockBodyScroll();
  }
});

watch(greaterOrEqual('hd'), () => {
  if (isExpanded.value) {
    isExpanded.value = false;
  }
});
</script>

<template>
  <Teleport to="#mobile-view-top-menu">
    <div
      v-if="isExpanded"
      class="fixed inset-0 bg-transparent"
      @click.self="isExpanded = false"
    ></div>
    <div
      class="fixed top-0 w-full bg-blue-900 data-[expanded=true]:shadow-2xl hd:hidden"
      data-id="top-menu"
      :data-expanded="isExpanded"
    >
      <TheTopMenuTriggerButton
        :is-expanded="isExpanded"
        @click="onMenuButtonClicked"
      />
      <hr class="border-t border-gray-700" />
      <SparkRevealer class="bg-blue-900" :is-revealed="isExpanded">
        <SparkScroller
          :space-between-content-and-scrollbar="0"
          :scrollbar-width="0"
          :mask-height="48"
          class="h-full max-h-[calc(100svh-3rem)]"
        >
          <div @click="onMenuClicked">
            <TheTopMenuLink
              to="/dashboard"
              icon="dashboardGrid"
              label="Dashboard"
            />
            <hr class="border-t border-gray-700" />

            <!-- Freight -->
            <TheTopMenuProductButton
              to="/freight"
              label="Freight"
              icon="freight"
              color="green"
            />
            <TheTopMenuSubmenu
              :menu="freightMenu"
              :call-to-actions="freightCallToActions"
            />

            <!-- Cargo -->
            <TheTopMenuProductButton
              to="/cargo"
              label="Cargo"
              icon="basis"
              color="basis"
            />
            <TheTopMenuSubmenu
              :menu="cargoMenu"
              :call-to-actions="cargoCallToActions"
            />

            <!-- Access -->
            <TheTopMenuProductButton
              to="/access"
              label="Access"
              icon="access"
              icon-size="20"
              color="access"
            />
            <TheTopMenuSubmenu
              :menu="accessMenu"
              :call-to-actions="accessCallToActions"
              :one-column="true"
            />

            <hr class="border-t border-gray-700" />

            <template
              v-for="item in crossProductFeaturesMenu"
              :key="item.title"
            >
              <TheTopMenuLink
                v-if="item.to"
                :to="item.to"
                :icon="item.icon"
                :label="item.title"
              />
            </template>

            <hr class="border-t border-gray-700" />

            <TheTopMenuLink
              v-for="item in miscFeatures"
              :key="item.to"
              :to="item.to"
              :icon="item.icon"
              :label="item.title"
            />

            <hr class="border-t border-gray-700" />

            <TheTopMenuLink
              v-for="item in accountFeatures"
              :key="item.to"
              :to="item.to"
              :icon="item.icon"
              :label="item.title"
            />
          </div>
        </SparkScroller>
      </SparkRevealer>
    </div>
  </Teleport>
</template>
