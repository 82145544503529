<template>
  <g>
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 15.3531L5.20163 22.9232L12 19.3491L18.7984 22.9232L17.5 15.3531L23 9.99197L15.3992 8.88751L12 2L8.60081 8.88751L1 9.99197L6.5 15.3531ZM11.5 13H8.5C8.22386 13 8 13.4477 8 14C8 14.5523 8.22386 15 8.5 15H11.5H12.5H15.5C15.7761 15 16 14.5523 16 14C16 13.4477 15.7761 13 15.5 13H12.5H11.5Z"
    />
  </g>
</template>
