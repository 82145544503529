import { getAppRelease } from '@/core/utils/getAppRelease';
import type { ApiConfig, ApiMeta } from '#/interfaces/spark-api/types';

/**
 * The default configuration for the app.
 */
if (!import.meta.env.VITE_APP_SP_API_BASE_URL) {
  throw new Error('VITE_APP_SP_API_BASE_URL required in env');
}
export const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: import.meta.env.VITE_APP_SP_API_BASE_URL,
  timeout: 30000,
};

export const DEFAULT_API_META: ApiMeta = {
  appRelease: getAppRelease(),
  getBrowsingSessionId: () => window.browsingSessionId,
  clientAppName: 'sp-application',
};
