<template>
  <g>
    <path fill="currentColor" d="M17 5H7V7H17V5Z" />
    <path fill="currentColor" d="M7 9H9V11H7V9Z" />
    <path fill="currentColor" d="M9 13H7V15H9V13Z" />
    <path fill="currentColor" d="M7 17H9V19H7V17Z" />
    <path fill="currentColor" d="M13 9H11V11H13V9Z" />
    <path fill="currentColor" d="M11 13H13V15H11V13Z" />
    <path fill="currentColor" d="M13 17H11V19H13V17Z" />
    <path fill="currentColor" d="M15 9H17V11H15V9Z" />
    <path fill="currentColor" d="M17 13H15V19H17V13Z" />
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 3C3 1.89543 3.89543 1 5 1H19C20.1046 1 21 1.89543 21 3V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V3ZM5 3H19V21H5V3Z"
    />
  </g>
</template>
