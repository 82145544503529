export const CONTRACTS = 'CONTRACTS';
export const TOOLS = 'TOOLS';

export const PHYSICAL = 'Physical';
export const FINANCIAL = 'Financial';
export const NOTIFICATIONS = 'Notifications';

export const FREIGHT_TITLES = {
  DASHBOARD: 'Dashboard', // basic subscription plan
  GLOBAL_CALC: 'Global Calc',
  HISTORICAL_DATA: 'Historical Data',
  SPREAD_CHARTING: 'Spread Charting',
  ROUND_TRIP_CALC: 'Round Trip Calc',
  INDEXATION: 'Indexation',
  ROUTES: 'Routes ($/MMBtu)',
  FLOATING_STORAGE: 'Floating Storage',
  NEUTRAL: 'Neutral',
};

export const CARGO_TITLES = {
  SPARKCLOSE: 'Sparkclose',
  HISTORICAL_DATA: 'Historical Data',
  SETTLEMENT_TRACKER: 'Settlement Tracker',
  NETBACKS: 'Netbacks',
  NETFORWARDS: 'Netforwards',
};

export const ACCESS_TITLES = {
  TERMINAL_MAP: 'Terminal Map',
  REGAS_COSTS: 'Regas Costs',
  DES_HUB_NETBACKS: 'DES Hub Netbacks',
  FOB_HUB_NETBACKS: 'FOB Hub Netbacks',
  COSTS_CHANGELOG: 'Costs Changelog',
};
