<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import {
  GuideLinkText,
  InlineButton,
  SparkUITour,
  SparkUITourStop,
  SparkUITourStopLast,
  SparkUITourStopWelcome,
} from '@/core/components/UITour';
import { useAppStore } from '@/core/stores/app';
import { useMenuStore } from '@/core/stores/menu';
import { UI_TOUR_IDS as SHARED_UI_TOUR_IDS } from '@/shared/misc/constants';

import { ACCESS_TITLES, TOOLS } from '../the-nav/constants';
import { ACCESS_UI_TOUR_IDS } from './constants';

const router = useRouter();
const appStore = useAppStore();
const menuStore = useMenuStore();

const { accessMenu } = storeToRefs(menuStore);
const uiTour = ref<InstanceType<typeof SparkUITour>>();

async function goToRoute(routeUrl: string, done: () => void) {
  await router.push(routeUrl);
  done();
}

function hasAccessTo(title: string, subtitle: string) {
  const section = accessMenu.value.find((item) => item.title === title);
  if (!section) return false;

  const found = section.items.find((item) => item.title === subtitle);
  if (!found) return false;

  return !found.isLocked;
}

appStore.listenEvent('accessUITour', () => {
  uiTour.value?.startTour();
});
</script>

<template>
  <SparkUITour :no-trigger-button="true" ref="uiTour" color="access">
    <SparkUITourStopWelcome title="Spark Access">
      <p>
        Spark's Access product provides LNG regasification costs and slot
        capacity information across several major European terminals. Gain a
        greater understanding of how profitable delivering LNG to Europe is over
        the next 12 months and which terminals are most competitive.
      </p>

      <p>
        Spark's platform covers the entire LNG value chain. If you're looking
        for more information on global LNG freight costs, visit our
        <InlineButton color="green" is-external-link to="/freight">
          Freight product </InlineButton
        >. Alternatively, if you're looking to value LNG cargoes delivered to
        Europe, visit our
        <InlineButton color="basis" is-external-link to="/cargo"
          >Cargo product</InlineButton
        >.
      </p>
    </SparkUITourStopWelcome>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, ACCESS_TITLES.TERMINAL_MAP)"
      title="Terminal Map"
      placement="right-start"
      :target-element-id="ACCESS_UI_TOUR_IDS.TERMINAL_MAP_TITLE"
      @before-mount-action-async="goToRoute('/access/terminal-map', $event)"
    >
      <p>
        The Terminal Map provides an overview of some of the LNG regasification
        infrastructure across Europe, including most major regas terminals, as
        well as inter-country connectors. Hover over a terminal to view all
        currently available information such as regas costs, capacity holders
        and total capacity.
      </p>

      <p>
        Daily updates on European regas slot capacity are shown in the Slots
        Table, providing an indicator for current demand.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, ACCESS_TITLES.REGAS_COSTS)"
      title="Regas Costs"
      placement="right-start"
      :target-element-id="ACCESS_UI_TOUR_IDS.REGAS_COSTS_TITLE"
      @before-mount-action-async="goToRoute('/access/regas/terminals', $event)"
    >
      <p>
        View regasification costs across several major European terminals, as
        well as a full breakdown of these costs into a standardised set of
        components, enabling you to accurately compare regas cost components
        across terminals and providing market-leading transparency for European
        regasification.
      </p>

      <p>
        Flexibility to exclude certain costs for selected terminals, while
        keeping them at others.
      </p>

      <p>
        Rank terminals by front-month regas cost competitiveness in the
        'Terminals' tab, or switch to the 'Forward' tab to view 12-month regas
        cost forward curves.
      </p>

      <p>
        Available slots are displayed for each terminal's front month and across
        the forward curve.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, ACCESS_TITLES.DES_HUB_NETBACKS)"
      title="DES Hub Netbacks"
      placement="right-start"
      :target-element-id="ACCESS_UI_TOUR_IDS.DES_HUB_NETBACKS_TITLE"
      @before-mount-action-async="
        goToRoute('/access/des-hub-netbacks/terminals', $event)
      "
    >
      <p>
        DES Hub Netbacks combine terminal regas costs with regional gas hub
        prices, providing a more accurate picture of how competitive each
        terminal is for regas and selling into the local grid.
      </p>

      <p>
        Rank all terminals by front-month cost competitiveness in the
        'Terminals' tab, or switch to the 'Forward' tab to view 12-month cost
        forward curves.
      </p>

      <p>
        Interested in understanding which terminals are 'in the money' based on
        current DES LNG market rates? Spark Cargo NWE/SWE DES LNG assessments
        can be overlaid onto the DES Hub Netbacks chart to help identify
        profitable terminals for your chosen period.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, ACCESS_TITLES.FOB_HUB_NETBACKS)"
      title="FOB Hub Netbacks"
      placement="right-start"
      :target-element-id="ACCESS_UI_TOUR_IDS.FOB_HUB_NETBACKS_TITLE"
      @before-mount-action-async="
        goToRoute('/access/fob-netbacks/terminals', $event)
      "
    >
      <p>
        FOB Hub Netbacks combines terminal regas costs, regional gas hub prices
        and freight costs to provide a cargo valuation that incorporates all
        costs from loading to delivery to grid. Choose an FOB port to understand
        which European terminal is most profitable to deliver to for your cargo.
      </p>

      <p>
        Rank all terminals by front-month cost competitiveness in the
        'Terminals' tab, or switch to the 'Forward' tab to view 12-month cost
        forward curves.
      </p>

      <p>
        Interested in our most comprehensive cargo valuation tool? Our FOB Hub
        Netbacks tool uses
        <InlineButton color="green" is-external-link to="/freight"
          >Spark Freight data</InlineButton
        >
        and requires a Freight subscription. Head over to our
        <InlineButton
          color="green"
          is-external-link
          to="/freight/routes/discover"
          >Freight Routes</InlineButton
        >
        tool to understand how these costs are calculated and to view a full
        cost breakdown across a 2 year forward curve.
      </p>
    </SparkUITourStop>

    <SparkUITourStop
      v-if="hasAccessTo(TOOLS, ACCESS_TITLES.COSTS_CHANGELOG)"
      title="Costs Changelog"
      placement="right-start"
      :target-element-id="ACCESS_UI_TOUR_IDS.COSTS_CHANGELOG_TITLE"
      @before-mount-action-async="goToRoute('/access/changelog', $event)"
    >
      <p>
        The regas costs published on our platform are checked daily and updated
        as soon as new information is available.
      </p>

      <p>To keep track of all changes made, view our Costs Changelog page.</p>
    </SparkUITourStop>

    <SparkUITourStop
      title="Data Integrations"
      placement="right-start"
      :target-element-id="SHARED_UI_TOUR_IDS.DATA_INTEGRATIONS_TITLE"
      @before-mount-action-async="goToRoute('/data-integrations', $event)"
    >
      <p>
        Incorporate Spark's data directly into your workflow with Data
        Integrations. Pull our data through our third-party connectors, Excel
        Add-in or API.
      </p>

      <p>
        To find out more on each of these services, explore the relevant section
        within Data Integrations. If you need help setting up the API, or would
        like to further discuss anything API or platform related, please email
        <InlineButton is-external-link to="mailto:info@sparkcommodities.com"
          >info@sparkcommodities.com</InlineButton
        >
        or use our Chatbox located in the bottom-right of the screen.
      </p>
    </SparkUITourStop>

    <SparkUITourStopLast
      title="Thanks for taking the tour!"
      placement="bottom"
      :target-element-id="ACCESS_UI_TOUR_IDS.ACCESS_GUIDE_BUTTON"
      @before-mount-action-async="goToRoute('/dashboard', $event)"
    >
      <p>
        We hope you have a sufficient overview of what our Access product has to
        offer. You may further explore on each of the pages by clicking on the
        <GuideLinkText class="text-access-500" /> link at the top of their
        respective pages.
      </p>
      <p>
        You may also access this guide at any time by clicking on this
        <GuideLinkText class="text-access-500" />
        link in the Dashboard page.
      </p>
    </SparkUITourStopLast>
  </SparkUITour>
</template>
