import { useAuthStore } from '@/core/stores/auth';
import { useMasterDataStore } from '@/core/stores/masterData';
import {
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
} from '@/shared/configs/apiConfig';

import AlertCentreService from './AlertCentreService';
import FFAPriceReleaseService from './FFAPriceReleaseService';
import FloatingStorageService from './FloatingStorageService';
import GlobalCalcService from './GlobalCalcService';
import OffsetCalculatorService from './OffsetCalculatorService';
import PriceService from './PriceService';
import RoutesService from './RoutesService';
import SettlementService from './SettlementService';
import WebhookService from './WebhookService';

const getRefreshTokenFn = () => useAuthStore().refreshToken();

const getAccessTokenFn = () => useAuthStore().accessToken;
const logoutFn = () => useAuthStore().logout();

const handle409Conflict = () => useMasterDataStore().handle409Conflict();

const priceService = new PriceService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

const settlementService = new SettlementService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);
const offsetCalculatorService = new OffsetCalculatorService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

const ffaPriceReleaseService = new FFAPriceReleaseService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

const alertCentreService = new AlertCentreService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

const routesService = new RoutesService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);
const webhookService = new WebhookService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);
const floatingStorageService = new FloatingStorageService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

const globalCalcService = new GlobalCalcService(
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  getAccessTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
  },
);

export {
  alertCentreService,
  ffaPriceReleaseService,
  floatingStorageService,
  globalCalcService,
  offsetCalculatorService,
  priceService,
  routesService,
  settlementService,
  webhookService,
};
