<template>
  <g>
    <path
      d="M9.6073 15.6289H8.48828V21.1688H9.6073V15.6289Z"
      fill="currentColor"
    />
    <path
      d="M13.4649 16.3148C14.0207 16.3148 14.3704 16.4473 14.5692 16.5614L14.6465 16.6056L14.9115 15.7074L14.86 15.6816C14.595 15.5491 14.0723 15.3945 13.4686 15.3945C12.5925 15.3945 11.849 15.6816 11.3079 16.2264C10.7852 16.7528 10.498 17.5001 10.498 18.332C10.498 19.1639 10.7594 19.8743 11.2526 20.3823C11.7496 20.8976 12.4416 21.1663 13.2588 21.1663C13.9582 21.1663 14.5508 20.9859 14.8416 20.835L14.8895 20.8093L14.6907 19.9405L14.6134 19.9737C14.3263 20.0988 13.9729 20.235 13.4355 20.235C12.3569 20.235 11.6318 19.451 11.6318 18.2841C11.6318 17.732 11.8011 17.235 12.103 16.8853C12.4269 16.5135 12.898 16.3148 13.4649 16.3148Z"
      fill="currentColor"
    />
    <path
      d="M17.8049 16.2258C18.173 16.2258 18.4712 16.3399 18.6921 16.5607C19.0418 16.9141 19.0933 17.4478 19.097 17.6797H16.3804C16.4651 17.1055 16.8516 16.2258 17.8049 16.2258ZM19.7154 16.3141C19.3141 15.7067 18.681 15.3828 17.8822 15.3828C17.1056 15.3828 16.4467 15.6847 15.9718 16.2515C15.5264 16.7889 15.2798 17.5362 15.2798 18.3644C15.2798 19.1926 15.5301 19.892 16.0049 20.3889C16.4871 20.8969 17.1792 21.1656 18.0074 21.1656C18.8724 21.1656 19.4761 20.9742 19.8332 20.8123L19.8847 20.7902L19.6859 19.9509L19.6086 19.984C19.2737 20.1276 18.8651 20.2564 18.1399 20.2564C17.6356 20.2564 17.2086 20.1092 16.8994 19.8368C16.5644 19.535 16.3804 19.0932 16.3583 18.5227H20.1424L20.1534 18.4159C20.1645 18.3129 20.1829 18.173 20.1829 17.9963C20.1829 17.7018 20.1387 16.9509 19.7154 16.3141Z"
      fill="currentColor"
    />
    <path
      d="M1.84424 2.80078V21.2057H5.49209V19.6266H3.42338V4.3836H18.7068V12.7505H20.2491V2.80078H1.84424Z"
      fill="currentColor"
    />
    <path
      d="M21.6222 15.6328C21.9204 15.6328 22.1744 15.8647 22.1744 16.1776C22.1744 16.4942 21.9204 16.7261 21.6222 16.7261C21.3204 16.7261 21.0664 16.4942 21.0664 16.1776C21.0664 15.8647 21.3241 15.6328 21.6222 15.6328ZM21.6222 15.7248C21.3682 15.7248 21.1732 15.9199 21.1732 16.1813C21.1732 16.45 21.3682 16.6414 21.6222 16.6414C21.8725 16.6414 22.0676 16.45 22.0676 16.1813C22.0713 15.9199 21.8725 15.7248 21.6222 15.7248ZM21.5081 16.4978H21.4124V15.8647H21.6554C21.8063 15.8647 21.8799 15.9199 21.8799 16.0451C21.8799 16.1592 21.81 16.207 21.7143 16.2181L21.8946 16.4942H21.7879L21.6186 16.2181H21.5081V16.4978ZM21.5081 16.1445H21.6222C21.74 16.1445 21.7768 16.1113 21.7768 16.0414C21.7768 15.9825 21.7474 15.9457 21.637 15.9457H21.5044V16.1445H21.5081Z"
      fill="currentColor"
    />
  </g>
</template>
