import { snakeCase } from 'lodash';

export function deriveEventName({
  content,
  path,
}: {
  content?: string | null;
  path: string;
}) {
  return snakeCase(`${path} ${content ?? ''}`)
    .trim()
    .toUpperCase();
}
