import type { RouteRecordRaw } from 'vue-router';

import RouteView from '@/shared/components/RouteView.vue';
import AppLayout from '@/shared/layout/AppLayout.vue';
import { features, permissions } from '@/shared/misc/constants';

const TerminalMapPage = () =>
  import('@/products/access/pages/beckmap/BeckMap.vue');

const AlertCentrePage = () =>
  import('@/products/access/pages/alert-centre/AlertCentrePage.vue');

const RegasTerminalsPage = () =>
  import(
    '../pages/terminal-regasification-costs/regas/terminals/RegasTerminalsPage.vue'
  );

const DesHubNetbacksTerminalsPage = () =>
  import(
    '../pages/terminal-regasification-costs/des-hub-netbacks/terminals/DesHubNetbacksTerminalsPage.vue'
  );

const FobTerminalsPage = () =>
  import(
    '../pages/terminal-regasification-costs/fob-netbacks/terminals/FobNetbacksTerminalsPage.vue'
  );

const RegasForwardsPage = () =>
  import(
    '../pages/terminal-regasification-costs/regas/forwards/RegasForwardsPage.vue'
  );

const DesHubNetbacksForwardsPage = () =>
  import(
    '../pages/terminal-regasification-costs/des-hub-netbacks/forwards/DesHubNetbacksForwardsPage.vue'
  );

const FobNetbacksForwardsPage = () =>
  import(
    '../pages/terminal-regasification-costs/fob-netbacks/forwards/FobNetbacksForwardsPage.vue'
  );

const TerminalRegasificationCostsIntroPage = () =>
  import('../pages/terminal-regasification-costs/Intro.vue');

const CostsChangelogPage = () => import('../pages/changelog/ChangelogPage.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/access',
    component: RouteView,
    children: [
      {
        path: '',
        name: 'access home',
        redirect: '/access/terminal-map',
      },
      {
        path: 'terminal-map',
        name: features.ACCESS_TERMINAL_MAP,
        component: TerminalMapPage,
        meta: {
          title: 'Terminal Map',
          product: 'access-platform',
          hideBannerMessage: true,

          requiresAuth: true,
          permissions: [
            {
              name: permissions.ACCESS_PLATFORM_REQUEST_TERMINAL,
            },
          ],
          layout: AppLayout,
        },
      },
      {
        path: 'sparkr-costs/regas',
        redirect: '/access/regas/terminals',
      },
      {
        path: 'sparkr-costs/netbacks',
        redirect: '/access/des-hub-netbacks/terminals',
      },
      {
        path: 'sparkr-costs/fob-netbacks',
        redirect: '/access/fob-netbacks/terminals',
      },
      {
        path: 'sparkrfo-costs/regas',
        redirect: '/access/regas/forwards',
      },
      {
        path: 'sparkrfo-costs/netbacks',
        redirect: '/access/des-hub-netbacks/forwards',
      },
      {
        path: 'sparkrfo-costs/fob-netbacks',
        redirect: '/access/fob-netbacks/forwards',
      },
      {
        path: 'regas',
        component: RouteView,
        children: [
          {
            path: '',
            redirect: {
              name: features.ACCESS_TERMINAL_REGASIFICATION_COSTS_F,
            },
          },
          {
            path: 'terminals',
            name: features.ACCESS_TERMINAL_REGASIFICATION_COSTS_F,
            component: RegasTerminalsPage,
            meta: {
              title: 'Terminal Regasification Costs',
              product: 'access-platform',
              hideBannerMessage: true,
              requiresAuth: true,
              scrollToTop: true,
              permissions: [
                {
                  prefix: true,
                  name: permissions.ACCESS_PLATFORM_SPARKR_TERMINAL_COSTS,
                },
              ],
              layout: AppLayout,
            },
          },
          {
            path: 'forwards',
            name: features.ACCESS_TERMINAL_REGASIFICATION_COSTS_FO,
            component: RegasForwardsPage,
            meta: {
              title: 'Terminal Regasification Costs',
              product: 'access-platform',
              hideBannerMessage: true,
              requiresAuth: true,
              scrollToTop: true,
              permissions: [
                {
                  prefix: true,
                  name: permissions.ACCESS_PLATFORM_SPARKR_TERMINAL_COSTS,
                },
              ],
              layout: AppLayout,
            },
          },
        ],
      },
      {
        path: 'des-hub-netbacks',
        component: RouteView,
        children: [
          {
            path: '',
            redirect: {
              name: features.ACCESS_SPARKR_NETBACKS_F,
            },
          },
          {
            path: 'terminals',
            component: DesHubNetbacksTerminalsPage,
            name: features.ACCESS_SPARKR_NETBACKS_F,
            meta: {
              title: 'DES Hub Netbacks',
              product: 'access-platform',
              hideBannerMessage: true,
              requiresAuth: true,
              scrollToTop: true,
              permissions: [
                {
                  prefix: true,
                  name: permissions.ACCESS_PLATFORM_SPARKR_TERMINAL_COSTS,
                },
              ],
              layout: AppLayout,
            },
          },
          {
            path: 'forwards',
            component: DesHubNetbacksForwardsPage,
            name: features.ACCESS_SPARKR_NETBACKS_FO,
            meta: {
              title: 'DES Hub Netbacks',
              product: 'access-platform',
              hideBannerMessage: true,
              requiresAuth: true,
              scrollToTop: true,
              permissions: [
                {
                  prefix: true,
                  name: permissions.ACCESS_PLATFORM_SPARKR_TERMINAL_COSTS,
                },
              ],
              layout: AppLayout,
            },
          },
        ],
      },
      {
        path: 'fob-netbacks',
        component: RouteView,
        children: [
          {
            path: '',
            redirect: {
              name: features.ACCESS_SPARKR_FOB_NETBACKS_F,
            },
          },
          {
            path: 'terminals',
            component: FobTerminalsPage,
            name: features.ACCESS_SPARKR_FOB_NETBACKS_F,
            meta: {
              title: 'FOB Hub Netbacks',
              product: 'access-platform',
              hideBannerMessage: true,
              requiresAuth: true,
              scrollToTop: true,
              permissions: [
                {
                  prefix: true,
                  name: permissions.ACCESS_PLATFORM_SPARKR_TERMINAL_COSTS,
                },
              ],
              layout: AppLayout,
            },
          },
          {
            path: 'forwards',
            component: FobNetbacksForwardsPage,
            name: features.ACCESS_SPARKR_FOB_NETBACKS_FO,
            meta: {
              title: 'FOB Hub Netbacks',
              product: 'access-platform',
              hideBannerMessage: true,
              requiresAuth: true,
              scrollToTop: true,
              permissions: [
                {
                  prefix: true,
                  name: permissions.ACCESS_PLATFORM_SPARKR_TERMINAL_COSTS,
                },
              ],
              layout: AppLayout,
            },
          },
        ],
      },

      {
        path: 'terminal-regasification-costs/intro',
        name: features.ACCESS_TERMINAL_REGASIFICATION_COSTS_INTRO,
        component: TerminalRegasificationCostsIntroPage,
        meta: {
          title: 'Terminal Regasification Costs Intro',
          product: 'access-platform',
          hideBannerMessage: true,
          requiresAuth: true,
          layout: AppLayout,
        },
      },
      {
        path: 'changelog',
        name: features.ACCESS_SPARKR_CHANGELOG,
        component: CostsChangelogPage,
        meta: {
          title: 'Costs Changelog',
          product: 'access-platform',
          hideBannerMessage: true,
          requiresAuth: true,
          layout: AppLayout,
        },
      },
    ],
  },
  {
    path: '/access/alert-centre',
    name: features.ACCESS_ALERT_CENTRE,
    component: AlertCentrePage,
    alias: ['/alert-centre'],
    meta: {
      title: 'Alert Centre',
      product: 'access-platform',
      requiresAuth: true,
      basic: true,
      layout: AppLayout,
    },
  },
];

export default routes;
