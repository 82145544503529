<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue';
import { ref, watch } from 'vue';

import ErrorMessage from '@/core/components/ErrorMessage.vue';
import SparkPopup from '@/shared/components/SparkPopup.vue';
import type { Nullable } from '#/types/core';

interface Props {
  phoneNumber?: string;
  whatsappNumber?: Nullable<string>;
  verifiedAt?: string | null;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  defaultCountry?: string;
  error?: string;
}

const props = withDefaults(defineProps<Props>(), {
  phoneNumber: '',
  whatsappNumber: undefined,
  defaultCountry: undefined,
  verifiedAt: null,
  isSubmitting: false,
  isSubmitted: false,
  error: '',
});

interface Emits {
  (e: 'closed'): void;
  (
    e: 'save',
    value: { currentPassword: string; whatsappNumber: string | null },
  ): void;
}
const emit = defineEmits<Emits>();
const form = ref<FormInstance | null>(null);

const showModal = ref(false);
const validated = ref<any>(null);
const formData = ref(initForm());
const usePhoneNumberAsWhatsapp = ref(false);

const validationRules = {
  newWhatsappNumber: [
    {
      validator: (rule: any, value: any, fn: (status?: boolean) => void) => {
        if (value) {
          const pattern = /^\+\d{10,15}$/;
          const valid = pattern.test(value);
          return valid ? fn() : fn(false); // callbak without arg to pass, callbak with arg to fail
        } else {
          fn();
        }
      },
      message: 'Not a valid phone number',
      transform: (val: string) => {
        return val.replaceAll(' ', '');
      },
    },
  ],
  currentPassword: [
    {
      trigger: 'change',
      required: true,
      message: 'Required',
    },
  ],
};

function initForm() {
  return {
    newWhatsappNumber: props.whatsappNumber || '',
    currentPassword: '',
  };
}

function onClosed() {
  showModal.value = false;
  form.value?.resetFields();
  formData.value = initForm();
  emit('closed');
}

function countryChanged() {
  validated.value = null;
}

async function updateWhatsappNumber() {
  try {
    emit('save', {
      whatsappNumber:
        // cannot be undefined, have to use null to reset/remove it
        formData.value.newWhatsappNumber?.replaceAll(' ', '') || null,
      currentPassword: formData.value.currentPassword,
    });
  } catch (e) {
    console.log(e);
  }
}
function applyPhoneNumberAsWhatsapp(checked: boolean) {
  if (checked) {
    formData.value.newWhatsappNumber = props.phoneNumber;
    usePhoneNumberAsWhatsapp.value = true;
  } else {
    formData.value.newWhatsappNumber = props.whatsappNumber ?? '';
    usePhoneNumberAsWhatsapp.value = false;
  }
}
watch(
  () => props.isSubmitted,
  (val) => {
    if (val) {
      showModal.value = false;
    }
  },
);
</script>

<template>
  <div>
    <SparkTrackerButton
      v-if="!whatsappNumber"
      icon="plus"
      class="my-1"
      @click="() => (showModal = true)"
      >Add Whatsapp Number</SparkTrackerButton
    >

    <SparkTrackerButton
      v-else
      icon="edit"
      look="outlined"
      @click="() => (showModal = true)"
    >
      Edit
    </SparkTrackerButton>
    <SparkPopup
      popup-title="Manage WhatsApp Number"
      :show="showModal"
      :mask-closable="!isSubmitting"
      :closable="!isSubmitting"
      width="450px"
      @popup:closed="onClosed"
    >
      <a-form
        ref="form"
        :model="formData"
        :rules="validationRules"
        layout="vertical"
        class="space-y-4"
        hide-required-mark
        autocomplete="off"
        @finish="updateWhatsappNumber"
      >
        <p>Link your WhatsApp number to your account.</p>
        <a-form-item label="Whatsapp Number" name="newWhatsappNumber">
          <vue-tel-input
            v-model="formData.newWhatsappNumber"
            mode="international"
            class="flex-1"
            :valid-characters-only="true"
            :disabled="usePhoneNumberAsWhatsapp"
            @country-changed="countryChanged"
          ></vue-tel-input>
          <div v-if="phoneNumber" class="mt-1">
            <a-checkbox
              :checked="usePhoneNumberAsWhatsapp"
              @change="applyPhoneNumberAsWhatsapp($event?.target?.checked)"
            />
            Use registered phone number
          </div>
        </a-form-item>
        <div>
          <a-divider class="mb-0 mt-6" />
          <div class="bg-gray-100 p-2">
            <p class="my-2">
              Your password is required to perform profile updates
            </p>
            <div class="flex items-center space-x-2">
              <a-form-item
                label="Password"
                name="currentPassword"
                class="flex-1"
              >
                <a-input-password
                  v-model:value="formData.currentPassword"
                ></a-input-password>
              </a-form-item>
            </div>
          </div>
        </div>
        <ErrorMessage :message="error"></ErrorMessage>

        <div class="my-6 flex items-center justify-center space-x-4">
          <SparkTrackerButton :loading="isSubmitting" type="submit"
            >Update
          </SparkTrackerButton>
        </div>
      </a-form>
    </SparkPopup>
  </div>
</template>
