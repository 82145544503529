<script setup lang="ts">
/**
 * See ./AnnouncementModal.md on how to update announcements
 */

import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { getItem, setItem } from '@/core/misc/UserLocalStorage';
import { analyticsTrack } from '@/core/utils/usageAnalytics';
import { useAppBreakpoints } from '@/shared/composables/useAppBreakpoints';
import { AnalyticsEvent } from '@/types';

import { useAppStore } from '../stores/app';
import { useAuthStore } from '../stores/auth';

const ANNOUNCEMENT_ID = '2024-08.1';
const STORAGE_ID_KEY = 'announcement-popup';
const DELAY = 5000;
const ROUTES_TO_SHOW = ['/dashboard', '/freight', '/cargo', '/access'];

const isModalVisible = ref(false);
const breakpoints = useAppBreakpoints();
const authStore = useAuthStore();
const route = useRoute();
const appStore = useAppStore();
let timeout: ReturnType<typeof setTimeout> | undefined;

const modalSize = computed(() => {
  return breakpoints.isDesktop.value ? '800px' : '430px';
});
const imgLoaded = ref(false);

function onImgLoad() {
  imgLoaded.value = true;
}

// do not remove, this might be used in the future
function contactUs() {
  if (window.$crisp) {
    window.$crisp.push(['do', 'chat:open']);
    window.$crisp.push(['set', 'message:text', ['']]);
    window.$crisp.push([
      'set',
      'message:text',
      ['Which update would you like to discuss?'],
    ]);
  }
  analyticsTrack(AnalyticsEvent.AnnouncementContactUs, {
    announcementId: ANNOUNCEMENT_ID,
  });
  acknowledge();
}

function acknowledge() {
  isModalVisible.value = false;
  setItem(STORAGE_ID_KEY, ANNOUNCEMENT_ID);
  analyticsTrack(AnalyticsEvent.AnnouncementAcknowledged, {
    announcementId: ANNOUNCEMENT_ID,
  });
}

function areConditionsMet() {
  const routeIncludes = ROUTES_TO_SHOW.some((r) => route.path.includes(r));
  const loggedIn = authStore.loggedIn;
  const isNotInLocalStorage = getItem(STORAGE_ID_KEY) !== ANNOUNCEMENT_ID;
  const hasDoneOnboarding = authStore.userAccount?.hasVisitedOnboardingPage;
  const allConditions = [
    routeIncludes,
    loggedIn,
    isNotInLocalStorage,
    hasDoneOnboarding,
  ];
  return allConditions.every((condition) => condition === true);
}

function tryShowAnnouncement() {
  const toShow = areConditionsMet();
  if (!toShow) {
    return;
  }
  isModalVisible.value = true;
}

function showAnnouncementAfterDelay() {
  if (timeout !== undefined) {
    return;
  }

  timeout = setTimeout(() => {
    tryShowAnnouncement();
    clearTimeout(timeout);
    timeout = undefined;
  }, DELAY);
}

appStore.listenEvent('announcement', () => {
  isModalVisible.value = true;
});

watch([() => authStore.loggedIn, () => route.path], () => {
  showAnnouncementAfterDelay();
});

const unwatch = watch(isModalVisible, (isVisible) => {
  if (isVisible) {
    analyticsTrack(AnalyticsEvent.AnnouncementShown, {
      announcementId: ANNOUNCEMENT_ID,
    });
    unwatch();
  }
});
</script>

<template>
  <a-modal
    v-model:visible="isModalVisible"
    title="Announcements"
    class="announcement-modal"
    centered
    :width="modalSize"
    @cancel="acknowledge()"
  >
    <div class="relative">
      <img
        class="hidden fhd:block"
        src="@/assets/img/announcement/2024-08-desktop.svg"
        @load="onImgLoad"
      />

      <img
        class="fhd:hidden"
        src="@/assets/img/announcement/2024-08-mobile.svg"
        @load="onImgLoad"
      />

      <!-- Default -->
      <!-- <div
        class="absolute bottom-0 flex w-full items-center justify-end px-8 py-4 lg:right-0 lg:w-[484px]"
      >
        <SparkTrackerButton look="outlined" @click="contactUs"
          >Contact Us</SparkTrackerButton
        >
      </div> -->
      <!-- Temporary for Access -->
      <div
        v-if="imgLoaded"
        class="absolute bottom-0 flex w-full items-center justify-center py-4 lg:right-0 lg:w-[521px]"
      >
        <SparkTrackerButton @click="contactUs"
          >Get in touch for a demo</SparkTrackerButton
        >
      </div>
    </div>
  </a-modal>
</template>

<style lang="scss">
.announcement-modal {
  .ant-modal-header {
    @apply hidden;
  }
  .ant-modal-body {
    @apply p-0;
  }
  .ant-modal-footer {
    @apply hidden;
  }
}
</style>
