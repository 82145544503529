import PriceConstants from '@/shared/misc/PriceConstants';
import type { components } from '@/types';

import ApiBase from './ApiBase';

export default class PriceSignalService extends ApiBase {
  async getDeliveryPeriodsByContractId(contractId: string) {
    const response = await this.$http.get<
      components['schemas']['ListResponse_PriceEvolutionDeliveryWindowDTO_']
    >(`v1.0/contracts/${contractId}/price-evolution`);
    return response?.data?.data;
  }

  async getPriceSignalByContractIdAndDeliveryPeriod(
    assessmentType: components['schemas']['AssessmentTypeEnum'],
    contractId: components['schemas']['ContractEnum'],
    deliveryPeriodId: string,
    priceFormat?: components['schemas']['PriceFormatEnum'],
  ) {
    // physical
    let url = `v1.0/contracts/${contractId}/price-evolution/${deliveryPeriodId}`;

    if (assessmentType === 'financial') {
      url = `v1.0/price-evolutions/${contractId}/${deliveryPeriodId}`;
    }

    if (priceFormat) {
      url = url + `?price-format=${priceFormat}`;
    }

    const response =
      await this.$http.get<
        components['schemas']['DetailResponse_PriceEvolutionDTO_']
      >(url);

    return response?.data?.data;
  }

  async getHistoricalPrices(
    vertical: components['schemas']['VerticalEnum'],
    priceFormat?: components['schemas']['PriceFormatEnum'],
  ) {
    let url = `v1.1/historical-prices/${vertical}`;
    if (priceFormat) {
      url = url + `?price-format=${priceFormat}`;
    }
    const response =
      await this.$http.get<
        components['schemas']['DetailResponse_HistoricalPriceResponseDTO_']
      >(url);
    return response?.data?.data;
  }

  async getHistoricalDataOptions(
    vertical: components['schemas']['VerticalEnum'],
  ) {
    const response = await this.$http.get<
      components['schemas']['DetailResponse_DeliveryOptionsResponse_']
    >(`v1.0/price-evolutions/options/${vertical}`);
    return response?.data?.data;
  }

  async getContractsSpread(
    assessmentType: components['schemas']['AssessmentTypeEnum'],
    leg1Criteria: any,
    leg2Criteria: any,
  ) {
    try {
      if (assessmentType === 'physical') {
        const leg1CriteriaString = getLegCriteriaString(leg1Criteria);
        const leg2CriteriaString = getLegCriteriaString(leg2Criteria);
        const response = await this.$http.get<
          components['schemas']['DetailResponse_PriceSpreadDTO_']
        >(`v1.0/price-spreads/${leg1CriteriaString}/${leg2CriteriaString}`);

        const data = response?.data?.data?.spreads;
        return data;
      } else if (assessmentType === 'financial') {
        const response = await this.$http.post<
          components['schemas']['DetailResponse_PriceSpreadDTO_']
        >(`v1.0/price-spreads/`, {
          leg1: {
            contractGroup: leg1Criteria.contractGroup,
            deliveryPeriod: leg1Criteria.deliveryPeriod,
          },
          leg2: {
            contractGroup: leg2Criteria.contractGroup,
            deliveryPeriod: leg2Criteria.deliveryPeriod,
          },
        });
        return response?.data?.data?.spreads;
      } else {
        return [];
      }
    } catch (e) {
      console.error(e);
      return [];
    }
  }
}

const getLegCriteriaString = function (legCriteria: any) {
  let criteriaString = legCriteria.contractId;
  const [contractType, dimensionName] = legCriteria.contractType.split('|');

  // convert to dimension code
  let dimensionCode = '';
  switch (dimensionName) {
    case PriceConstants.DIMENSION_SP:
      dimensionCode = 'sp';
      break;
    case PriceConstants.DIMENSION_PP:
      dimensionCode = 'pp';
      break;
    case PriceConstants.DIMENSION_OWNER:
      dimensionCode = 'owner';
      break;
    default:
      break;
  }

  switch (contractType) {
    case 'forward':
      // criteriaString += 'Fo';
      criteriaString += '_' + dimensionCode + '_' + legCriteria.deliveryDate;
      break;
    case 'frontMonth':
      criteriaString += 'F' + '_' + dimensionCode;
      break;
    case 'spot':
      criteriaString += 'S' + '_' + dimensionCode;
      break;
    default:
      break;
  }
  return criteriaString;
};
