import { reduce } from 'lodash';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import type { RouteRecordName } from 'vue-router';

import featureVersions from '@/shared/configs/featureVersionConfig';
import type { Change } from '@/types';

const localStorageKey = 'featureVersion';

const useFeatureVersionStore = defineStore('featureVersion', () => {
  // getters
  const getLocalStorageFeatureVersion = () => {
    const local = localStorage.getItem(localStorageKey);
    if (!local) return null;
    return JSON.parse(local);
  };

  const getLocalStorageFeatureComponents = computed(() => {
    return (id: string) => {
      const localAppVersions = getLocalStorageFeatureVersion();
      if (!localAppVersions) return null;
      return localAppVersions[id];
    };
  });

  const getUpdatedFeatureComponents = computed(() => {
    return (id: string) => {
      return reduce(
        featureVersions[id],
        (result, value, key) => {
          const localFeatureComponents =
            getLocalStorageFeatureComponents.value(id);
          if (featureVersions[id][key] >= 0 && localFeatureComponents) {
            if (!localFeatureComponents[key]) result.push(key);
            if (localFeatureComponents[key] < featureVersions[id][key])
              result.push(key);
          }
          return result;
        },
        [] as string[],
      );
    };
  });

  const updatedFeatures = computed(() => {
    return reduce(
      featureVersions,
      (result, value, key) => {
        // If entire feature not found
        if (!getLocalStorageFeatureComponents.value(key)) {
          // And if all the values in that new feature is -1, ignore
          // Otherwise, get the new feature component that is >= 0
          if (Object.values(value)?.filter((item) => item > 0)?.length > 0) {
            result[key] = reduce(
              value,
              (result, value, key) => {
                if (value >= 0) result.push(key);
                return result;
              },
              [] as string[],
            );
          }
        } else {
          // Else check component update within feature
          if (getUpdatedFeatureComponents.value(key)?.length) {
            result[key] = getUpdatedFeatureComponents.value(key);
          }
        }
        return result;
      },
      // Somehow if not added, it's inferred wrongly as
      // result: { [componentId: string]: number; }
      {} as Change,
    );
  });

  const isFeatureUpdated = computed(() => {
    return (id: string) => {
      const localVersion = getLocalStorageFeatureComponents.value(id);
      const currentVersion = featureVersions[id];
      let isUpdated = false;
      if (localVersion && currentVersion) {
        isUpdated = Object.keys(localVersion).some((key) => {
          return localVersion[key] < currentVersion[key];
        });
      }
      // if localVersion is not found, but currentVersion is found. ie. new feature (page) added
      else if (!localVersion && currentVersion) {
        isUpdated = Object.keys(currentVersion).some((key) => {
          return currentVersion[key] >= 0;
        });
      }
      return isUpdated;
    };
  });

  // actions
  function dismissComponent({
    featureId,
    componentId,
  }: {
    featureId: string;
    componentId: string;
  }) {
    const currentVersion = featureVersions[featureId][componentId];
    const localVersion = getLocalStorageFeatureVersion();
    if (localVersion) {
      if (!localVersion[featureId]) localVersion[featureId] = {};
      localVersion[featureId][componentId] = currentVersion;
    }
    localStorage.setItem(localStorageKey, JSON.stringify(localVersion));
  }
  function dismissFeature({ featureId }: { featureId: string }) {
    const currentVersion = featureVersions[featureId];
    const localVersion = getLocalStorageFeatureVersion();
    if (localVersion) {
      localVersion[featureId] = currentVersion;
    }
    localStorage.setItem(localStorageKey, JSON.stringify(localVersion));
  }
  function initialiseFeatureVersionOnLocalStorage() {
    localStorage.setItem(localStorageKey, JSON.stringify(featureVersions));
  }

  function shouldShowRedDot(
    featureId: string,
    route: RouteRecordName | null | undefined,
  ) {
    const isUpdated = isFeatureUpdated.value(featureId);
    const isCurrentRoute = route === featureId;
    const result = isUpdated && !isCurrentRoute;
    return result;
  }

  return {
    // getters
    getLocalStorageFeatureVersion,
    getLocalStorageFeatureComponents,
    getUpdatedFeatureComponents,
    updatedFeatures,
    isFeatureUpdated,
    // actions
    dismissComponent,
    dismissFeature,
    initialiseFeatureVersionOnLocalStorage,
    shouldShowRedDot,
  };
});

export { useFeatureVersionStore };
