import { ITEM_KEYS } from './keys';

export const LocalStorageManager = {
  setClientId(newClientId: string) {
    return localStorage.setItem(ITEM_KEYS.CLIENT_ID, newClientId);
  },
  getClientId() {
    return localStorage.getItem(ITEM_KEYS.CLIENT_ID);
  },

  getMandatorySetupDone(): boolean {
    return localStorage.getItem(ITEM_KEYS.MANDATORY_SETUP_DONE) === 'true';
  },
  setMandatorySetupDone(flag: boolean) {
    return localStorage.setItem(
      ITEM_KEYS.MANDATORY_SETUP_DONE,
      flag ? 'true' : 'false',
    );
  },
  listenLogoutEvent(logoutCallback: () => void) {
    window.onstorage = () => {
      if (
        localStorage.getItem(ITEM_KEYS.LOGOUT_EVENT_KEY) ===
        ITEM_KEYS.LOGOUT_EVENT_ALL
      ) {
        logoutCallback();
      }
    };
  },
  triggerLogoutEvent() {
    // Trigger event
    localStorage.setItem(
      ITEM_KEYS.LOGOUT_EVENT_KEY,
      ITEM_KEYS.LOGOUT_EVENT_ALL,
    );

    // Clear event
    localStorage.setItem(ITEM_KEYS.LOGOUT_EVENT_KEY, '');
  },
};
