<script setup lang="ts">
import { onMounted } from 'vue';

import SparkAlert from '#/components/generic/SparkAlert.vue';

import { useMaintenanceStore } from '../stores/maintenance';

const maintenanceStore = useMaintenanceStore();

onMounted(async () => {
  await maintenanceStore.loadData();
});
</script>
<template>
  <div
    v-if="
      maintenanceStore.config &&
      maintenanceStore.config.enabled &&
      maintenanceStore.config.message
    "
    class="fixed z-[999] w-full"
  >
    <SparkAlert type="warning" closable>
      <template #message>
        <Markdown :source="maintenanceStore.config.message" />
      </template>
    </SparkAlert>
  </div>
</template>
