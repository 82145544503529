<script setup lang="ts">
import type { Placement } from '@floating-ui/vue';
import {
  arrow,
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  useFloating,
} from '@floating-ui/vue';
import { useElementBounding, useElementSize } from '@vueuse/core';
import { computed, nextTick, onMounted, provide, ref } from 'vue';

import { assertIsDefined } from '#/utils/asserts';
import { delayAsync } from '#/utils/promise';

import { UI_TOUR_INTERNAL_CONTEXT_KEY, UI_TOUR_STOP_ID_ATTR } from './share';
import type { FloatingContentContext, ModalContentContext } from './types';

withDefaults(
  defineProps<{
    teleportTo?: string;
    currentId: string | null;
  }>(),
  { teleportTo: 'body', currentIndex: -1 },
);

const emit = defineEmits<{
  exited: [];
}>();

const overlay = ref<HTMLElement>();
const currentTargetElement = ref<HTMLElement>();
const isExiting = ref(false);
const showContent = ref(false);
const spotlight = ref<SVGRectElement>();
const spotlightMask = ref<SVGMaskElement>();
const floating = ref<HTMLElement>();
const floatingArrow = ref<HTMLElement>();
const floatingContent = ref<HTMLElement>();
const modal = ref<HTMLElement>();
const spinner = ref<HTMLElement>();
const desiredPlacement = ref<Placement | 'modal'>('bottom');
const spotlightPadding = ref<number>(10);
const spotlightCornerRadius = ref<number>(10);
const spinnerBounds = ref<{
  x: number;
  y: number;
  width: number;
  height: number;
} | null>(null);

const { width: overlayWidth, height: overlayHeight } = useElementSize(overlay);

const { top, left, width, height } = useElementBounding(currentTargetElement);

const viewBox = computed(
  () => `0 0 ${overlayWidth.value} ${overlayHeight.value}`,
);

const spotLightBounds = computed(() => {
  if (currentTargetElement.value === spinner.value) {
    return {
      x: left.value,
      y: top.value,
      width: width.value,
      height: height.value,
    };
  }

  return {
    x: left.value - spotlightPadding.value,
    y: top.value - spotlightPadding.value,
    width: width.value + spotlightPadding.value * 2,
    height: height.value + spotlightPadding.value * 2,
  };
});

const computedSpotlightCornerRadius = computed(() => {
  if (isExiting.value) {
    return 0;
  }
  return spotlightCornerRadius.value;
});

async function showFloatingContent(
  floatingContentContext: FloatingContentContext,
) {
  const {
    targetElementId,
    placement: placementSetting,
    scrollMargin,
    scrollLogicalPosition,
    spotlightPadding: spotlightPaddingSetting,
    spotlightCornerRadius: spotlightCornerRadiusSetting,
  } = floatingContentContext;

  desiredPlacement.value = placementSetting ?? 'bottom';
  spotlightPadding.value = spotlightPaddingSetting ?? 10;
  spotlightCornerRadius.value = spotlightCornerRadiusSetting ?? 10;

  const element = document.getElementById(targetElementId ?? '');
  if (element) {
    currentTargetElement.value = element;
    showContent.value = false;
    let savedScrollMargin: string | null = null;
    if (!isInViewport(currentTargetElement.value)) {
      savedScrollMargin = currentTargetElement.value.style.scrollMargin;
      currentTargetElement.value.style.scrollMargin =
        scrollMargin ?? savedScrollMargin;
      currentTargetElement.value.scrollIntoView({
        behavior: 'smooth',
        block: scrollLogicalPosition,
      });
    }
    await nextTick();
    spotlightMask.value?.classList.add('spotlight');
    await awaitSpotlightTransitionEnd();
    spotlightMask.value?.classList.remove('spotlight');
    showContent.value = false;
    await nextTick();
    showContent.value = true;
    await nextTick();
    floatingContent.value?.classList.add('show');
    if (savedScrollMargin !== null) {
      currentTargetElement.value.style.scrollMargin = savedScrollMargin;
    }
  } else {
    console.error(`Element with id ${targetElementId} not found`);
    await exit({ omitAnimation: true });
  }
}

async function showModalContent(modalContentContext: ModalContentContext) {
  const {
    spotlightPadding: spotlightPaddingSetting,
    spotlightCornerRadius: spotlightCornerRadiusSetting,
  } = modalContentContext;

  spotlightPadding.value = spotlightPaddingSetting ?? 10;
  spotlightCornerRadius.value = spotlightCornerRadiusSetting ?? 10;

  showContent.value = true;
  await nextTick();
  currentTargetElement.value = modal.value;
  await nextTick();
  spotlightMask.value?.classList.add('spotlight');
  await awaitSpotlightTransitionEnd();
  spotlightMask.value?.classList.remove('spotlight');
  modal.value?.classList.add('show');
}

async function showSpinner() {
  spinnerBounds.value = {
    x: spotLightBounds.value.x,
    y: spotLightBounds.value.y,
    width: spotLightBounds.value.width,
    height: spotLightBounds.value.height,
  };
  await nextTick();
  currentTargetElement.value = spinner.value;
}
function hideSpinner() {
  spinnerBounds.value = null;
}

async function exit(options: { omitAnimation: boolean }) {
  if (!options.omitAnimation) {
    isExiting.value = true;
    currentTargetElement.value = overlay.value;
    showContent.value = false;
    await nextTick();
    spotlightMask.value?.classList.add('spotlight');
    await awaitSpotlightTransitionEnd();
    spotlightMask.value?.classList.remove('spotlight');
    isExiting.value = false;
  } else {
    currentTargetElement.value = overlay.value;
    showContent.value = false;
  }
  emit('exited');
}

function isInViewport(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function awaitSpotlightTransitionEnd() {
  const transitionEndPromise = new Promise((resolve) => {
    spotlightMask.value?.addEventListener('transitionend', resolve, {
      once: true,
    });
  });

  // 360ms here is to match the transition duration in the CSS + 10ms buffer
  const delayPromise = delayAsync(360);

  // this is to prevent the spotlight from not returning from the transitionend event
  return Promise.race([transitionEndPromise, delayPromise]);
}

function setFloatingElement(element: HTMLElement) {
  floating.value = element;
}

function setFloatingContentElement(element: HTMLElement) {
  floatingContent.value = element;
}

function setFloatingArrowElement(element: HTMLElement) {
  floatingArrow.value = element;
}

function setModalElement(element: HTMLElement) {
  modal.value = element;
}

const { floatingStyles, middlewareData, placement } = useFloating(
  spotlight,
  floating,
  {
    placement: computed(() =>
      desiredPlacement.value === 'modal' ? undefined : desiredPlacement.value,
    ),
    middleware: [
      offset(10),
      flip({
        fallbackAxisSideDirection: 'end',
      }),
      shift({ crossAxis: true }),
      arrow({ element: floatingArrow, padding: 20 }),
      hide(),
    ],
    whileElementsMounted: autoUpdate,
  },
);

const arrowPositionStyles = computed<Partial<Record<string, string>>>(() => {
  if (!middlewareData.value?.arrow) return {};
  const { x, y } = middlewareData.value.arrow;

  const side = placement.value.split('-')[0];

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[side];

  assertIsDefined(staticSide);
  if (!floatingArrow.value) return {};

  return {
    left: x != null ? `${x}px` : '',
    top: y != null ? `${y}px` : '',
    [staticSide]: `${-floatingArrow.value.offsetWidth / 2}px`,
  };
});

const hideFloatingStyles = computed(() => {
  const hideData = middlewareData.value.hide;
  return {
    visibility: hideData?.referenceHidden ? 'hidden' : 'visible',
  };
});

const computedFloatingStyles = computed(() => {
  return {
    ...floatingStyles.value,
    ...hideFloatingStyles.value,
  };
});

function getChildrenIds() {
  if (overlay.value) {
    const orderedChildren = Array.from(
      overlay.value.querySelectorAll(`[${UI_TOUR_STOP_ID_ATTR}]`),
    ).map((el) => el.getAttribute(UI_TOUR_STOP_ID_ATTR));

    return orderedChildren.filter((id) => !!id) as string[];
  }
  return [];
}

provide(UI_TOUR_INTERNAL_CONTEXT_KEY, {
  showFloatingContent,
  showModalContent,
  showSpinner,
  hideSpinner,
  showContent: computed(() => showContent.value),
  computedFloatingStyles,
  arrowPositionStyles,
  placement,
  setFloatingElement,
  setFloatingContentElement,
  setFloatingArrowElement,
  setModalElement,
  exit,
});

defineExpose({
  exit,
  getChildrenIds,
});

onMounted(() => {
  currentTargetElement.value = overlay.value;
});
</script>

<template>
  <Teleport :to="teleportTo">
    <div
      ref="overlay"
      data-ui-tour-internal
      class="fixed inset-0"
      :class="{ 'pointer-events-none': currentId === null }"
    >
      <svg v-if="currentId !== null" :viewBox="viewBox">
        <mask id="spotlight-mask">
          <rect
            x="0"
            y="0"
            :width="overlayWidth"
            :height="overlayHeight"
            fill="white"
          />
          <rect
            ref="spotlightMask"
            :x="spotLightBounds.x"
            :y="spotLightBounds.y"
            :width="spotLightBounds.width"
            :height="spotLightBounds.height"
            :rx="computedSpotlightCornerRadius"
            fill="black"
          />
        </mask>
        <rect
          x="0"
          y="0"
          :width="overlayWidth"
          :height="overlayHeight"
          fill="black"
          fill-opacity="0.7"
          mask="url(#spotlight-mask)"
        />
        <rect
          ref="spotlight"
          :x="spotLightBounds.x"
          :y="spotLightBounds.y"
          :width="spotLightBounds.width"
          :height="spotLightBounds.height"
          :rx="computedSpotlightCornerRadius"
          fill="transparent"
        />
      </svg>

      <div
        ref="spinner"
        v-if="spinnerBounds !== null"
        class="absolute rounded-lg bg-white"
        :style="{
          left: `${spinnerBounds.x}px`,
          top: `${spinnerBounds.y}px`,
          width: `${spinnerBounds.width}px`,
          height: `${spinnerBounds.height}px`,
        }"
      >
        <slot name="spinner" />
      </div>

      <slot />
    </div>
  </Teleport>
</template>

<style scoped>
.spotlight {
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
</style>
