<script lang="ts">
import { inject } from 'vue';

import { UITourStop } from '#/components/uiTour/';

import { SPARK_UI_TOUR_CONTEXT } from './share';
import SparkUITourStopDialog from './SparkUITourStopDialog.vue';

export interface OnStartArgs {
  callback: (() => Promise<void>) | null;
}
</script>

<script setup lang="ts">
defineProps<{
  title: string;
}>();

const emit = defineEmits<{
  start: [OnStartArgs];
}>();

const context = inject(SPARK_UI_TOUR_CONTEXT);
if (!context) {
  throw new Error('SparkUITourStop must be used inside SparkUITour');
}

const { color } = context;

async function startTour(next: () => void) {
  const args: OnStartArgs = {
    callback: null,
  };
  emit('start', args);
  if (typeof args.callback === 'function') {
    await args.callback();
    next();
  } else {
    next();
  }
}
</script>

<template>
  <UITourStop
    placement="modal"
    :spotlight-padding="0"
    floating-element-css="drop-shadow-lg"
  >
    <template #default="{ exit, next }">
      <SparkUITourStopDialog :title="title">
        <slot />
        <template #actions>
          <SparkTrackerButton look="ghost" :color="color" @click="exit">
            Exit Guide
          </SparkTrackerButton>
          <SparkTrackerButton :color="color" @click="startTour(next)">
            Start Guide
          </SparkTrackerButton>
        </template>
      </SparkUITourStopDialog>
    </template>
  </UITourStop>
</template>
