<script lang="ts" setup>
import { CheckCircleFilled, QuestionCircleFilled } from '@ant-design/icons-vue';
import { computed, ref } from 'vue';

import { useAuthStore } from '@/core/stores/auth';

import AccountRegistrationSteps from './components/AccountRegistrationSteps.vue';
import EditAccount from './components/EditAccount.vue';

interface Props {
  bannerMode?: boolean;
}

withDefaults(defineProps<Props>(), { bannerMode: false });

const authStore = useAuthStore();

const verifiedAt = computed(() => {
  return authStore.userAccount?.iceChatUsernameVerifiedAt;
});

const handle = computed(() => {
  return authStore.userAccount?.iceChatUsername;
});
const showForm = ref(false);

function openFormModal() {
  showForm.value = true;
}

function closeFormModal() {
  showForm.value = false;
}
</script>
<template>
  <section>
    <div class="flex flex-wrap items-center justify-between">
      <div v-if="bannerMode" class="flex flex-wrap items-center gap-1">
        <SparkIcon height="16" icon="ice" />
        <span class="font-display font-bold">ICE Chat Account</span>
        <AccountRegistrationSteps :banner-mode="bannerMode" />
      </div>
      <div
        :class="{ 'flex-1 justify-between': !bannerMode }"
        class="flex items-center gap-4"
      >
        <div v-if="handle" class="flex items-center gap-1">
          <SparkIcon v-if="!bannerMode" height="16" icon="ice" />
          <span>{{ handle }}</span
          ><span>
            <a-tooltip v-if="verifiedAt" title="Verified">
              <CheckCircleFilled class="align-middle text-green" />
            </a-tooltip>
            <a-tooltip v-else title="Verification Pending">
              <QuestionCircleFilled />
            </a-tooltip>
          </span>
        </div>
        <div>
          <SparkTrackerButton
            v-if="handle"
            look="outlined"
            icon="edit"
            @click="openFormModal"
          >
            Edit
          </SparkTrackerButton>

          <SparkTrackerButton
            v-else
            icon="plus"
            class="my-1"
            @click="openFormModal"
            >Add ICE Chat Account
          </SparkTrackerButton>
        </div>
      </div>
    </div>
    <EditAccount :show="showForm" @close="closeFormModal" />
  </section>
</template>
