import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

export default class AlertCentreService extends ApiBase {
  // User-defined alerts
  getUserDefinedAlerts() {
    return this.$http
      .get<
        components['schemas']['ListResponse_PriceAlertDTO_']
      >(`v2/price-alerts/`)
      .then((response) => {
        return response?.data?.data;
      });
  }

  createUserDefinedAlert(form: components['schemas']['PriceAlertPayload']) {
    return this.$http
      .post<
        components['schemas']['DetailResponse_PriceAlertDTO_']
      >(`v2/price-alerts/`, form)
      .then((response) => {
        return response?.data?.data;
      });
  }

  editUserDefinedAlert(
    uuid: string,
    form: components['schemas']['PriceAlertPayload'],
  ) {
    return this.$http
      .put<
        components['schemas']['DetailResponse_PriceAlertDTO_']
      >(`v2/price-alerts/${uuid}/`, form)
      .then((response) => {
        return response?.data?.data;
      });
  }

  deleteUserDefinedAlert(uuid: string) {
    return this.$http
      .delete<
        components['schemas']['EmptyResponse']
      >(`v2/price-alerts/${uuid}/`)
      .then((response) => {
        return response?.data?.data;
      });
  }
}
