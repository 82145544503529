import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { marketingService } from '@/core/services';
import type { components, FeedbackSlugId } from '@/types';

const useMarketingStore = defineStore('marketing', () => {
  // state
  const recentUpdatesItemDtos = ref<
    components['schemas']['RecentUpdateItemDTO'][]
  >([]);
  const isRecentUpdatesLoading = ref<boolean>(false);
  const feedbackDtos = ref<components['schemas']['FeedbackItemDTO'][]>([]);
  const isFeedbackDtosLoading = ref<boolean>(false);
  const autoPlay = ref<boolean>(true);

  // actions
  async function loadRecentUpdates() {
    try {
      isRecentUpdatesLoading.value = true;
      recentUpdatesItemDtos.value = await marketingService.getRecentUpdates();
    } catch {
      console.error('Failed to load recent updates');
    } finally {
      isRecentUpdatesLoading.value = false;
    }
  }

  async function loadFeedbacks(
    { forceRefresh }: { forceRefresh?: boolean } = { forceRefresh: false },
  ) {
    try {
      if (!forceRefresh && feedbackDtos.value.length > 0) {
        return;
      }

      isFeedbackDtosLoading.value = true;
      feedbackDtos.value = await marketingService.getFeedbacks();
    } catch {
      console.error('Failed to load feedbacks');
    } finally {
      isFeedbackDtosLoading.value = false;
    }
  }

  async function performMarketingAction(url: string) {
    const response = await marketingService.performMarketingAction(url);
    return response;
  }

  function useFeedback(slugId: FeedbackSlugId) {
    const feedback = computed(() => {
      return feedbackDtos.value.find((f) => f.slug === slugId);
    });
    const actions = computed(() => {
      return feedback.value?.actions ?? [];
    });
    const alreadyAnswered = computed(() => {
      return feedback.value?.alreadyAnswered;
    });
    async function sendFeedback(
      action: string,
      data?:
        | components['schemas']['FeedbackChallengeDataPayload']
        | components['schemas']['FeedbackRequestDataPayload']
        | components['schemas']['FeedbackAckDataPayload'],
    ) {
      if (!feedback.value) {
        console.error(`Feedback not found for ${slugId}`);
        return;
      }

      if (!actions.value.includes(action)) {
        console.error(`Action: ${action}  not found`);
        return;
      }

      return await marketingService.sendUserFeedback(slugId, {
        action,
        data,
      });
    }
    return {
      actions,
      alreadyAnswered,
      sendFeedback,
    };
  }

  function challengePrices(
    vertical: components['schemas']['VerticalEnum'],
    form: components['schemas']['FeedbackChallengeDataPayload'],
  ) {
    const data = Object.assign({}, form, { action: 'challenge' });
    if (vertical === 'lng-freight') {
      return marketingService.sendUserFeedback('lng-freight-challenge', {
        action: 'challenge',
        data,
      });
    } else if (vertical === 'lng-basis') {
      return marketingService.sendUserFeedback('lng-basis-challenge', {
        action: 'challenge',
        data,
      });
    } else if (vertical === 'access') {
      return marketingService.sendUserFeedback('lng-access-challenge', {
        action: 'challenge',
        data,
      });
    } else {
      throw new Error('No vertical matched');
    }
  }

  return {
    // state
    autoPlay,
    recentUpdatesItemDtos,
    isRecentUpdatesLoading,
    feedbackDtos,
    // actions
    performMarketingAction,
    loadRecentUpdates,
    loadFeedbacks,
    useFeedback,
    challengePrices,
  };
});

export { useMarketingStore };
