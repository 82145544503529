<script setup lang="ts">
import { computed, getCurrentInstance, inject } from 'vue';

import { ProductColorCssVarValues } from '#/constants/productColor';

import { SPARK_UI_TOUR_CONTEXT } from './share';

withDefaults(
  defineProps<{
    title?: string;
    currentIndex?: number;
    totalStops?: number;
  }>(),
  {
    title: undefined,
    nextButtonLabel: 'Next',
    currentIndex: undefined,
    totalStops: undefined,
  },
);

defineEmits<{
  prev: [];
  next: [];
  exit: [];
}>();

const context = inject(SPARK_UI_TOUR_CONTEXT);
if (!context) {
  throw new Error('SparkUITourStop must be used inside SparkUITour');
}

const { color } = context;

const instance = getCurrentInstance();

// https://stackoverflow.com/questions/46706737/check-if-a-component-has-an-event-listener-attached-to-it
const hasPrevListener = computed(() => {
  return !!instance?.vnode.props?.onPrev;
});

const hasNextListener = computed(() => {
  return !!instance?.vnode.props?.onNext;
});

const hasExitListener = computed(() => {
  return !!instance?.vnode.props?.onExit;
});
</script>

<template>
  <div
    class="relative grid max-w-[480px] gap-y-4 overflow-hidden rounded-lg border-[3px] border-[--stop-dialog-border-color] bg-white px-8 py-6"
    :style="{ '--stop-dialog-border-color': ProductColorCssVarValues[color] }"
  >
    <h4 class="h4 flex items-center justify-between">
      <slot name="title">{{ title }}</slot>
      <span
        v-if="currentIndex !== undefined && totalStops !== undefined"
        class="whitespace-nowrap text-sm text-gray-500"
        >{{ currentIndex }} / {{ totalStops }}</span
      >
    </h4>

    <slot />

    <div class="flex justify-end gap-x-2">
      <SparkTrackerButton
        v-if="hasPrevListener"
        look="ghost"
        :color="color"
        shape="circle"
        class="-ml-2 mr-auto"
        @click="$emit('prev')"
      >
        <template #icon>
          <SparkIcon icon="arrowForward" class="rotate-180" />
        </template>
      </SparkTrackerButton>
      <slot name="actions">
        <SparkTrackerButton
          v-if="hasExitListener"
          :color="color"
          look="ghost"
          @click="$emit('exit')"
        >
          Exit Guide
        </SparkTrackerButton>
        <SparkTrackerButton
          v-if="hasNextListener"
          :color="color"
          icon="chevronright"
          @click="$emit('next')"
        >
          Next
        </SparkTrackerButton>
      </slot>
    </div>
  </div>
</template>
